// import { ClientMetadata } from 'amazon-cognito-identity-js';
import React, { useState, useEffect, useContext, createContext } from 'react';

import { AppContext }   from '../App.js';
import config           from '../config';
import axios            from 'axios';
import * as cognito     from '../cognito/cognito.ts';
const bDebug = config.DEBUG && false;

export const AuthStatus = {
    Loading:   0,
    SignedIn:  1,
    SignedOut: 2
};
  
// export interface IAuth {
//      sessionInfo?: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string }
//     ,attrInfo?: any
//     ,authStatus?: AuthStatus
//     ,signInWithEmail?: any
//     ,signUpWithEmail?: any
//     ,signOut?: any
//     ,verifyCode?: any
//     ,getSession?: any
//     ,sendCode?: any
//     ,forgotPassword?: any
//     ,changePassword?: any
//     ,getAttributes?: any
//     ,setAttribute?: any
//     ,refreshToken?: any
// }
  
const defaultState = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
}

export const AuthContext = createContext(defaultState)

export const AuthIsSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext)
  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext)
  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

let refreshInterval = undefined;
  
const AuthProvider = ({ children }) => {
    const { set_oNotifyOptions } = useContext(AppContext)
    const [ authStatus  ,setAuthStatus  ] = useState(AuthStatus.Loading)
    const [ sessionInfo ,setSessionInfo ] = useState({});
    const [ attrInfo    ,setAttrInfo    ] = useState([]);

    useEffect(() => {
        
        async function getSessionInfo() {
            try {
                
                let recoverToken = localStorage.getItem('recoverToken') !== null ? '' + localStorage.getItem('recoverToken') : '';
                bDebug && console.log('recoverToken: ', recoverToken);
                const isRevoverTokenValid = await cognito.isAccessTokenValid(recoverToken.toString());
                bDebug && console.log('isRevoverTokenValid: ', isRevoverTokenValid);
                
                if ( isRevoverTokenValid ) {
                    
                    let session = await getSession();
                    bDebug && console.log('session: ', session);
                    
                    await cognito.refreshToken(session);
                    
                    session = await getSession();
                    const jwtToken = session.accessToken.jwtToken;
                    
                    bDebug && console.log('session.accessToken.jwtToken e localStorage recoverToken: ', jwtToken);
                    setSessionInfo({
                        accessToken: jwtToken,
                        refreshToken: session.refreshToken.token
                    });
                    localStorage.setItem('recoverToken', jwtToken);
                    
                    const attr = await getAttributes();
                    bDebug && console.log('attr: ', attr);
                    setAttrInfo(attr);
                    
                    if ( !refreshInterval ) {
                        refreshInterval = setInterval(refreshToken, config.REFRESH_TOKEN_INTERVAL);
                    }
                    
                    bDebug && console.log('AuthStatus.SignedIn: ', AuthStatus.SignedIn);
                    setAuthStatus(AuthStatus.SignedIn);
                    
                } else {
                    bDebug && console.log('isRevoverTokenValid: Not valid');
                    setAuthStatus(AuthStatus.SignedOut);
                }
                
            } catch (err) {
                console.error(err);
                setAuthStatus(AuthStatus.SignedOut);
            }
        }
        
        getSessionInfo();

    }, [setAuthStatus, authStatus])
  
  
    if ( authStatus === AuthStatus.Loading ) {
      return null;
    }

    const getAdminInfo = async (headers) => {
        // console.log('getAdminInfo');
        try {
            const response = await axios.get( config.API_URL + '/get', { headers, params: { getUserInfo: true } } );
            if ( ( response?.status === 200 ) && Array.isArray( response.data?.aoRecords ) ) {
                // console.log('authContext: ',response.data.aoRecords[0]);
                const { KUSER ,FLAG_ADMIN ,DATA_ULTIMA_LOGIN ,EMAIL_ADDRESS ,FULL_NAME ,MENU_ITEMS ,ALERTS } = response.data.aoRecords[0];
                localStorage.setItem('flagAdmin'          , FLAG_ADMIN );
                localStorage.setItem('email'              , EMAIL_ADDRESS );
                localStorage.setItem('fullName'           , FULL_NAME );
                localStorage.setItem('lastLogin'          , DATA_ULTIMA_LOGIN );
                localStorage.setItem('kUser'              , KUSER );
                localStorage.setItem('menuItemsToDisplay' , MENU_ITEMS || '[{}]');
                localStorage.setItem('alarmsToDisplay'    , ALERTS  || '[{}]');
            } else {
                console.error('ERR 161: Failed GetUserInfo, response status: ', response?.status);
                set_oNotifyOptions({
                     message:  `Non è stato possibile recuperare le informazioni relative all'utente`
                    ,severity: 'error'
                });
            }
        } catch(err) {
            console.error('ERR 160: Failed GetUserInfo: ', err);
            set_oNotifyOptions({
                 message:  `Non è stato possibile recuperare le informazioni relative all'utente`
                ,severity: 'error'
            });
        }
    };

    async function refreshToken() {
        //qui si potrebbe implementare una chiamata backend per loggare il fatto che l'utente è ancora attivo
        try {
            let session = await getSession();
            
            await cognito.refreshToken(session);
            
            session = await getSession();
            const jwtToken = session.accessToken.jwtToken;
            bDebug && console.log('session.accessToken.jwtToken e localStorage recoverToken: ', jwtToken);
            setSessionInfo({
                accessToken: jwtToken
            });
            localStorage.setItem('recoverToken', jwtToken);
            
            setAuthStatus(AuthStatus.SignedIn);
        
        } catch (err) {
            console.error('ERROR refreshToken',err);
            setAuthStatus(AuthStatus.SignedOut);
        }
    }
  
    async function signInWithEmail(username, password) {
        try {
            await cognito.signInWithEmail(username, password);

            const session  = await getSession();
            const jwtToken = session.accessToken.jwtToken;
            bDebug && console.log('session.accessToken.jwtToken e localStorage recoverToken: ', jwtToken);
            
            localStorage.setItem('recoverToken', jwtToken);
            
            /*
                // CHIAMATA A DATABASE PER OTTENERE LE INFORMAZIONI SULL UTENTE
                // !!! TODO Commento da rivedere
                // !! Eccezione: 
                In questo punto abbiamo bisogno di accessToken 
                che tuttavia ancora non è valorizzato in authContext.sessionInfo.accessToken (undefined)
                Utiliziamo quindi unicamente in questo punto session.accessToken.jwtToken, 
                mentre, una volta autenticati e entrati nell'applicazione, 
                useremo esclusivamente l'authContext.sessionInfo.accessToken
            */

            const headers = {
                'Accept'        : 'application/json',
                'Authorization' : `Bearer ${jwtToken}`
            }; 
            await getAdminInfo(headers);

            bDebug && console.log('session.accessToken.jwtToken: ', jwtToken);
            setSessionInfo({
                accessToken: jwtToken
            });

            setAuthStatus(AuthStatus.SignedIn);

        } catch (err) {
            console.error('ERROR signInWithEmail',err);
            setAuthStatus(AuthStatus.SignedOut);
            throw err;
        }
    }
  
    async function signUpWithEmail(username, password, nickname, metaData) {
      try {
        await cognito.signUpUserWithEmail(username, password, nickname, metaData);
      } catch (err) {
        throw err;
      }
    }
  
    function signOut() {
      localStorage.clear();
      cognito.signOut();
      setAuthStatus(AuthStatus.SignedOut);
    }
  
    async function verifyCode(username, code) {
      try {
        await cognito.verifyCode(username, code);
      } catch (err) {
        throw err;
      }
    }
  
    async function getSession() {
      try {
        return await cognito.getSession();
      } catch (err) {
        throw err;
      }
    }
  
    async function getAttributes() {
      try {
        return await cognito.getAttributes();
      } catch (err) {
        throw err;
      }
    }
  
    async function setAttribute(attr) {
      try {
        return await cognito.setAttribute(attr);
      } catch (err) {
        throw err;
      }
    }
  
      async function sendCode(email) {
      try {
        await cognito.sendCode(email);
      } catch (err) {
        throw err;
      }
    }
  
    async function forgotPassword(username, code, password) {
      try {
        await cognito.forgotPassword(username, code, password);
      } catch (err) {
        throw err;
      }
    }
  
    async function changePassword(oldPassword, newPassword) {
      try {
        await cognito.changePassword(oldPassword, newPassword);
      } catch (err) {
        throw err;
      }
    }
  
    const state = {
      authStatus,
      sessionInfo,
      attrInfo,
      signUpWithEmail,
      signInWithEmail,
      signOut,
      verifyCode,
      getSession,
      sendCode,
      forgotPassword,
      changePassword,
      getAttributes,
      setAttribute,
      refreshToken
    }
  
    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}
  
export default AuthProvider;
