import React, { useState, useEffect, useContext } from 'react';
import config                   from '../../config.js';
import axios                    from 'axios';
import moment                   from 'moment';
import {
     Autocomplete
    ,Button
    ,Checkbox
    ,Chip
    ,IconButton
    ,Typography
} from '@mui/material';

import                          './SellOrNot.css';
import { AppContext }           from '../../App.js';
import { AuthContext }          from '../../contexts/authContext.js';
import { SimpleTable }          from '../../components/SimpleTable/SimpleTable.js';
import Graph                    from '../../components/Graph/Graph.js';
import CircleProgress           from '../../components/CircleProgress/CircleProgress.tsx';
import utils                    from '../../util/CommonUtilities.js';

import { CustomCssTextField
        ,CustomNoWidthTooltip
        ,RadioDis24CheckedIcon    } from '../../components/CustomCSS/CustomCSS.tsx';
import {
     FolderOffIcon
   , CachedIcon
   , CancelIcon
   , InfoIcon
   , FullScreenOnIcon 
   , FullScreenOffIcon
} from '../../assets/icons/icons.js';

const bDebug = config.DEBUG && false;

const SellOrNot = () => {
    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);
    const auth     = useContext(AuthContext);
    const headers  = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };
    const isDemo    = process.env.REACT_APP_ENV.includes('demo')

    const
         [ loading      ,setLoading       ] = useState(true)
        ,[ onReload     ,set_onReload     ] = useState(null)  // State per triggerare il lancio della chiamata
        ,[ sFullMode    ,set_sFullMode    ] = useState('')  // State per Identificare se si vuole visualizzare FullMode1: OnlyGraph ('fullGraph'), FullMode2: OnlyTable ('fullTable'), o entrambi ('all')
        ,[ cTitle       ,set_cTitle       ] = useState(<></>) // Component per Titolo del dettaglio Pagina
        ,[ sExportTitle ,set_sExportTitle ] = useState('')  // State per contenere il titolo del file da esportare
        ,[ sDateToView        ,set_sDateToView        ] = useState('') // State per scatenare il primo onreload in fase di atterraggio pagina solamente dopo aver ricevuto l'elenco di mesi
        ,[ aoDateToViewList   ,set_aoDateToViewList   ] = useState([]) // State per contenere i mesi da usare come filtro di primo livello per l'intera pagina
        ,[ aoDateToView       ,set_aoDateToView       ] = useState([]) // State per contenere i mesi da usare come filtro di primo livello per l'intera pagina
        ,[ aoDateToFilterList ,set_aoDateToFilterList ] = useState([]) // State per contenere i mesi da filtrare al secondo livello per grafico e tabella dettaglio
        ,[ aoDateToFilter     ,set_aoDateToFilter     ] = useState([]) // State per contenere i mesi da filtrare al secondo livello per grafico e tabella dettaglio
        
        ,oMappingField = {
             'M'  : { 'description': 'Periodo'             }
            ,'V'  : { 'description': 'Impressions Erogate' }
            ,'I'  : { 'description': 'Invenduto'           }
            ,'R'  : { 'description': 'Revenue'             }
        }
        ,formatDate = (val) => moment(val, 'YYYYMM').format('MMM YYYY')
        
        // Dati
        ,[ aoGraph     ,set_aoGraph          ] = useState([])
        ,[ aoCopyTable ,set_aoCopyTable      ] = useState([]) // Copia della tabella per reset
        ,[ aoTableSelectorSito        ,set_aoTableSelectorSito        ] = useState([]) // Tabella Siti
        ,[ aoTableSelectorSitoHeaders ,set_aoTableSelectorSitoHeaders ] = useState([]) 
        ,[ aoTableSelectorPlacement        ,set_aoTableSelectorPlacement        ] = useState([]) // Tabella Placements
        ,[ aoTableSelectorPlacementHeaders ,set_aoTableSelectorPlacementHeaders ] = useState([]) 
        ,[ aoTableDetail        ,set_aoTableDetail        ] = useState([]) // Tabella Line-Items
        ,[ aoTableDetailHeaders ,set_aoTableDetailHeaders ] = useState([])

        // ES: { sDettaglioSitoId: '', sDettaglioSito: '', sDettaglioPlacementId: '', sDettaglioPlacement: '' } 
        // State per contenere il riferimento del dettaglio da visualizzare
        ,[ oDettaglio    ,set_oDettaglio ] = useState(null)

        // ES: { Dettaglio: `${LvlDettaglio}` , ...oRow }
        // State per contenere la riga selezionata, utilizzo questo stato di appoggio per poter fare i controlli prima di settare oDettaglio per evitare re-rendere non voluti
        ,[ oRowDettaglio ,set_oRowDettaglio ] = useState(null)

        // State per indicare allo scroll dove posizionarsi al caricamento pagina
        ,[ sIdToScroll   ,set_sIdToScroll ] = useState('')
    ;
    
    /* #region Setting Function */
    const createTitle = () => {
        // console.log('createTitle oDettaglio?.sDettaglioSito: ', oDettaglio?.sDettaglioSito)
        return  ( oDettaglio?.sDettaglioPlacement && oDettaglio?.sDettaglioSito )
                    ? { title: <span><span className='myBold'>Sito:</span> { isDemo ? 'SitoDemo' : oDettaglio?.sDettaglioSito} &emsp;</span>
                      , subTitle: <span><span className='myBold'> Placement:</span> { isDemo ? 'PlacementDemo' : oDettaglio?.sDettaglioPlacement }</span>
                      , exportTitle: `Report Venduto Invenduto ${ oDettaglio.sDettaglioSito } ${ oDettaglio.sDettaglioPlacementId }` }
                    : oDettaglio?.sDettaglioSito
                        ? { title: <span><span className='myBold'>Sito:</span> { isDemo ? 'Sito' : oDettaglio?.sDettaglioSito }</span> }
                        : { title: '', subTitle: '', exportTitle: '' };
    };

    // State per Modificare la visualizzazione Compressa/Espansa
    const settingFullMode = (field) => {
        if ( field !== sFullMode ) {
            if (field !== 'fullGraph') {
                set_aoTableDetailHeaders([...getTableHeaders('detail', field)]);
            }
            set_sFullMode( field );
        } else {
            set_sFullMode('');
        } 
    };

    // Function per popolare la selezione date di secondo livello
    const extractDate = (object) => {
        let aoDateFromPlacement = JSON.parse(object?.REPORT_JSON).map(o => { return { M: o.M } }) || [];
        return [ ...aoDateToView.filter( oDate => aoDateFromPlacement.find( oDateFromPlacement => oDateFromPlacement.M === oDate.M ) ) ];
    }

    // Function per identificare se nella selezione di secondo livello è stato selezionato un mese non più presente nella selezione di primo livello ( dopo un reload )
    const findNewDateSecondLevel = ( aoDateExtracted ) => {

        if ( aoDateToFilter.length ) {
            for ( let i = 0; i < aoDateToFilter.length; i++) {
                let bFounded = aoDateExtracted.some(o => o.M === aoDateToFilter[i].M );
                if (!bFounded) {
                    return true;
                }
            }            
            return false;
        } else {
            // Situazione di primo caricamento di dettagli
            return true;
        }
    }

    const getTableHeaders = ( sDettaglio, sFullMode ) => {
        // console.log('getTableHeaders')
        /* #region Format */
            const
                // Icona per Espandere/Comprimere il dettaglio, l'icona lancia una function che setta oRowDettaglio scatenando la useEffect associata
                 formatDettaglioSito = (val, oRow) => <div className={'toggle-dettaglio'} onClick={ () => toggleDettaglio( 'sito', oRow ) }>
                            {
                                oRow.FLAGGED === 'Y' 
                                ? <CancelIcon />
                                : <RadioDis24CheckedIcon checked={ oRow.FLAGGED === 'Y' } />
                            }
                            </div>
                ,formatDettaglioPlacement = (val, oRow) => <div id={val} className={`toggle-dettaglio`} onClick={ () => toggleDettaglio( 'placement', oRow ) }>
                                <RadioDis24CheckedIcon checked={ oRow.FLAGGED === 'Y' } />
                            </div>
                ,formatPlacementLabel = (val, oRow) => <div className={`${ oRow.REPORT_JSON === null ? 'no-data' : '' }`}>{ val }</div>
                ,formatNum0dec        = (val) => typeof val === 'number' ? utils.formatNumberWithOptions(val, { nOuputDecimals: 0 }) : ''
                ,formatNum0decEuro    = (val) => typeof val === 'number' ? utils.formatNumberWithOptions(val, { nOuputDecimals: 0 }) + ' €' : ''
                ,formatDate           = (val) => val === '-' ? val : utils.formatDate(val)
                ,formatDateExcel      = (val) => val === '-' ? val : utils.formatDateTime( val, { input: 'YYYYMMDD', output: 'DD/MM/YYYY' })
                ,formatZero           = (val) => val === 0 ? 'zero' : ''
            ;
        /* #endregion Format */
        const aoMapHeaders = [
             ( sDettaglio === 'selector-sito' && 
                    { name: 'SITE_ID'          ,title: ''                ,width:  24    ,format: formatDettaglioSito      ,isUniqueKeyForRow: true ,notSortable: true ,noFilter: true })

            ,( sDettaglio === 'selector-placement' && 
                    { name: 'PLACEMENT_ID'     ,title: ''                ,width:  24    ,format: formatDettaglioPlacement ,isUniqueKeyForRow: true ,notSortable: true ,noFilter: true })

            ,( sDettaglio === 'selector-sito' && 
                    { name: 'SITE_NAME'        ,title: 'Sito'            ,width: '100%'                                   ,isUniqueKeyForRow: true })

            ,( sDettaglio === 'selector-placement' && 
                    { name: 'PLACEMENT_NAME'   ,title: 'Placement'       ,width: '100%' ,format: formatPlacementLabel     ,isUniqueKeyForRow: true ,tooltip: true ,filterOriginalValue: true })

            ,( sDettaglio === 'detail'  && 
                { name: 'LINE_ITEM_ID'          ,title: 'Line Item Id'   ,width: 90                                       ,isUniqueKeyForRow: true ,isNum: true })

            ,( sDettaglio === 'detail'  && 
                { name: 'LINE_ITEM_NAME'        ,title: 'Line Item'      ,width: sFullMode ? '100%' : 375                 ,isUniqueKeyForRow: true ,tooltip: true })

            ,( sDettaglio === 'detail'  && 
                { name: 'BEGIN_DATE'            ,title: 'Data Inizio'    ,width:  90    ,format:formatDate            ,formatExcel: formatDateExcel })
                    
            ,( sDettaglio === 'detail'  && 
                { name: 'END_DATE'              ,title: 'Data Fine'      ,width:  90    ,format:formatDate            ,formatExcel: formatDateExcel })

            ,( sDettaglio === 'detail'  && 
                { name: 'LINE_ITEM_TYPE'        ,title: 'Tipo'           ,width: 110 })

            ,( sDettaglio === 'detail'  && 
                { name: 'IMPRESSIONS_SCHEDULED' ,title: 'Scheduled'      ,width: 120    ,format: formatNum0dec        ,additionalClass: formatZero ,isNum: true })

            ,( sDettaglio === 'detail' && 
                { name: 'VENDUTO'               ,title: 'Impressions'    ,width: 120    ,format: formatNum0dec        ,additionalClass: formatZero ,isNum: true })

            ,( sDettaglio === 'detail' && 
                { name: 'REVENUE'               ,title: 'Revenue'        ,width: 120    ,format: formatNum0decEuro    ,additionalClass: formatZero ,isNum: true })
        ];

        return aoMapHeaders;
    };

    // const setDettaglioSitoTable = (sSitoId) => {
    //     !!! TODO DA ultimare, ottimizzazione
    //     setLoading(true);
    //     // console.log('aoCopyTable: ', aoCopyTable);
    //     let aoTempTable = [];
    //     // Selezione Distinct dei Siti
    //     for( let i = 0; i < aoCopyTable.length; i++) {
    //         if ( aoCopyTable[i]?.SITE_ID === sSitoId && aoCopyTable[i]?.PLACEMENT_ID ) {
    //             aoTempTable.push( aoCopyTable[i] );
    //         }
    //     }

    //     console.log('temptable : ', aoTempTable);
    //     set_aoTableSelectorPlacement([...aoTempTable]);
    // };

    const setDettaglioPlacementTable = (sSitoId) => {
        setLoading(true);
        let aoTempTable = [];
        // Seleziono solo i Placement che mi servono
        for( let i = 0; i < aoCopyTable.length; i++) {
            if ( aoCopyTable[i]?.SITE_ID === sSitoId && aoCopyTable[i]?.PLACEMENT_ID ) { // && aoCopyTable[i]?.PLACEMENT_ID !== aoCopyTable[i-1]?.PLACEMENT_ID ) {
                aoTempTable.push( aoCopyTable[i] );
            }
        }

        set_aoTableSelectorPlacement([...aoTempTable]);
    };

    const setDettaglioPlacementGraph = (oDettaglioPlacement) => {
        let aoGraphTemp = JSON.parse(oDettaglioPlacement.REPORT_JSON);

        for( let i = 0; i < aoGraphTemp.length; i++ ) {
            aoGraphTemp[i]['M'] = formatDate(aoGraphTemp[i]['M']);
            
            // aggiungo campo fittizio calcolato sommando i valori di venduto e invenduto
            // (per i casi limite: entrambi positivi e entrambi negativi) 
            aoGraphTemp[i]['V+I'] = aoGraphTemp[i]['V'] + aoGraphTemp[i]['I'];
        }
        set_aoGraph(aoGraphTemp);
    };

    /* #endregion SettingFunction */

    /* #region handleFunction */
    const handleScroll = () => {
        let element = document.getElementById(sIdToScroll);

        if (sIdToScroll && element) {
            element.scrollIntoView({ behavior: "auto", block: "center", inline: 'center' });
            set_sIdToScroll('')
        }
    };

    /* #endregion handleFunction */
    
    /* #region ToggleFunction */
    // Funzioni per intercettare il cambiamento di dettaglio selezionato e popolare le tabelle
    const toggleDettaglio = ( label, oRow ) => {
        // Non posso settare qui il loading = false perchè se non ho un cambiamento di oRowDettaglio non esco più dal loading
        setLoading(true);
        set_oRowDettaglio({ DETTAGLIO: label , ...oRow });
    }

    /* #endregion ToggleFunction */

    /* #region CallDB */
    // Function CaricaDettaglioInpage
    const getDettaglioInpage = async (oRow, aoForceDate = false) => {
        setLoading(true);
        try {
            const oParams = {
                'getSellOrNotDetailParams': true
                ,pSiteId      : oRow.SITE_ID
                ,pPlacementId : oRow.PLACEMENT_ID
                ,pListaMesi   : aoForceDate ? aoForceDate.map(oDate => oDate.M).join('§') : aoDateToFilter.map(oDate => oDate.M).join('§') || ''
            };
           
            let response = await axios.get( config.API_URL + '/get', { headers, params: oParams } );
            
            if ( response?.status === 200 ) {
                const result = response?.data?.aoRecords;
                if ( result ) {
                    set_aoTableDetail([...result]);
                }
            }
        } catch(err) {
            console.error('ERR 130: Failed get: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
        }
    };
    /* #endregion CallDB */

    /* #region UseEffect */
    useEffect(() => {
        // console.log('UseEffect Iniziale');
        set_dLastUpdate('');

        ( async () => {
            try {
                let response = await axios.get( config.API_URL + '/get', { headers, params: { 'getDateSellOrNotParams': true }});
                let aoDateList = response?.data?.aoRecords.filter( o => (o?.GIORNI_MANCANTI || '').split('§').length < moment(o?.M, 'YYYYMM').daysInMonth() );
                if (aoDateList) {
                    set_aoDateToViewList(aoDateList);
                    /* 
                    // Questo verrà usato quando Raju avrà caricato i suoi file ( Filtrare solamente i mesi completi )
                    // Funzione per selezionare gli N mesi più recenti e completi
                    let aoTempDateList = [];
                    for (let i = 0; i <= aoDateList; i++) {
                        if ( aoTempDateList.length < 14 && !( aoDateList[i]?.GIORNI_MANCANTI?.length || false )) {
                            aoTempDateList.push(aoDateList[i]);
                        }
                    }
                    set_aoDateToView(aoTempDateList);
                    */
                    
                    set_aoDateToView(aoDateList);

                }
                set_sDateToView('reload');
                
            } catch (err) {
                set_oNotifyOptions({ message: `Interrogazione fallita, impossibile connettersi al Database`, severity:'error' });
                setLoading(false);
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // UseEffect per lanciare l'onReload in fase di atterraggio pagina solamente dopo aver settato le date di default
        if (sDateToView) {
            set_onReload(!onReload);
        }
    }, [ sDateToView ]);

    useEffect(() => {
        if ( onReload !== null ) {
            // Non Setto qui a [] le tabelle perchè scatenerei le loro UseEffect in anticipo, dovrei rivedere l'intero flusso
            setLoading(true);

            ( async () => {
                try {
                    let aoTempTable = [];
                    const oParams = {
                         'getSellOrNotParams': true
                        ,'pListaMesi'        : aoDateToView.map(oDate => oDate.M).join('§') || '' // generaMesi(12)
                    } 

                    let response = await axios.get( config.API_URL + '/get', { headers, params: oParams } );
                    const result = response?.data?.aoRecords;

                    if ( response?.status === 200 && result ) {
                        if ( result?.length ) {
                            set_aoCopyTable(result);
                            // Click OnReload, ricaricare dettaglio se selezionato
                            let oSitoDettaglio = null;
                            if  ( oDettaglio?.sDettaglioSito ) {
                                if ( oDettaglio?.sDettaglioPlacementId ) {

                                    // Non devo cercare solo il singolo placement ma mappare e modificare il singolo record
                                    for( let i = 0; i < aoCopyTable.length; i++) {
                                        if ( result[i]?.SITE_ID === oDettaglio.sDettaglioSitoId && result[i]?.PLACEMENT_ID ) {
                                            if (result[i]?.PLACEMENT_ID === oDettaglio?.sDettaglioPlacementId) {
                                                oSitoDettaglio = result[i];
                                                aoTempTable.push({ ...oSitoDettaglio, FLAGGED: 'Y' });
                                                let aoDateExtracted = utils.sortBy(extractDate(oSitoDettaglio),['M'],['desc']);
                                                set_aoDateToFilterList(aoDateExtracted);
                                                if (findNewDateSecondLevel(aoDateExtracted)) {
                                                    set_aoDateToFilter(aoDateExtracted)
                                                };
                                            } else { 
                                                aoTempTable.push( result[i] );
                                            }
                                        }
                                    }
                                    set_aoTableSelectorPlacement(aoTempTable);
                                    setDettaglioPlacementGraph(oSitoDettaglio);
                                    set_sIdToScroll(oDettaglio?.sDettaglioPlacementId);
                                } else {
                                    oSitoDettaglio = result.find( oRow => oDettaglio.sDettaglioSitoId === oRow.SITE_ID);
                                }

                                if ( oSitoDettaglio === null ) {
                                    // Se oSitoDettaglio è ancora null dopo essere entrato qui dentro significa che non ho più trovato i dettagli richiesti nel db,
                                    // quindi svuoto le tabelle di dettaglio e il grafico
                                    // Forse posso raggruppare questa condizione nel prossimo if
                                    let sMessage = `${ oDettaglio.sDettaglioPlacement ? `Placement ${oDettaglio.sDettaglioPlacement}` : `Sito ${oDettaglio.sDettaglioSito}`} non trovato`;
                                    set_oDettaglio({ sDettaglioSito: '', sDettaglioPlacement: '' });
                                    set_oNotifyOptions({ message: sMessage, severity: 'warning' });
                                    set_aoTableDetail([]);
                                    set_aoTableSelectorPlacement([]);
                                    set_aoGraph([]);
                                } else {
                                    aoTempTable = [{ ...oSitoDettaglio, FLAGGED: 'Y' }];
                                }
                            }

                            if ( !oSitoDettaglio ) {
                                // Situazione Atterraggio pagina oppure in cui avevo selezionato un dettaglio ma non lo trovo più all'onReload
                                for( let i = 0; i < result.length; i++) {
                                    if ( result[i].SITE_ID !== result[i-1]?.SITE_ID) {
                                        // !!!NECESSARIO ASSOLUTAMENTE CHE IL RECORDSET SIA ORDINATO PER SITO
                                        aoTempTable.push( result[i] );
                                    }
                                }
                            }
                            set_aoTableSelectorSitoHeaders([...getTableHeaders('selector-sito')]);
                            set_aoTableSelectorPlacementHeaders([...getTableHeaders('selector-placement')]);
                            set_aoTableDetailHeaders([...getTableHeaders('detail')]);
                            set_aoTableSelectorSito([...aoTempTable]);
                            
                        } else {
                            set_aoTableSelectorSito([]);
                            set_aoTableSelectorPlacement([]);
                            set_aoTableDetail([]);
                            set_aoGraph([]);
                            setLoading(false);
                        }
                    } else {
                        throw new Error('error in getDettaglioOccupazione');
                    }
                    
                } catch(err) {
                    console.error('ERR 130: Failed get: ', err);
                    set_aoTableSelectorSito([]);
                    set_aoTableSelectorPlacement([]);
                    set_aoTableDetail([]);
                    set_aoGraph([]);
                    set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
                    setLoading(false);
                }
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);

    useEffect(() => {
        // console.log('UseEffect oRowDettaglio')
        (async () => { if ( oRowDettaglio !== null ) {
            setLoading(true);
            // Qui ci entro solamente dopo aver selezionato un dettaglio
            if ( oRowDettaglio?.DETTAGLIO === 'placement' && ( oRowDettaglio?.PLACEMENT_ID !== oDettaglio?.sDettaglioPlacementId )) {
                // Ottimizzazione, invece che modificare tutti i record si potreppe modificare solo quello selezionato precedentemente a 'N' e quello nuovo a 'Y', manca l'index nell'array
                
                let sTempIdToScroll = oRowDettaglio?.PLACEMENT_ID ? 
                                        oRowDettaglio?.PLACEMENT_ID
                                        : ''
                ;
                let aoTempTablePlacement = aoTableSelectorPlacement.map(oRow => oRow.PLACEMENT_ID === oRowDettaglio?.PLACEMENT_ID ? { ...oRow, FLAGGED: 'Y' } : { ...oRow, FLAGGED: 'N' });
                let aoDateExtracted = utils.sortBy(extractDate(oRowDettaglio),['M'],['desc']);
                set_aoDateToFilterList(aoDateExtracted);
                let bNewDate = findNewDateSecondLevel(aoDateExtracted);
                if (bNewDate) {
                    set_aoDateToFilter(aoDateExtracted);
                }

                Promise.all([
                    set_aoTableSelectorPlacement(aoTempTablePlacement),
                    setDettaglioPlacementGraph(oRowDettaglio),
                    getDettaglioInpage(oRowDettaglio, bNewDate ? aoDateExtracted : aoDateToFilter),
                    set_sIdToScroll(sTempIdToScroll)
                ]).then(() => { set_oDettaglio({  sDettaglioSito: oRowDettaglio.SITE_NAME
                                                , sDettaglioSitoId: oRowDettaglio.SITE_ID
                                                , sDettaglioPlacementId: oRowDettaglio.PLACEMENT_ID
                                                , sDettaglioPlacement: oRowDettaglio?.PLACEMENT_NAME }); });

            } else if ( oRowDettaglio?.DETTAGLIO === 'sito' && ( oRowDettaglio?.SITE_ID !== oDettaglio?.sDettaglioSitoId )) {
                // console.log('toggleDettaglioSito: ', oRowDettaglio?.SITE_ID);
                set_aoTableSelectorSito([{ ...oRowDettaglio, FLAGGED: 'Y' }]);

            } else if ( oRowDettaglio?.DETTAGLIO === 'sito' && ( oRowDettaglio?.SITE_ID === oDettaglio?.sDettaglioSitoId ) ) {
                // console.log('toggleDettaglioSito: ripristino tabella siti');
                let aoTempTable = [];
                for( let i = 0; i < aoCopyTable.length; i++) {
                    if ( aoCopyTable[i].SITE_ID !== aoCopyTable[i-1]?.SITE_ID ) {
                        aoTempTable.push( aoCopyTable[i] );
                    }
                }
                set_aoTableSelectorSito([...aoTempTable]);
                
            } else {
                // console.log('va te che nabbo, hai premuto sullo stesso placement?');
                setLoading(false)
            }
        }})();
    },[ oRowDettaglio ])

    useEffect(() => {
        set_sExportTitle('');
        if ( oDettaglio !== null ) {
            const { title, subTitle, exportTitle } = createTitle();
            exportTitle && set_sExportTitle( exportTitle );

            set_cTitle(<span className='flexyTitle'>
                        <span>
                            { title }
                        </span>
                        {
                            subTitle && <span className='dettaglioDescription'>{ subTitle }</span>
                        }
                    </span>);

            // salvavita ipotetico per toglare la selezione del sito
            set_oRowDettaglio(null);
            setLoading(false);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ oDettaglio ]);

    useEffect(() => {
        if ( onReload !== null ) {
            if ( oRowDettaglio?.DETTAGLIO === 'sito' ) {
                // Se entriamo qua dentro significa che abbiamo selezionato un sito
                if ( oRowDettaglio?.SITE_ID !== oDettaglio?.sDettaglioSitoId ) {
                    // console.log('oRowDettaglio?.SITE_ID !== oDettaglio?.sDettaglioSitoId');
                    // Se entriamo qua o è la prima selezione o stiamo cambiando sito
                    setDettaglioPlacementTable(oRowDettaglio.SITE_ID);
                    set_oDettaglio({ sDettaglioSito: oRowDettaglio.SITE_NAME, sDettaglioSitoId: oRowDettaglio.SITE_ID, sDettaglioPlacement: '', sDettaglioPlacementId: '' });

                } else {
                    // Se entriamo qua abbiamo ripremuto sullo stesso sito per nasconderlo, la tabella Placement Sarà sempre popolata con almeno un record (altrimenti non vedremmo il sito)
                    // , quindi scateneremo sempre la useEffect associata a aoTableSelectorPlacement perchè lo stiamo sempre svuotando
                    // console.log('aoTableSelectorSito oRowDettaglio.sSitoId === sito_id');
                    set_aoTableSelectorPlacement([]);
                    set_oDettaglio({ sDettaglioSito: '', sDettaglioSitoId: '', sDettaglioPlacement: '', sDettaglioPlacementId: '' });

                }
            } else if ( oRowDettaglio === null ) {
                // Situazione di atterraggio pagina e OnReload
                if ( oDettaglio === null ) {
                    // Atterraggio Pagina
                    setLoading(false);

                } else if ( oDettaglio?.sDettaglioPlacementId ) {
                    // Situazione in cui si aveva preselezionato un placement
                    Promise.all([
                        getDettaglioInpage({ SITE_ID: oDettaglio?.sDettaglioSitoId ,PLACEMENT_ID: oDettaglio?.sDettaglioPlacementId })
                    ]).then(() => setLoading(false));

                } else if ( oDettaglio?.sDettaglioSitoId ) {
                    // Situazione di preselezione di un sito
                    setDettaglioPlacementTable(oDettaglio?.sDettaglioSitoId);

                }
            } else {
                // console.log("Se sei arrivato fin qui chiama l'amministratore, abbiamo un problema!!");
            }
        }
    }, [ aoTableSelectorSito ]);

    useEffect(() => {
        if ( onReload !== null ) {
            if ( oRowDettaglio?.DETTAGLIO === 'sito' ) {
                set_aoGraph([]);
                set_aoTableDetail([]);
                setLoading(false);
                
            } else if ( oRowDettaglio?.DETTAGLIO === 'placement' ) {
                // Va bene entrare qui perchè abbiamo modificato la Key Flagged
                return;
            } else if ( oRowDettaglio === null ) {
                // Click OnReload
                if ( !oDettaglio?.sDettaglioPlacementId ) {
                    setLoading(false);
                }
            }
        }
    }, [ aoTableSelectorPlacement ]);

    useEffect( () => {
        if ( loading === false &&  sIdToScroll) {
            handleScroll();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ loading ]);

    /* #endregion UseEffect */

    /* #region Component */
    const multiSelectCheck = ( label, aoMesi, set_aoMesi, aoMesiList ) => {
        const handleChange = (event, values) => {
            if ( values.length < 1 ) {
                set_oNotifyOptions({ message: `Attenzione nessun mese selezionato`, severity:'warning', timeout: 5 });
            } else if ( values.length < 14 ) {
                // Necessario ordinare le values
                set_aoMesi(utils.sortBy(values,['M'],['desc']))
                if ( label === 'mese-second-level' ) {
                    set_onReload( b => !b );
                }
            } else {
                set_oNotifyOptions({ message: 'Necessario selezionare almeno un mese', severity:'warning', timeout: 5 });
            }
        };
    
        return (
            <Autocomplete
                id  = {`auto-check-${ label }`}
                key = {`auto-check-${ label }`}
                multiple
                disableCloseOnSelect
                disableClearable
                limitTags = {4}
                options   = {aoMesiList}
                // Questo verrà usato quando Raju avrà caricato i suoi file, serve per disabilitare alcuni mesi senza nasconderli
                // getOptionDisabled={(option) => 
                //     !option?.GIORNI_MANCANTI?.length || true
                //     // console.log(option?.GIORNI_MANCANTI?.length)
                // }
                getOptionLabel = {(option) => option?.M }
                value    = { aoMesi }
                onChange = {(event, values) => handleChange(event, values)}
                isOptionEqualToValue = { (option, value) => option.M === value.M }
                renderOption   = {(props, option, { selected }) => {
                    let asGiorniMancanti = (option?.GIORNI_MANCANTI || '').split('§') || [];
                    return  <li {...{...props
                                      ,className: `${props.className} ${ 
                                            option?.GIORNI_MANCANTI 
                                                ? asGiorniMancanti.length < moment(option?.M, 'YYYYMM').daysInMonth()
                                                    ? 'mancano-giorni'
                                                    : 'mancano-tutti-giorni' 
                                                : '' }`}} 
                                title={ option?.GIORNI_MANCANTI
                                    ? `${ moment(option?.M, 'YYYYMM').format('MMM YYYY') }: ${ 
                                            asGiorniMancanti.length < moment(option?.M, 'YYYYMM').daysInMonth() 
                                                ? `${ asGiorniMancanti.length } giorni mancanti (${asGiorniMancanti.join(', ')})`
                                                : 'mancano tutti i giorni' }`
                                    : '' }>
                                <Checkbox checked={selected} />
                                <span className='selector-date-option'>{ moment(option?.M, 'YYYYMM').format('MMM YYYY') }</span>
                            </li>
                }}
                renderTags = {(value, getTagProps) =>
                    value.map((val, index) => {
                        let asGiorniMancanti = (val?.GIORNI_MANCANTI || '').split('§') || [];
                        return <Chip
                            variant="outlined"
                            label={moment(val?.M, 'YYYYMM').format('MMM YYYY')}
                            size="small"
                            {...getTagProps({ index })}
                            color={ val?.GIORNI_MANCANTI 
                                    ? asGiorniMancanti.length < moment(val?.M, 'YYYYMM').daysInMonth()
                                        ? 'warning'
                                        : 'error' 
                                    : 'default' }
                            title={ val?.GIORNI_MANCANTI
                                ? `${ moment(val?.M, 'YYYYMM').format('MMM YYYY') }: ${ 
                                        asGiorniMancanti.length < moment(val?.M, 'YYYYMM').daysInMonth() 
                                            ? `${ asGiorniMancanti.length } giorni mancanti (${asGiorniMancanti.join(', ')})`
                                            : 'mancano tutti i giorni' }`
                                : '' }
                        />
                    })
                }
                renderInput = {(params) => (
                    <CustomCssTextField {...params} />
                )}
                className='selector-date'
                size = "small"
                sx   = {{ width: '550px' }}
            />
        );
    }
    /* #endregion Component */

   return (
        <div className="component-container sell-or-not">
            <div className="component-card">  
                <div className="component-head-options-wrapper">
                    <span className='options-buttons-wrapper'>
                        <span className='users-tooltip-wrapper'>
                            <CustomNoWidthTooltip
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">Visualizzazione Report</Typography>
                                            <br/>Selezionare i mesi desiderati e premere l'icona di ricarica <i className='fa-regular fa-rotate'/>
                                            <br/>(Per i mesi evidenziati in arancione mancano alcuni file giornalieri)
                                        <br/><br/>Selezionare il sito: vengono nascosti tutti i siti non selezionati.
                                            <br/>Per mostrare nuovamente la tabella dei siti premere sulla relativa icona X associata al sito precedentemente selezionato.
                                        <br/><br/>Selezionare il placement: verranno mostrati il grafico e la tabella di lineitems associati.
                                        <br/><br/>Selezionare i mesi nel campo associato alla tabella lineitems per filtrare i lineitems relativi al periodo selezionato.
                                    </React.Fragment>
                                }
                                placement="bottom"
                                arrow
                            >
                            <InfoIcon className={'infoIcon'}/>
                            </CustomNoWidthTooltip>
                        </span>
                        <span className={`title-sell-or-not ${oDettaglio?.sDettaglioSito} ${oDettaglio?.sDettaglioPlacement}`}>
                            { cTitle }
                        </span>
                    </span>
                    <span className='options-buttons-wrapper'>
                        { 
                            multiSelectCheck( 'mese-first-level', aoDateToView, set_aoDateToView, aoDateToViewList ) 
                        }
                        <IconButton className="reloadButton link myShadow" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ () => set_onReload(!onReload) }>
                            <CachedIcon />
                        </IconButton>
                    </span>
                </div>
                <div className="component-wrapper">
                 { loading ? <CircleProgress /> : <>
                    { sFullMode === '' ?
                        // Tabella Selezione Sito | Placement
                        <div className={`sellOrNot-selector-wrapper`}>
                            <div className={`table-wrapper selector-sito`}>
                                {
                                    aoTableSelectorSito?.length ?
                                        <SimpleTable
                                            chiave            ={ 'SellOrNot-Selector-Sito' }
                                            sTableDataType    ={ 'SellOrNot-Selector-Sito' }
                                            aoRows            ={ aoTableSelectorSito }
                                            aoCols            ={ aoTableSelectorSitoHeaders }
                                            oSortOptions      ={{ isSortable: true }}
                                            oExportOption     ={{ isExportable: false }}
                                        />
                                    : <div className="sellOrNotNoRecord-wrapper">
                                        <FolderOffIcon />&nbsp;Nessun risultato trovato
                                    </div>
                                }
                            </div>
                            { !oDettaglio?.sDettaglioSitoId  ? <></> :
                                <div className={`table-wrapper selector-placement`}>
                                    { aoTableSelectorPlacement?.length ?
                                        <SimpleTable
                                            chiave            ={ 'SellOrNot-Selector-Placement' }
                                            sTableDataType    ={ 'SellOrNot-Selector-Placement' }
                                            aoRows            ={ aoTableSelectorPlacement }
                                            aoCols            ={ aoTableSelectorPlacementHeaders }
                                            oSortOptions      ={{ isSortable: true }}
                                            oExportOption     ={{ isExportable: false }}
                                        />
                                    : <div className="sellOrNotNoRecord-wrapper">
                                        <FolderOffIcon />&nbsp;Nessun risultato trovato
                                    </div> }
                                </div>
                            }
                        </div>
                    : <></> }
                    <div className={`sellOrNot-detail-wrapper ${sFullMode !== '' ? 'full' : '' }`}>
                        { oDettaglio?.sDettaglioPlacement
                        ? <>
                            { sFullMode !== 'fullTable' ?
                                <div className={`graph-wrapper ${sFullMode === 'fullGraph' ? 'full-graph' : '' }`}>
                                    { !oDettaglio?.sDettaglioPlacementId ? <></> :
                                        // Grafici
                                        aoGraph?.length ?
                                            <>
                                                <span className='group-detail-buttons-wrapper'>
                                                    <Button className={`fullViewButton link myShadow`} title={`${sFullMode === 'fullGraph' ? 'Comprimi' : 'Espandi'}`} 
                                                            variant="outlined" onClick={ () => settingFullMode('fullGraph') } disableElevation>
                                                        { sFullMode === 'fullGraph' ? <FullScreenOffIcon /> : <FullScreenOnIcon /> } 
                                                    </Button>
                                                </span>
                                                <Graph
                                                    oGraphContent = {{
                                                         sChartType      : 'mix-bar-line'
                                                        ,sChartMode      : 'stacked'
                                                        ,aoRows          : aoGraph
                                                        ,asElementX      : [ 'M' ]
                                                        ,asElementY      : [ 'V' , 'I' ]
                                                        ,asElementYmirror: [ 'R' ]
                                                        ,sLegendColor    : [ '#E8C1A0', '#ff9a88', 'black' ] // Ultimo colore corrispondente a asYmirror
                                                        ,oMappingField
                                                        ,nRotationLabelX : 0
                                                        ,bEnableTotals   : true
                                                        ,enableLabel     : true
                                                        ,sMinMaxCustomField : 'V+I'
                                                        ,sAppendFormatLineMix : '€'
                                                        ,formattedLabelValue  : (o) => (
                                                            Math.abs( o.value ) / ( (
                                                                  Math.abs( o.data[ oMappingField['V'].description ] )
                                                                + Math.abs( o.data[ oMappingField['I'].description ] )
                                                            ) || 1 ) * 100
                                                        ).toFixed(0) + ' %'
                                                    }}
                                                    className ="grafico-bar-stacked"
                                                />
                                            </> 
                                
                                        : <div className="sellOrNotNoRecord-wrapper">
                                            <FolderOffIcon />&nbsp;Nessun risultato trovato
                                        </div>
                                    }
                                </div>
                                : <></>
                            }
                            
                            { sFullMode !== 'fullGraph' ?
                                // Tabella
                                <div className={`table-wrapper detail-line-items ${sFullMode === 'fullTable' ? 'full-table' : '' }`}>
                                    { !oDettaglio?.sDettaglioPlacementId ? <></> :
                                        aoTableDetail?.length ?
                                            <>
                                                <span className='group-detail-buttons-wrapper'>
                                                    { 
                                                        multiSelectCheck( 'mese-second-level', aoDateToFilter, set_aoDateToFilter, aoDateToFilterList ) 
                                                    }
                                                    <Button className={`fullViewButton link myShadow`} title={`${sFullMode === 'fullTable' ? 'Comprimi' : 'Espandi'}`} 
                                                            variant="outlined" onClick={ () => settingFullMode(sFullMode ? '' : 'fullTable') } disableElevation>
                                                        { sFullMode === 'fullTable' ? <FullScreenOffIcon /> : <FullScreenOnIcon /> } 
                                                    </Button>
                                                </span>
                                                <SimpleTable
                                                    chiave            ={ 'SellOrNot-Detail' }
                                                    sTableDataType    ={ 'SellOrNot-Detail' }
                                                    aoRows            ={ aoTableDetail }
                                                    aoCols            ={ aoTableDetailHeaders }
                                                    oSortOptions      ={{ isSortable: true }}
                                                    oExportOption     ={{ sFileName: sExportTitle }}// `Report-Venduto_Invenduto${ dettaglio && ('-' + dettaglio2) }` } }
                                                />
                                            </>
                                        : <div className="sellOrNotNoRecord-wrapper">
                                            <FolderOffIcon />&nbsp;Nessun risultato trovato
                                        </div>
                                    }
                                </div>
                            : <></> }
                        </>
                        : <></>}
                    </div>
                 </> }
                </div>
           </div>
       </div>
   )
};

export default SellOrNot;
