import React, {useContext, useState, useEffect}  from 'react';
import { Button     
       , Checkbox         
       , FormControl      
       , FormHelperText   
       , IconButton       
       , Input            
       , InputAdornment } from '@mui/material';

import { AuthContext }                           from '../../contexts/authContext';
import { AppContext }                            from '../../App.js';
import { CustomMaxWidthTooltip }                 from '../../components/CustomCSS/CustomCSS.tsx';
import CircleProgress                            from '../../components/CircleProgress/CircleProgress.tsx'
import config                                    from '../../config.js';
import utils                                     from '../../util/CommonUtilities.js'
import axios                                     from 'axios';
import './CreateUser.css';

import { AccountCircleIcon
       , AlternateEmailIcon
       , InfoIcon
       , KeyIcon
       , PinIcon
       , SupervisorAccountIcon
       , VisibilityIcon
       , VisibilityOffIcon
       , TraffickerIcon }                      from '../../assets/icons/icons.js';
 
const bDebug = config.DEBUG && false;

// const regexEmailValidator = /^[a-z0-9]+([.\-_]?[a-z0-9]+)+@[a-z0-9]+([.\-_]?[a-z0-9]+)+\.[a-z]{2,}$/;
// const regexPasswordValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

const CreateUser = (props) => {
    const [ loading,              setLoading               ] = useState(false);
    const [ email,                setEmail                 ] = useState('');
    const [ emailCheck,           set_emailCheck           ] = useState(false);
    // const [ password,             setPassword              ] = useState('');
    // const [ passwordCheck,        set_PasswordCheck        ] = useState(false);
    // const [ showPassword,         setShowPassword          ] = useState(false);
    // const [ passwordConfirm,      setPasswordConfirm       ] = useState("");
    // const [ passwordConfirmCheck, set_PasswordConfirmCheck ] = useState(false);
    // const [ showPasswordConfirm,  setShowPasswordConfirm   ] = useState(false);
    const [ cognome,              setCognome               ] = useState('');
    const [ pAgenteCode,          setPAgenteCode           ] = useState('');
    const [ admin,                setAdmin                 ] = useState(false);
    const [ trafficker,           setTrafficker            ] = useState(false);
    const [ bBtnEnabled,          set_bBtnEnabled          ] = useState(false);
    // const sHelperPswAccordant = 'Password non conforme';
    // const sHelperPswEqual     = 'Le due password non coincidono';

    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);
    const authContext = useContext(AuthContext);
    
    useEffect(() => {
        set_dLastUpdate('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        set_emailCheck(utils.regexEmailValidator.test(email));
        bDebug && console.log('emailCheck: ', emailCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[email]);

    // useEffect(()=>{
    //     set_PasswordCheck(regexPasswordValidator.test(password));
    //     bDebug && console.log('passwordCheck: ', passwordCheck);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[password]);

    // useEffect(()=>{
    //     set_PasswordConfirmCheck(regexPasswordValidator.test(passwordConfirm));
    //     bDebug && console.log('passwordCheck: ', passwordConfirmCheck);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[passwordConfirm]);

    const isErrorFormat = ( param, field ) => {
        let retVal = true;
        if ( param !== "") {
    //         if ( field === 'psw' ) {
    //             retVal = !passwordCheck;
    //         } else if ( field === 'confirm') {
    //             if ( password === passwordConfirm ) {
    //                 retVal = !passwordConfirmCheck ;
    //             }
    //         } else 
            if ( field === 'email' ) {
                retVal = !emailCheck;
            } else {
                return false;
            }
        } else {
            retVal = false;
        }
        bDebug && console.log('retVal: ', retVal);
        
        return retVal;
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.key === 'Enter') {
            e.preventDefault();
            if ( bBtnEnabled ) {
                signup();
            };
        };
    };

    const signup = async () =>{
        setLoading(true);
        try {
            let url = `${config.API_URL}/ins-user`;
            let params = {
                 pUserName       : email
                ,pEMailAddress   : email
                ,pCognome        : cognome
                // ,pPassword       : password
                ,pAgenteCod      : pAgenteCode
                ,pFlagAdmin      : admin ? 'Y' : 'N'
                ,pFlagTrafficker : trafficker ? 'Y' : 'N'
            };

            let response = await axios.post(
                url,
                null,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authContext.sessionInfo.accessToken}`
                    },
                    params
                }
            )
            if( response.status === 200 && response?.data ){
                // console.log('res', response)
                if ( !response?.data?.vErrorMessageDesc ) {
                    try {                
                        setEmail('');
                        // setPassword('');
                        // setPasswordConfirm('');
                        // setShowPasswordConfirm(false);
                        // setShowPassword(false);
                        setCognome('');
                        setAdmin(false);
                        setTrafficker(false);
                        setPAgenteCode('');
                        await authContext.sendCode(email); // Forza forgot Password
                        set_oNotifyOptions({ message: 'Creazione nuovo Utente avvenuta con successo', severity:'success', timeout: 2 });
                    } catch (err) {
                        console.error('ERR 134: ', err);
                        set_oNotifyOptions({ message: `Utente ${ email } creato, ma non è stato possibile inviare l'email di creazione password`, severity:'error' });
                    }
                } else {
                    set_oNotifyOptions({ message: response?.data?.vErrorMessageDesc, severity:'error' });
                }
            }else{
                console.error('ERR 133 Failed Signup: ', response);
                set_oNotifyOptions({ message: `${response?.data || 'Errore imprevisto'}`, severity:'error' });
            }
        } catch (err) {
            console.error('ERR 132: ' + err);
            set_oNotifyOptions({ message: 'Errore imprevisto', severity:'error' });
        }
        setLoading(false);
    }

    useEffect(() => {
        const control = ( email && emailCheck ) && ( cognome.length > 0 ); /*( password && passwordCheck ) && ( passwordConfirm && passwordConfirmCheck ) && ( password === passwordConfirm ) && */
        set_bBtnEnabled(control);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ email, emailCheck, cognome, pAgenteCode, admin ]); /* password, passwordCheck, passwordConfirm, passwordConfirmCheck, */

    return (
        <div className="component-container change-password">
        <div className="component-card flexy">
        { loading && <CircleProgress /> }
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3 change-password-container">
                <div className="app-login-main-content">
                    <div className="app-login-content-noAuth">
                        <span className='info-icon'>
                            <CustomMaxWidthTooltip
                                title     ={
                                    `Dopo aver creato l'Utente, un Admin dovrà attivarlo nella Gestione Utenti. 
                                    L'Utente stesso dovrà impostare la propria password attraverso il link 
                                    che avrà ricevuto al suo indirizzo email.`
                                }
                                placement ="top"
                                arrow
                            >
                                <InfoIcon className={'infoIcon'}/>
                            </CustomMaxWidthTooltip>
                        </span>
                        <form onSubmit={handleSubmit} className="login-credentials-wrapper">
                            <div className="form-group withPadding withMargin">
                                <FormControl className="input-field full-width login-credentials-wrapper" >
                                    <Input
                                        name            = "cognome"
                                        id              = "cognome"
                                        value           = { cognome }
                                        placeholder     = "Nome"
                                        onChange        = { e => { setCognome(e.target.value); } }
                                        startAdornment  = {
                                            <InputAdornment position="start">
                                                <AccountCircleIcon className={"noHover textDark"}/>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div className="form-group">
                                <FormControl className="input-field full-width login-credentials-wrapper">
                                    <Input
                                        name            = "email"
                                        id              = "email"
                                        placeholder     = "Email"
                                        value           = { email }
                                        onChange        = { e => { setEmail(e.target.value); } }
                                        error           = { isErrorFormat( email, 'email' ) }
                                        spellCheck      = { false }
                                        startAdornment  = {
                                            <InputAdornment position="start">
                                                <AlternateEmailIcon className={"noHover textDark"}/>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="standard-password-helper-text" className='helperText' >{ email && !emailCheck ? 'Email non conforme' : '' }</FormHelperText>
                                </FormControl>
                            </div>
                            {/* <span className='criteriPass grey  '>Password minimo 8 caratteri, 1 maiuscolo, 1 minuscolo, <br />1 numero, 1 simbolo</span>
                            <div className="form-group">
                                <FormControl className="input-field full-width login-credentials-wrapper">
                                    <Input
                                        name            = "password"
                                        id              = "password"
                                        placeholder     = "Password"
                                        type            = { showPassword ? 'text' : 'password' }
                                        value           = { password }
                                        onChange        = { e => { setPassword(e.target.value); } }
                                        error           = { isErrorFormat( password, 'psw' ) }
                                        spellCheck      = { false }
                                        startAdornment  = {
                                            <InputAdornment position="start">
                                                <KeyIcon addClass={"noHover textDark"}/>
                                            </InputAdornment>
                                        }
                                        endAdornment    = {
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={ e => { setShowPassword(!showPassword);; } }
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityIcon addClass={"textDark"}/> : <VisibilityOffIcon addClass={"textDark"}/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="standard-password-helper-text" className='helperText' >{ password && !passwordCheck ? sHelperPswAccordant : '' }</FormHelperText>
                                </FormControl>
                            </div>
                            <div className="form-group">
                                <FormControl className="input-field full-width login-credentials-wrapper">
                                    <Input
                                        name            = "passwordConfirm"
                                        id              = 'passwordConfirm'
                                        placeholder     = "Conferma Password"
                                        type            = { showPasswordConfirm ? 'text' : 'password' }
                                        value           = { passwordConfirm}
                                        onChange        = {  e => { setPasswordConfirm(e.target.value); } }
                                        error           = { isErrorFormat( passwordConfirm, 'confirm') }
                                        spellCheck      = { false }
                                        startAdornment  = {
                                            <InputAdornment position="start">
                                                <KeyIcon addClass={"noHover textDark"}/>
                                            </InputAdornment>
                                        }
                                        endAdornment    = {
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={e => { setShowPasswordConfirm(!showPasswordConfirm); } } 
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    { showPasswordConfirm ? <VisibilityIcon addClass={"textDark"}/> : <VisibilityOffIcon addClass={"textDark"}/> }
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText id="standard-password-helper-text" className='helperText' >
                                                    { 
                                                        ( passwordConfirm && !passwordConfirmCheck ) ? 
                                                            sHelperPswAccordant 
                                                            : (passwordConfirm && ( passwordConfirm !== password ) ) ?
                                                                sHelperPswEqual
                                                                : '' }</FormHelperText>
                                </FormControl>
                            </div> */}
                            
                            <div className="form-group with-min-margin">
                                <FormControl className="input-field full-width login-credentials-wrapper">
                                    <Input
                                        name            = "pAgenteCode"
                                        id              = "pAgenteCode"
                                        value           = { pAgenteCode }
                                        placeholder     = "Codice Agente (opzionale)"
                                        onChange        = { e => { setPAgenteCode(e.target.value); } }
                                        startAdornment  = {
                                            <InputAdornment position="start">
                                                <PinIcon className={"noHover textDark"}/>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                            <div className="form-group">
                                <FormControl className="input-field flag-admin full-width">
                                    <div className='myFlexCreateUser'>
                                        <SupervisorAccountIcon className={"noHover textDark"}/>
                                        <span>&nbsp; Admin</span>
                                    </div>
                                    <Checkbox
                                        name     = "admin"
                                        id       = "admin"
                                        checked  = {admin}
                                        onChange = { e => { setAdmin(!admin); } }
                                    />
                                </FormControl>
                            </div>
                            <div className="form-group">
                                <FormControl className="input-field flag-admin full-width">
                                    <div className='myFlexCreateUser'>
                                        <TraffickerIcon className={"noHover textDark"}/>
                                        <span>&nbsp; Trafficker</span>
                                    </div>
                                    <Checkbox
                                        name     = "trafficker"
                                        id       = "trafficker"
                                        checked  = {trafficker}
                                        onChange = { e => { setTrafficker(!trafficker); } }
                                    />
                                </FormControl>
                            </div>
                            <div className="submit-btn-wrapper" style={{marginTop: 20}}>
                                {/* <Button type="button" className="text-uppercase login-btn back" onClick={goToPreviousPage}>
                                    Indietro
                                </Button> */}
                                <Button
                                    type="button"
                                    className="text-uppercase submit-btn"
                                    onClick={signup}
                                    disabled={ !bBtnEnabled }
                                >
                                    Crea Utente
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default CreateUser
