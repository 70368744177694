import './SimpleTable.css';
import React, {useEffect, useRef, useState} from 'react';
// import { Paper, Checkbox, MenuItem, Select} from '@material-ui/core';
import { DownLoadIcon
       , EditIcon }     from '../../assets/icons/icons.js'; // '@mui/icons-material/Edit';
import { Button
       , Checkbox
       , MenuItem
       , Paper
       , Select
       , TextField
       , Tooltip      } from '@mui/material';
import PaginationTool   from '../PaginationTool/PaginationTool';
import utils            from '../../util/CommonUtilities.js';
import moment           from 'moment';
const bDebug             = false;
const removeSpecialChars = s => ( ['string','number'].includes(typeof s) ? s : '' ).toString().replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
let timeoutIdOnHover     = 0;
const env = process.env.REACT_APP_ENV;
const isDemo  = env.includes('demo');

function numeroCasualeDecimale( n = 1 ) {
    const
         valori = [ 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8 ]
        ,oggi   = new Date()
        ,giorno = oggi.getDate()
        ,mese   = oggi.getMonth()
        ,anno   = oggi.getFullYear()
        ,numero = anno * 10000 + mese * 100 + giorno + n
        ,indice = numero % valori.length
    ;
    return valori[indice];
}

// sortBy consente di ordinare un array di oggetti in base ad un array di proprietà (chiavi) scelte,
// specificando per ognuna se in ordine crescente o decrescente
const sortBy = ( arrayOfObjects, arrayOfProperties, arrayOfSortingType ) => {
    // console.log('arrayOfProperties: ', arrayOfProperties)
    // console.log('arrayOfSortingType: ', arrayOfSortingType)
    // se non è un array, lo converto in array ( per chiamare il sortBy con un solo parametro di tipo string )
    if ( !Array.isArray(arrayOfProperties) ) { arrayOfProperties = [ arrayOfProperties + '' ]; }

    let
        // toStr serve per avere stringhe simili da confrontare (i falsy vengono uniformati)
         toStr                = anyVar => ( anyVar || 0 ).toString()
        // uniformRes serve per uniformare i risultati del localeCompare ( -1 0 +1 )
        ,uniformRes           = ( res, sortType ) => ( 
            ( ( res < 0 ) ? -1 : ( res > 0 ? 1 : 0 ) ) * ( [ 'DESC', '-1' ].includes( ( sortType + '' ).toUpperCase() ) ? -1 : 1  )
        )
        // compare restituisce un valore uniformato per la comparazione di stringhe
        ,compare              = ( e1, e2, sortType ) => uniformRes(
              toStr(e1).localeCompare( toStr(e2), 'en', {numeric: true, sensitivity: 'base'} )
             ,sortType
        )
        // validateString controlla se è una stringa non vuota
        ,validateString       = str        => ( typeof str === 'string' && str !== '' ) ? str : ( 'err: "' + str + '"' )
        // validateProperty restituisce il valore di quella proprietà (eventualmente normalizzato a zero)
        ,validateProperty     = ( elToDebug, prop )  => {
            bDebug && console.assert(
                 typeof elToDebug[prop] !== 'undefined'
                ,'ERROR in sortBy: proprietà ' + prop + ' non trovata in'
                ,elToDebug
            );
            return elToDebug[prop] || 0;
        }
        // compareAllProperties restituisce ( -1 0 +1 ) in base al confronto di tutte le proprietà dei due oggetti
        ,compareAllProperties = ( obj1, obj2, props = [], sortTypes = [] ) => {
            let retVal = 0;
            for ( let nProp = 0; nProp < props.length; nProp++ ) {
                const property = validateString(props[nProp]);
                retVal         = retVal || compare(
                     validateProperty(obj1, property)
                    ,validateProperty(obj2, property)
                    ,sortTypes[nProp]
                );
            }
            return retVal;
        }
    ;
    // restituisce l'array di oggetto ordinato
    return [...arrayOfObjects].sort( ( a, b ) => compareAllProperties( a, b, arrayOfProperties, arrayOfSortingType ) );
    
};

export function SimpleTable({
    chiave, className,
    sTableDataType, aoRows = [], oRowOptions, aoCols, extraClasses, noHeight, noHeader, noFilter,  bOnlySelected,
    isCheckedFunction = ()=>{}, fCheckAll, fCheckOne, oCheckOptions = {}, // oCheckOptions = { isEnabled: true, isVisible: true }
    isHighlightable, isHighlightedFunction = ()=>{}, fHighlightOnlyOne,
    isEditable, editFunction,
    oHeadersOptions = {},
    exception = val=>val,
    fGetRecord,
    oSortOptions = { isSortable: false, isMultiColSortable: false, oSort: null },
    oPaginationOption = { isPagination: false, oPag: {} },  // nTotRecord: null, nRecordPerPage: null, nFirstRecordPage: null, set_nFirstRecordPage: null }, // functionToExecute === fSort
    oExportOption ,
    offSetHeight = 0,
    randomizeOnlyNumbersForDemo = false
}) {
    // l'abilitazione delle checkbox è possibile solo se gli elementi nelle righe hanno la proprietà "FLAG_SELECTED" ( 'Y' oppure 'N' )

    const isEmpty          = aoRows.length === 0;
    aoCols = aoCols.filter(Boolean);
    // se non c'è la key, uso name come key
    for ( let n = 0; n < aoCols.length; n++ ) {
        let o = aoCols[n];
        if ( !o.key ) {
            o.key = o.name + '' + n;
        }
    }
    const asKeyColumnNames = ( aoCols.filter( oCol => oCol.isUniqueKeyForRow ) || {} ).map( oCol => oCol.name );
    const getUniqueKey     = ( oRow, asKeyColumnNames ) => {
        return asKeyColumnNames.reduce(
            ( sUniqueKey, sKeyColumnName ) => sUniqueKey + ( oRow[ sKeyColumnName ] || '' ),
            ''
        );
    };
    const bFiltersLimitReached = false; // ( aoRows.filter( isCheckedFunction ).length >= ( config.FILTERS_LIMIT || 50 ) );

    const { sFileName = 'export' , isExportable = true } = oExportOption || {};

    const
        [ oColumnFilters     ,set_oColumnFilters     ] = useState({})           // per ogni colonna mi segno il valore da filtrare
       ,[ aoFilteredRows     ,set_aoFilteredRows     ] = useState([...aoRows])  // array interno delle sole righe filtrate
       ,[ height             ,set_height             ] = useState(0)            // mantiene fissa l'altezza della finestra dopo il primo caricamento dei dati
       ,[ headGroupRowHeight ,set_headGroupRowHeight ] = useState(0)            // mantiene fissa l'altezza della riga dopo il primo caricamento dei dati
       ,[ headersRowHeight   ,set_headersRowHeight   ] = useState(0)            // mantiene fissa l'altezza della riga dopo il primo caricamento dei dati
       ,[ bSelectAll         ,set_bSelectAll         ] = useState(null)         // indica se devono essere selezionati tutti gli elementi
        // ATTENZIONE: importante che bSelectAll sia inizializzato a null e poi nella useEffect si verifichi che sia boolean
       ,[ coordHover         ,set_coordHover         ] = useState([])
       ,[ isDisabledExport   ,set_isDisabledExport   ] = useState(false)
    ;

    const paperContainer = useRef(null);
    const headGroupRow   = useRef(null);
    const headersRow     = useRef(null);
    const getValidTitle  = oCol => typeof(oCol.title) === 'string' ? oCol.title : oCol.headerTooltip;

    const exportCSV = () => {
        set_isDisabledExport(true);
        const aasDataRows = [
              aoCols.filter( oCol => oCol && !oCol.noExport ).map( getValidTitle )
            , ...aoFilteredRows.map( oRow => 
                aoCols
                    .filter( oCol => oCol && !oCol.noExport )
                    .map( ( oCol, nCol ) => {
                        const 
                            originalValue  = oRow[ oCol.name ]
                           ,valueToDisplay = oCol?.formatExcel ? oCol.formatExcel(originalValue) 
                                                : (['string','number'].includes( typeof originalValue )) ? originalValue : ''
                        // ,formatValue    = oCol.format ? oCol.format( valueToDisplay, oRow ) : valueToDisplay
                        ;
                        return (
                            ( typeof valueToDisplay === 'string' )
                               ? ( '"' + valueToDisplay  + '"' )
                               : utils.fixDecimals(valueToDisplay, 2)
                        );
                    })
            )
        ];
        utils.fileSaver( aasDataRows, sFileName + ` ( ${ moment().format('YYYY-MM-DD HH_mm_ss') } )` );
        setTimeout( set_isDisabledExport, 2000, false);
    };

    // useEffect(() => {
    //     console.error('Simple Table')
    //     // sortTable(( aoCols.filter( oCol => oCol.isUniqueKeyForRow ) ));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        if ( !noHeight ) {
            set_height(paperContainer?.current?.clientHeight + offSetHeight);
        }
        set_headGroupRowHeight( headGroupRow?.current?.clientHeight );
        set_headersRowHeight(   headersRow?.current?.clientHeight );
        // if ( !aoRows.length ) { console.error('Nessun dato da visualizzare') }
        
        // questa riga non va bene perché può esserci più di una colonna isUniqueKeyForRow
        // e sortTable prevede una sola colonna come parametro
        // sortTable(aoCols.find( oCol => oCol.isUniqueKeyForRow ) );
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        set_aoFilteredRows( bOnlySelected ? [...aoRows].filter( isCheckedFunction ) : [...aoRows] );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bOnlySelected]);

    const aoRowsUpdatedWithAllFilters = () => {
        
        let aoRowsUpdated = [...aoRows];
        
        // applico ogni filtro inserito (compreso l'ultimo appena aggiunto)
        const asFilterKeys = Object.keys( oColumnFilters );
        for ( let nFilterKey = 0; nFilterKey < asFilterKeys.length; nFilterKey++ ) { // per ogni valore filtrato
            
            const sColumnFilterName = asFilterKeys[nFilterKey];
            
            let sValueFiltered = '', oValueFiltered;
            const vValueFiltered        = oColumnFilters[sColumnFilterName];
            const isValuefilteredObject = ( typeof(vValueFiltered) === 'object' );
            
            if ( isValuefilteredObject ) {
                oValueFiltered = vValueFiltered;
            } else {
                sValueFiltered = vValueFiltered;
            }
            
            if ( isValuefilteredObject ) {
                
                const asColKeys = Object.keys( oValueFiltered );
                for ( let nColKey = 0; nColKey < asColKeys.length; nColKey++ ) {
                    
                    const sColKey           = asColKeys[nColKey];
                    
                    sValueFiltered          = oValueFiltered[ sColKey ];
                    
                    const oColFound         = ( aoCols.find( oCol => !oCol.noFilter && ( oCol.name === sColumnFilterName ) && ( ( oCol.key + '' ) === ( sColKey + '' ) ) ) || {} );
                    const selectOptions     = oColFound.selectOptions;
                    const formatFunction    = ( oColFound.format && !oColFound.filterOriginalValue ) ? oColFound.format : ( val => val );
                    
                    aoRowsUpdated  = aoRowsUpdated.filter( oRow => {
                        if  ( !!selectOptions ) {
                            const oActualOption = selectOptions.find( oSelectOption => ( oSelectOption.value + '' ) === ( sValueFiltered + '' ) );
                            return oActualOption?.checkFunc( oRow[sColumnFilterName] || '', isValuefilteredObject ? sColKey : sValueFiltered );
                        } else {
                            return removeSpecialChars( formatFunction(oRow[sColumnFilterName], oRow ) ).includes( removeSpecialChars(sValueFiltered) );
                        }
                    });
                    
                }
                
            } else {
                
                const oColFound         = ( aoCols.find( oCol => !oCol.noFilter && ( oCol.name === sColumnFilterName ) ) || {} );
                const selectOptions     = oColFound.selectOptions;
                const formatFunction    = ( oColFound.format && !oColFound.filterOriginalValue ) ? oColFound.format : ( val => val );
                
                aoRowsUpdated = aoRowsUpdated.filter( oRow => {
                    
                    if  ( !!selectOptions ) {
                        const oActualOption = selectOptions.find( oSelectOption => ( oSelectOption.value + '' ) === ( sValueFiltered + '' ) );
                        return oActualOption?.checkFunc( oRow[sColumnFilterName] || '', sValueFiltered );
                    } else {
                        return removeSpecialChars( formatFunction(oRow[sColumnFilterName], oRow ) ).includes( removeSpecialChars(sValueFiltered) );
                    }
                });
                
            }
            
        }

        return aoRowsUpdated;

    };

    const handleFilterValues = ({ sValueToFilter, sColumnName, key }) => {

        // aggiungo l'attuale valore filtrato alla colonna interessata
        if ( key ) {
            if ( !oColumnFilters[ sColumnName ] ) {
                oColumnFilters[ sColumnName ] = {};
            }
            oColumnFilters[ sColumnName ][ key ] = sValueToFilter;
        } else {
            oColumnFilters[ sColumnName ] = sValueToFilter;
        }

        const aoRowsUpdated = aoRowsUpdatedWithAllFilters();

        // aggiorno righe e colonne
        set_oColumnFilters( oColumnFilters );
        set_aoFilteredRows( aoRowsUpdated );

    }

    const onChangeSelectAll         = (event) => {
        set_bSelectAll(!!event.target.checked);
    }

    useEffect( ()=>{
        // ATTENZIONE: importante che bSelectAll sia inizializzato a null e poi nella useEffect si verifichi che sia boolean
        if ( oCheckOptions.isVisible && ( typeof bSelectAll === 'boolean' ) ) {
            fCheckAll(bSelectAll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bSelectAll]);

    useEffect( ()=>{
        if ( aoRows.every( oRow => oRow.FLAG_SELECTED === 'Y' ) ) {
            set_bSelectAll(true);
        }
        set_aoFilteredRows(aoRowsUpdatedWithAllFilters() );
        // fix per aggiornare lo stato della tabella quando aoRows (una prop) viene aggiornata dal padre
        // ad ogni modifica di aoRows risetto lo stato associato "aoFilteredRows" tenendo conto dei filtri già applicati
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ aoRows ]);

    const isHeadersGrouped = aoCols.some( oCol => oCol.group );
    
    const aoColsGroups     = aoCols.reduce( ( aoColsGroups, oCol ) => {
        let oGroupToFind = aoColsGroups.find( o => o.group === oCol.group );
        if ( !oCol.group ) { // se non fa parte di un gruppo creo un oggetto con nColSpan = 1
            aoColsGroups.push({ ...oCol, nColSpan: 1 });
        } else if ( oCol.group && !(oGroupToFind) ) { // se non esiste ancora l'oggetto gruppo, lo crea e gli dà valore 1
            aoColsGroups.push({ ...oCol, nColSpan: 1 }); 
        } else { // altrimenti se esiste già aumenta il suo valore di 1
            oGroupToFind.nColSpan += 1; 
        }
        return aoColsGroups
    } ,[]);


    /*
    const handleCheck = ( { isChecked, oRow } ) => {
        handleChangeCheck({ isChecked, oRow });
        setAoRows( [...aoRows] );
    };

    const  handleHighlight = ( { isHighlighted, oRow } ) => {
        aoRows.map( row => handleChangeHighlight({ isHighlighted: false, oRow: row }) ); // toglie evidenziazione da tutte le righe
        handleChangeHighlight({ isHighlighted: !isHighlighted, oRow }); // evidenzia solo la riga interessata
        setAoRows( [...aoRows] );
    };
    */
    
    const fMapSameValues = () => {
        
        let oPrevRow        = {};
        let aMappedSameRow  = [];
        const aoSortCols    = ( aoCols.filter( oCol => oCol.sort ) || {} );
        
        const aoSortedRows  = fGetRecord ? [ ...aoFilteredRows ] : sortBy(
             aoFilteredRows 
            ,aoSortCols.map( oSortcol => oSortcol.name ) 
            ,aoSortCols.map( oSortcol => oSortcol.sort )
        );
        
        for ( let nRow = 0; nRow < aoSortedRows.length; nRow++ ) {
            const oRow = aoSortedRows[nRow];
            const isChecked = isCheckedFunction( oRow );
            aMappedSameRow.push( 
                <tr
                    key       ={ 'tr' + getUniqueKey(    oRow, asKeyColumnNames ) }
                    className ={ ( isHighlightable && isHighlightedFunction( oRow ) ? ' highlight ' : '' )
                            + ( oRowOptions && oRowOptions.addClass ? oRowOptions.addClass(oRow) : '' ) }
                    onClick   ={ () => { isHighlightable && fHighlightOnlyOne( oRow ) } }
                >
                    {   // colonna per CHECKBOX
                        oCheckOptions.isVisible &&
                        <td key="checkcolumn" className="check">
                            <Checkbox // disabilita checkbox se si supera il massimo di elementi selezionabili
                                disabled ={ !oCheckOptions.isEnabled && !isChecked && bFiltersLimitReached }
                                checked  ={ isCheckedFunction(oRow) || isChecked }
                                onClick  ={ (event) => { event.stopPropagation() } }
                                onChange ={ (event) => fCheckOne( { isChecked: event.target.checked, oRow } ) }
                                color    ="primary"
                            />
                        </td>
                    }
                    {   // colonna per EDIT
                        isEditable && <td className="edit" onClick={ () => { editFunction(oRow) } } >
                            <Tooltip title="Edit" placement='right' arrow>
                                <EditIcon />
                            </Tooltip>
                        </td>
                    }
                    {   // corpo della tabella (i dati)

                        // eslint-disable-next-line no-loop-func
                        aoCols.map( (oCol, nCol) => {
                            const
                                 originalValue  = !isDemo ? oRow[ oCol.name ] : ( 
                                     oCol.isNum ? ~~( ( +oRow[ oCol.name ] + numeroCasualeDecimale(+oRow[ oCol.name ]) ) * numeroCasualeDecimale(+oRow[ oCol.name ]) ) 
                                                : ( randomizeOnlyNumbersForDemo ? oRow[ oCol.name ] : ( oCol.name + nRow ) )
                                 )
                                ,isSame         = oCol.showDiff && ( originalValue === oPrevRow[ oCol.name ] ) ? ' isSame ' : ''
                                ,valueToDisplay = ['string','number'].includes( typeof originalValue ) ? originalValue : ''
                                ,formatValue    = ( typeof oCol?.format === 'function' ) ? oCol?.format( valueToDisplay, oRow ) : null
                                ,formatOrOriginal = oCol.format ? formatValue : valueToDisplay
                                ,optionalProps  = !oCol.onHover ? {} : {
                                    onMouseEnter:() => { timeoutIdOnHover = setTimeout( () => { set_coordHover([nRow, nCol]) }, 250 ) },
                                    onMouseLeave:() => { clearTimeout(timeoutIdOnHover);  set_coordHover([]) }
                                }
                                ,cellContent    = ( oCol.onHover && ( coordHover[0] === nRow ) && ( coordHover[1] === nCol ) )
                                                    ? oCol.onHover( formatOrOriginal, oRow.KUSER, oCol.key )
                                                    : exception( formatOrOriginal, oRow, oCol, oRow.asCurrentFilters )
                                ,uniqueClass    = oCol.isUniqueKeyForRow ? ' unique ' : ''
                                ,numberClass    = oCol.isNum             ? ' number ' : ''
                            ;

                            return <td
                                key       = { 'td' + oCol.name + getValidTitle(oCol) + nCol  }
                                className = {
                                      oCol.name
                                    + uniqueClass
                                    + numberClass
                                    + ' ' + (   oCol.group               ? ( 'ingroup ' + oCol.group )  : '' )
                                    + ' ' + ( ( oCol.group && oCol.key && oCol.format && ['string','number'].includes( typeof formatValue ) ) ? formatValue : '' )
                                    + ' ' + ( ( oCol.additionalClass || ((v)=>'') )(originalValue) )
                                    + isSame
                                }
                                style     = { { minWidth: oCol.width || '', width: oCol.width || '', maxWidth: oCol.width || '' } }
                                title     = { !oCol.tooltip
                                                    ? ''
                                                    : ['string','number'].includes( typeof formatValue ) 
                                                        ? formatValue 
                                                        : valueToDisplay }
                                { ...optionalProps }
                            >{
                                cellContent
                            }</td>
                        })

                    }
                </tr>
            )
            
            oPrevRow = oRow;
            
        }

        return aMappedSameRow;
    };
    
    const sortTable = (oCol) => {

        // se non è stata abilitata la possibilità di selezionare più di una colonna per l'ordinamento
        // allora, appena si ordina per una colonna, viene eliminato l'ordinamento per tutte le altre
        if ( !oSortOptions.isMultiColSortable ) {
            for ( let i = 0; i < aoCols.length; i++ ) {
                if ( aoCols[i].name !== oCol.name ) {
                    delete aoCols[i].sort;
                }
            }
        }
        
        if ( oSortOptions.isSortable ) {
            if ( fGetRecord ) { 
                oCol.sort = ( oSortOptions?.oSort?.sort !== 'DESC' ) || ( oSortOptions?.oSort?.name !== oCol.name ) ? 'DESC' : 'ASC';
                (async () => {
                    await fGetRecord({ oCol: { ...oCol } });
                    // avendo aggiornato una colonna, è sufficiente riscatenare l'aggiornamento di uno stato
                    set_aoFilteredRows([...aoFilteredRows]);
                })();
            } else {
                oCol.sort = ( oCol.sort !== 'DESC' ) ? 'DESC' : 'ASC';
                // avendo aggiornato una colonna, è sufficiente riscatenare l'aggiornamento di uno stato
                set_aoFilteredRows([ ...sortBy( aoFilteredRows, [oCol.name], [oCol.sort] ) ]);
            }
        }
        
    }
    
    if ( !Object.keys(aoRows).length || !Object.keys(aoCols).length ) {
        return <></>;
    }

    const tableTool = <span className='headerTableTool'>
        { isExportable && <Tooltip title='Download CSV' placement='right' arrow>
            <Button 
                className ='side-simpletable-menu-opener myShadow'
                onClick   ={ exportCSV }
                disabled  ={ isDisabledExport }
            >
                <DownLoadIcon className={'bigIcon textGrey'} />
            </Button>
        </Tooltip> }
        { oPaginationOption.isPagination && ( oPaginationOption.oPag.nTotRecord > oPaginationOption.oPag.pNumRecords ) &&
            <PaginationTool oPaginationOption = {{ fGetRecord, oPag: oPaginationOption.oPag }}/> }
    </span>;

    
    return <Paper
        key       ={chiave}
        className ={
            'SimpleTable-wrapper '
            + ( isEmpty ? ' no-data ' : ' ' )
            + ( extraClasses || ' ' )
            + ' ' + sTableDataType
            + ' ' + className
        }
        ref       ={ paperContainer }
        style     ={ height ? { height } : {} }
    >
        { tableTool }
    {/* <Tooltip title='Menu' placement='top' arrow><div className="dianButton side-simpletable-menu-opener link myShadow" onClick={ openMenuTable }><ThreeVerticalDot /></div></Tooltip> !!! TODO
        <Tooltip title='Download' placement='bottom' arrow><div className={`side-simpletable-menu link myShadow ${ isMenuOpen ? '' : 'hiddenElement' }`} onClick={ exportCSV }><DownLoadIcon /></div></Tooltip> */}
        <div className={ 'SimpleTable ' + chiave } key={chiave}>
            <table>
                <thead>
                {   // INTESTAZIONI dei GRUPPI di colonne
                    ( noHeader || !isHeadersGrouped ) ? null :
                    <tr className='cols-groups'>
                        { oCheckOptions.isVisible && <th key="checkcolumn" className="check" ><div></div></th> }
                        { isEditable              && <th key="editcolumn"  className="edit"  ><div></div></th> }
                        { aoColsGroups.map( ( oColGroup, nColGroup ) => {
                            // const nColSpan = aoColsGroups[oColGroup].nColSpan;
                            const hasColSpan = oColGroup.nColSpan > 1;
                            return <th
                                ref       ={ ( nColGroup === 0 ) ? headGroupRow : null }
                                key       ={ 'group' + oColGroup.group + oColGroup.name + nColGroup }
                                className ={
                                    (
                                        hasColSpan
                                            ? 'colgroup '
                                            : ( 'nocolgroup ' + ( ( oColGroup.additionalClass || ((v)=>'') )() || '' ) )
                                    )
                                    + ' ' + ( oColGroup.group || '' )
                                    + ' ' + oColGroup.name
                                }
                                { ...( hasColSpan && { colSpan: oColGroup.nColSpan }) }
                                { ...( hasColSpan && { title:  ( oHeadersOptions.tooltip && oColGroup.group ) ? oColGroup.group : '' }) }
                            >{ hasColSpan ? oColGroup.group : '' }</th>
                        }) }
                    </tr>
                }
                {   // INTESTAZIONI delle COLONNE
                    noHeader ? null :
                    <tr>
                        { oCheckOptions.isVisible && <th key="checkcolumn" className="check" style={ { top: headGroupRowHeight || 0 } } ><div></div></th> }
                        { isEditable              && <th key="editcolumn"  className="edit"  style={ { top: headGroupRowHeight || 0 } } ><div></div></th> }
                        { aoCols.map( ( oCol, nCol ) => {
                            const nColWidth = ( oCol.width + +( oSortOptions.isSortable && !oCol.notSortable && 14 ) ) || '';
                            return <th
                                ref       ={ nCol === 0 ? headersRow : null }
                                key       ={ 'headers' + oCol.name + oCol.key + nCol }
                                onClick   ={ ( typeof oCol?.onClickColHead === 'function' ) ? oCol.onClickColHead : (() => {}) }
                                className ={
                                     oCol.name
                                     + ' ' + ( oCol.group                ? ( 'ingroup ' + oCol.group )  : '' )
                                     + ' ' + ( oCol.isUniqueKeyForRow    ? 'unique'                     : '' )
                                     + ' ' + ( oCol.isNum                ? 'number'                     : '' )
                                     + ' ' + (  ( oCol.additionalClass || ((v)=>'') )() )
                                     + ' ' + ( oHeadersOptions.breakline ? 'breakline'                  : '' )
                                }
                                style     ={ { minWidth: nColWidth, width: nColWidth, maxWidth: nColWidth, top: headGroupRowHeight || 0 } } 
                                title     ={ ( oHeadersOptions.tooltip && ( oCol.headerTooltip || getValidTitle(oCol) ) ) || '' }
                            >{
                                oSortOptions.isSortable && !oCol.notSortable && aoRows.length > 1 &&
                                <div
                                    className={
                                        'arrows-icon updown ' + (
                                            ( 
                                                ( !fGetRecord && oCol.sort ) || 
                                                ( ( oSortOptions?.oSort?.name === oCol.name ) && oSortOptions?.oSort?.sort )
                                            ) || '' 
                                        )
                                    }
                                     onClick={ () => { sortTable(oCol); } }></div>
                            }{ oCol.title }</th>
                        } ) }
                    </tr>
                }
                {   // riga dei FILTRI
                    (noFilter || (aoRows.length <= 1)) ? null :
                    <tr className="filter">
                        { oCheckOptions.isVisible &&
                            <th key="checkcolumn" className="check" /*style={ { top: ( headGroupRowHeight || 0 ) + ( headersRowHeight || 0 )} }*/>
                                <Tooltip title={ bSelectAll ? 'Deselect all' : 'Select all' } placement='top' arrow>
                                    <Checkbox className="check-sel-all" checked={ !!bSelectAll } onChange={ onChangeSelectAll } />
                                </Tooltip>
                            </th>
                        }
                        { isEditable && <th key="editcolumn" className="edit" style={ { top: ( headGroupRowHeight || 0 ) + ( headersRowHeight || 0 )} }/> }
                        { // filtri sulle righe
                            aoCols.map( ( oCol, nCol ) => {
                                let sValueToFilter = oColumnFilters[ oCol.name ] || '';
                                if ( sValueToFilter && ![ 'string', 'number' ].includes( typeof sValueToFilter ) ) {
                                    sValueToFilter = sValueToFilter[ oCol.key ];
                                }
                                return <th
                                    style     ={ { top: ( headGroupRowHeight || 0 ) + ( headersRowHeight || 0 )} }
                                    key       ={ 'filters' + oCol.name + nCol }
                                    className ={
                                        oCol.name
                                        + ' ' + ( oCol.group                ? ( 'ingroup ' + oCol.group )  : '' )
                                        + ' ' + ( oCol.isUniqueKeyForRow    ? 'unique'                     : '' )
                                        + ' ' + ( oCol.isNum                ? 'number'                     : '' )
                                        + ' ' + (  ( oCol.additionalClass || ((v)=>'') )() )
                                    }
                                > {
                                    oCol.noFilter ? '' :
                                    ( !!oCol.selectOptions )
                                        ? // filtro in versione select con opzioni
                                            <Select
                                                value       ={ sValueToFilter || '' }
                                                variant     = 'standard'
                                                onChange    ={ (event) => {
                                                    handleFilterValues({
                                                         sValueToFilter:     event.target.value
                                                        ,sColumnName:        oCol.name
                                                        ,formatFunction:     oCol.format
                                                        ,selectOptions:      oCol.selectOptions
                                                        ,key:                oCol.key
                                                    })
                                                }}
                                                style={{ minWidth: 48 }}
                                            >{
                                                ( oCol?.selectOptions || [] ).map( (oSelectItem, index) =>
                                                    <MenuItem
                                                        key   ={ 'trioptions' + oSelectItem.value + index } 
                                                        value ={ oSelectItem.value }
                                                    >{ oSelectItem.label }</MenuItem>
                                                )
                                            }</Select>
                                        : // filtro versione con input libero da parte dell'utente digitando il testo
                                            <TextField
                                                type        ="text"
                                                variant     ="standard"
                                                value       ={ sValueToFilter }
                                                onChange    ={ (event) => {
                                                    handleFilterValues({
                                                         sValueToFilter:    event.target.value
                                                        ,sColumnName:       oCol.name
                                                        ,formatFunction:    oCol.format
                                                    })
                                                }}
                                                placeholder ="Filter..."
                                                fullWidth
                                                inputProps  ={{ spellCheck: "false" }}
                                            />
                                    /* InputProps={{ endAdornment: ( <InputAdornment position="end"> <span>Cc</span> <span>W</span> <span>*</span> </InputAdornment> ) }} */
                                } </th>
                            })
                        }
                    </tr>
                }
                </thead>
                <tbody>
                {   // CORPO della tabella
                    fMapSameValues()
                }
                </tbody>
                {/* {
                    oPaginationOption.isPagination && ( oPaginationOption.oPag.nTotRecord > oPaginationOption.oPag.pNumRecords ) && 
                    <tfoot><tr><th className='myFoot' colSpan={100}>
                        { tableTool }
                    </th></tr></tfoot>
                } */}
            </table>
        </div>
        { oPaginationOption.isPagination && ( oPaginationOption.oPag.nTotRecord > oPaginationOption.oPag.pNumRecords ) && tableTool } 
        { /* Ripetizione di condizione per i casi di tabella con più di una pagina */}
    </Paper>

}
/* --- esempio di utilizzo ---
    tableContainer = ({
                        key, sTableType, sTableDataType, aoColumns, aoRows, setAoRows, bChecksVisible, bOnlySelectedEnabled,
                        exception, noHeader, noFilter, isEditable, editFunction
                     }) => {

        return !( aoRows && aoRows.length ) ? null : (
            <SimpleTable

                chiave                ={ key }
                sTableDataType        ={ sTableDataType }
                aoRows                ={ aoRows    }
                aoCols                ={ aoColumns }

                noHeight              ={ true }
                noHeader              ={ noHeader }
                noFilter              ={ noFilter }
                bOnlySelected         ={ bOnlySelectedEnabled }

                oCheckOptions         ={ { isEnabled: true, isVisible: ( sTableType === 'checkable' ) && bChecksVisible } }
                isCheckedFunction     ={ isCheckedFunction }
                fCheckAll             ={ bCheckAll => fCheckAll( { bCheckAll, aoRows, setAoRows } ) }
                fCheckOne             ={ ({ isChecked, oRow }) => fCheckOne( { isChecked, oRow, aoRows, setAoRows } ) }

                isEditable            ={ !bChecksVisible && isEditable }
                editFunction          ={ isEditable && editFunction ? editFunction : () => {} }

                exception             ={ exception }

            />
        )
    }

    tableContainer({
         key:                    'users'
        ,sTableDataType:         'USERS'
        ,sTableType:             'checkable'
        ,aoColumns:              [...aoUsersColumns]
        ,aoRows:                 [...aoUsers]
        ,setAoRows:              set_aoUsers
        ,bChecksVisible:         bUsersChecks
        ,bOnlySelectedEnabled:   false
        ,isEditable:             true
        ,editFunction:           onClickEdit
    })

*/
