// Rotta non Renderizzata
import React, {useEffect, useState} from 'react';
import './UserDetail.css';
import {useNavigate, useParams} from 'react-router-dom'
import config from '../../config';
import axios from 'axios';

const bDebug = config.DEBUG && false;

const UserDetail = (props) => {
  
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const urlParams = useParams();

  useEffect(() => {
    //il nome e cognome saranno popolati con il risultato della futura getUser
    //load pagina -> getUser -> cambio testo nell'header con il risultato della get -> fine load pagina
    
    async function init(){
      let url = `${config.API_URL}/users`;
      let params = {
        cognitoSub: urlParams.id
      };
      let response = '';

      try{
        response = await axios.get(url,{
          headers: {
              'Accept': 'application/json',
          },params  
        });
 
        if(response.status === 200){
          bDebug && console.log(response.data[0])
          setUser(response.data[0]);
        }
      }catch(error){
        console.error('errore al loading', error);
      }
      // props.handleNamePageChange(`User: ${response.data[0].FULL_NAME}`);
    };
    init();
  },[]);

  const goToPreviousPage = () => {
    navigate(-1);
  }

  return (
    <div className='user-details-container'>
      <div className='boxes-container'>
        <div className='box'>
          <div className='box-title'>User Details</div>
          <div className='box-body'>
            <div>Name: {user.NOME}</div>
            <div>Surname: {user.FULL_NAME}</div>
            <div>Email: {user.EMAIL_ADDRESS}</div>
          </div>
        </div>
        <div className='box'>
          <div className='box-title'>History</div>
          <div className='box-body'>
            <div>Created on: {user.DATA_INSERIMENTO || 'N/A'}</div>
            <div>Updated on: {user.DATA_ULTIMA_MODIFICA || 'N/A'}</div>
            <div>Last login on: {user.DATA_ULTIMA_LOGIN || 'N/A'}</div>
          </div>
        </div>
        <div className='box'>
          <div className='box-title'>User Privileges</div>
        </div>
        <div className='box'>
          <div className='box-title'>User Roles</div>
          <div className='box-body'>
            <div>Admin: {user.FLAG_ADMIN}</div>
          </div>
        </div>
      </div>
      <button type="button" className="text-uppercase login-btn back" onClick={goToPreviousPage}>
        Back
      </button>
    </div>
  )
}

export default UserDetail
