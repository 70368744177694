import React        from "react";
import config       from '../../config.js';
const bDebug = config.DEBUG && false;

const Home = (props) => {

    return <div className="component-container dashboard">
        <div className="component-card"/>            
    </div>;
};

export default Home;