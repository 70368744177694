import React, { useState, useEffect, useContext } from 'react'; 
import { Button 
       , CircularProgress
       , Dialog       
       , DialogActions
       , DialogContent
       , DialogTitle }                from '@mui/material';

import axios                          from "axios";
import moment                         from 'moment';
import 'moment/locale/it';

import './Adjustment.css';
import '../../components/PopupConfirm/PopupConfirm.css';
import { AppContext }                 from '../../App.js';
import { AuthContext }                from '../../contexts/authContext';
import { CustomCssTextField }         from "../../components/CustomCSS/CustomCSS.tsx";
import config                         from '../../config';
// import utils                          from '../../util/CommonUtilities';

const bDebug = config.DEBUG && false;

const regexOnlyNum       = /^[0-9]*$/;
const regexForPercentage = /^[-]?[0-9]*$/;
// const aoValuesType       = [{ value: '%'}, { value: '#' } ];
const linkGam            = 'https://developers.google.com/ad-manager/api/reference/v202305/AdjustmentService#errors';

function AdjustmentEdit({ oPopupOptions, oPopupSettings }) { 

    bDebug && console.log('oPopupOptions: ', oPopupOptions)
    bDebug && console.log('oPopupSettings: ', oPopupSettings)
    const auth = useContext(AuthContext);
    const { set_oNotifyOptions } = useContext(AppContext);
    const headers = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };
    const
         { sPopupType, set_sPopupType, setLoading, onReload, set_onReload } = oPopupSettings
        ,{ oAdUnitToAdjust, oPlacementToAdjust, dDataInizio, dDataFine, sAdjType } = oPopupOptions
    ;

    const
         [ loadingDB        ,setLoadingDB ] = useState(false)
        ,[ sEtichettaAdj    ,set_sEtichettaAdj      ] = useState('')   // State per contenere l'etichetta dell'Adjustment che si vuole inserire
        ,[ nValueAdjust     ,set_nValueAdjust       ] = useState('')
        ,[ nValueAdjustPerc ,set_nValueAdjustPerc   ] = useState('')
        ,[ sBacinoAdj       ,set_sbacinoAdj         ] = useState('')
    ;

    /* #region GetFunction */
        const getBacinoAdj = async () => {
            bDebug && console.log('GetBacinoAdj');
            setLoadingDB(true);
            set_oNotifyOptions({ message: '' });
            set_sbacinoAdj('');
            
            try {
                let params = ( sAdjType === 'ad unit' ) ? 
                             { pAdUnitId:   oAdUnitToAdjust?.AD_UNIT_ID
                             , getBacinoTotaleForecastParam: true
                             , pDataInizio: dDataInizio.format('YYYYMMDD')
                             , pDataFine:   dDataFine.format('YYYYMMDD') }
                           : { pPlacementId: oPlacementToAdjust?.PLACEMENT_ID
                             , getBacinoTotaleForecastParam: true
                             , pDataInizio: dDataInizio.format('YYYYMMDD')
                             , pDataFine:   dDataFine.format('YYYYMMDD') }
                const response = await axios.get( config.API_URL + '/get', { headers, params } );
                const resValid = ( response && ( response.status === 200 ) && response?.data );

                if ( resValid ) {
                    console.log( resValid );
                    set_sbacinoAdj( resValid.aoRecords[0]?.BACINO );
                } else {
                    console.error('ERR 157.1: Failed getBacinoAdj');
                    set_oNotifyOptions({ message: `Impossibile recuperare i dati da database`, severity:'error' })
                }
            } catch ( err ) {
                console.error( 'Error Message: ', err?.message );
                console.error('ERR 157: Failed getBacinoAdj', err);
                set_oNotifyOptions({ message: `Impossibile recuperare i dati da database`, severity:'error' });
            }
            
            setLoadingDB(false);
        }   
    /* #endregion GetFunction */    
    
    /* #region AddAdjust */

        // Function per Inserire l'ADJUSTMENT
        const fAddAdjust = async () => {
            bDebug && console.log('FAddAdjust');
            setLoading(true);
            set_oNotifyOptions({ message: '' });

            try {
                let params = ( sAdjType === 'ad unit' ) ? 
                             { pAdUnitId:   oAdUnitToAdjust?.AD_UNIT_ID
                             , GAMaccount: 'ilsole24ore'
                             , pDataInizio: dDataInizio.format('YYYYMMDD')
                             , pDataFine:   dDataFine.format('YYYYMMDD')
                             , pAdjVal:     nValueAdjust
                             , pAdjName:    sEtichettaAdj }
                           : { pPlacementId: oPlacementToAdjust?.PLACEMENT_ID
                             , GAMaccount: 'ilsole24ore'
                             , pDataInizio: dDataInizio.format('YYYYMMDD')
                             , pDataFine:   dDataFine.format('YYYYMMDD')
                             , pAdjVal:     nValueAdjust
                             , pAdjName:    sEtichettaAdj }

                const response = await axios.get( config.API_URL + '/gam-adjust', { headers, params } );
                const resValid = ( response && ( response.status === 200 ) && response?.data );

                console.log(Object.keys( response ))
                console.log( response )

                if ( !resValid ) {
                    console.error('ERR 155.1: Failed AddAdjust');
                    set_oNotifyOptions({ message: `Impossibile salvare le modifiche su GAM`, severity:'error' });
                    
                } else if ( resValid?.error ) { 
                    console.error('ERR 155.2: Failed AddAdjust');
                    console.log( resValid?.error )
                    set_oNotifyOptions({ message: <span className='flexySnackAlert'><span>Errore Google Ad Manager:</span><span>{ resValid?.error?.errBody.split('reason')[1].split('"')[2] }</span><a className='myExternalLinkwhite' href={ linkGam } target='blank'>Visualizza dettaglio errore</a></span> , severity:'error' });

                } else if ( resValid ) {
                    set_oNotifyOptions({ message: 'Adjustment inserita con successo', severity: 'success', timeout: 4 });

                }
            } catch ( err ) {   
                console.error( 'Error Message: ', err?.message );
                console.error('ERR 155: Failed ConnectToGam AddAdjust', err);
                set_oNotifyOptions({ message: `Impossibile connettersi a GAM`, severity:'error' });
            }
            closePopup();
            set_onReload(!onReload);
        };

    /* #endregion */

    /* #region Handler */
        // Handler per controllare che nel campo ValueAdjust vengano inseriti solamente valori numerici senza segno
        const handleChangeValueAdjust = ( value, label ) => {    
            bDebug && console.log(regexOnlyNum.test(value));
            if (  label === '#' && (( regexOnlyNum.test(value) && value <= 99999999 ) || value === '' )) { // !!! TODO inserire controllo sValueType
                set_nValueAdjust(~~value);
                sBacinoAdj && set_nValueAdjustPerc(~~(( +value ) * 100 / ( +sBacinoAdj )));
            } else if ( label === '%' && sBacinoAdj && ( regexForPercentage.test(value)  || value === '' ) && ((( +sBacinoAdj ) * ( +value ) / 100) <= 99999999 )) {
                set_nValueAdjustPerc(~~(value));
                set_nValueAdjust(~~(( +sBacinoAdj ) * ( +value ) / 100));
            }
        };

        const myKeyPress = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation() 
                fAddAdjust();
            }
        };
    /* #endregion Handler */

    /* #region UseEffect */
        // UseEffect per scatenare la richiesta di Bacino all'apertura del Popup
        useEffect(() => {
            bDebug && console.log('UseEffect sPopupType: ', sPopupType)
            sPopupType === 'AddAdj' && getBacinoAdj();
            bDebug && console.log('oPlacementToAdjust: ', oPlacementToAdjust)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ sPopupType ]);
    /* #endregion UseEffect */

    /* #region UtilsFunction */
        const closePopup = () => {
            bDebug && console.log('I`m closing')
            // oPopupOptions.hasOwnProperty('onCancel') && oPopupOptions.onCancel()
            handleClearAdjParam()
            set_sPopupType(''); 
        };
        
        const handleClearAdjParam = () => {
            set_nValueAdjust('');
            set_nValueAdjustPerc('');
            set_sEtichettaAdj('');
        };
    /* #endregion UtilsFunction */

    return <Dialog open={!!( sPopupType === 'AddAdj' )} onClose={ closePopup } className='dialogWrapper' tabIndex={0} onKeyDown={ (e) => myKeyPress(e) }>
        <DialogTitle className='popupTitle'>Confermi di voler inserire il seguente Adjustment</DialogTitle>
        <DialogContent className='popupWrapperContentAdjustmentEdit'>
            { loadingDB ? <div className="myFlex"><div className='circularprogressWrapper'><CircularProgress color="inherit" /></div></div> : <div style={{ width: 'fit-content'}}>
                <table className='tablePopup'>
                    <tbody>
                    { sAdjType === 'ad unit' ?
                        <tr>
                            <td>AdUnit:</td>
                            <td className='myTruncText'>{ oAdUnitToAdjust?.AD_UNIT_NAME }</td>
                        </tr>
                      : <tr>
                            <td>Placement:</td>
                            <td className='myTruncText'>{ oPlacementToAdjust?.PLACEMENT_NAME }</td>
                        </tr> }
                        <tr>
                            <td>Data Inizio:</td>
                            <td>{ ( dDataInizio || moment() ).format('DD/MM/YYYY') }</td>
                        </tr>
                        <tr>
                            <td>Data Fine:</td>
                            <td>{ ( dDataFine || moment() ).format('DD/MM/YYYY') }</td>
                        </tr>
                    </tbody>
                </table>
                <span className='insAdjColumnHeaderWrapper'>
                    <CustomCssTextField
                        autoComplete    = "off"
                        id              = "etichettaAdj"
                        key             = "etichettaAdj"
                        label           = "Etichetta Adjustment"
                        value           = { sEtichettaAdj }
                        onChange        = { (event) => set_sEtichettaAdj(event.target.value.slice(0,199)) }
                        InputLabelProps = {{ shrink: true }}
                        variant         = "outlined"
                        type            = "text"
                        className       = "TextFieldEtichetta"
                        onKeyDown       = { (e) => { e.stopPropagation() }}
                    />
                    <CustomCssTextField
                        disabled
                        autoComplete    = "off"
                        id              = "bacinoAdj"
                        key             = "bacinoAdj"
                        label           = "Bacino"
                        value           = { sBacinoAdj || 0 }
                        InputLabelProps = {{ shrink: true }}
                        variant         = "outlined"
                        type            = "text"
                        className       = "TextFieldEtichetta"
                        onKeyDown       = { (e) => { e.stopPropagation() }}
                    />
                    <span>
                        <CustomCssTextField
                            // disabled        = { !sBacinoAdj }
                            autoComplete    = "off"
                            id              = "valueAdjust%"
                            key             = "valueAdjust%"
                            label           = "Modifica Adjustment %"
                            placeholder     = "0"
                            value           = { nValueAdjustPerc }
                            onChange        = { (event) => handleChangeValueAdjust( event.target.value, '%' ) }
                            InputLabelProps = {{ shrink: true }}
                            variant         = "outlined"
                            type            = "text"
                            className       = "TextFieldEtichetta"
                            onKeyDown       = { (e) => { e.stopPropagation() }}
                        />
                        <CustomCssTextField
                            autoComplete    = "off"
                            id              = "valueAdjust"
                            key             = "valueAdjust"
                            label           = "Adjustment"
                            placeholder     = "0"
                            value           = { nValueAdjust }
                            onChange        = { (event) => handleChangeValueAdjust( event.target.value, '#' ) }
                            InputLabelProps = {{ shrink: true }}
                            variant         = "outlined"
                            type            = "text"
                            className       = "TextFieldEtichetta"
                            onKeyDown       = { (e) => { e.stopPropagation() }}
                        />
                        
                    </span>
                </span>
            </div> }
        </DialogContent>
        <DialogActions>
            { loadingDB ? null :
                <>
                    <Button
                    // Forse va disabilitato il salva se  no bacino
                        onClick={ fAddAdjust } 
                        className='popupConfirm' >salva</Button>
                    <Button
                        onClick={ handleClearAdjParam }
                        className="popupClear"   >reset</Button>
                    <Button 
                        onClick={ closePopup }
                        className='popupAnnul'   >annulla</Button>
                </>
            }
        </DialogActions>
    </Dialog>
}

export default AdjustmentEdit;