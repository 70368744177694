import React, { useState, useEffect, useContext } from 'react';
import Checkbox                       from '@mui/material/Checkbox';
import InputLabel                     from '@mui/material/InputLabel';
import Input                          from '@mui/material/Input';
import InputAdornment                 from '@mui/material/InputAdornment';
import FormControl                    from '@mui/material/FormControl';
import IconButton                     from '@mui/material/IconButton';
import Button                         from '@mui/material/Button';
import Dialog                         from '@mui/material/Dialog';
import DialogActions                  from '@mui/material/DialogActions';
import DialogContent                  from '@mui/material/DialogContent';
import DialogTitle                    from '@mui/material/DialogTitle';

import axios                          from "axios";

import './UserEditing.css';
import utils                          from '../../util/CommonUtilities';
import { AuthContext }                from '../../contexts/authContext';
import { PopupConfirm }               from '../../components/PopupConfirm/PopupConfirm';
import { CustomCssTextField }         from "../../components/CustomCSS/CustomCSS.tsx";
import {  ActiveIcon
        , SupervisorAccountIcon
        , TraffickerIcon }          from '../../assets/icons/icons.js';
       /* , KeyIcon
          , VisibilityIcon
          , VisibilityOffIcon */  
import config from '../../config';

const bDebug = config.DEBUG && false;

function UserEditing( { options } ) {
    const auth = useContext(AuthContext);
    const 
         { bShowDialog, set_bShowDialog, oDialogOptions, loading, setLoading, sTitle, oMapLabelAndValues, reload, toggleNotifica } = options
        // ,isInsNewUser  = !oDialogOptions || ( Object.keys(oDialogOptions).length === 0 )
        ,{ oRow = {} } = oDialogOptions
        ,sOrigStatus   = oRow['FLAG_ATTIVO']
        // ,[ bShowMore        ,set_bShowMore        ] = useState(false)
        ,[ sUserRole        ,set_sUserRole        ] = useState(oRow['FLAG_ADMIN'])
        ,[ sTrafficker      ,set_sTrafficker      ] = useState(oRow['FLAG_TRAFFICKER'])
        ,[ sUserStatus      ,set_sUserStatus      ] = useState(oRow['FLAG_ATTIVO'])
        ,[ sFullName        ,set_sFullName        ] = useState(oRow['FULL_NAME']) // Nome e Cognome
        ,[ sUserName        ,set_sUserName        ] = useState(oRow['USERNAME']) // Email
        ,[ sUserAgents      ,set_sUserAgents      ] = useState(oRow['AGENTE_COD'])
     // ,[ aoListView       ,set_aoListView       ] = useState(JSON.parse(oRow['LIST_ALLARMI'])) // State per contenere l'elenco di pagine visibili all'utente
     // ,[ sPassword             ,set_sPassword             ] = useState('')
     // ,[ bPasswordCheck        ,set_bPasswordCheck        ] = useState(false)
     // ,[ bShowPassword         ,set_bShowPassword         ] = useState(false)
     // ,[ sPasswordConfirm      ,set_sPasswordConfirm      ] = useState("")
     // ,[ bPasswordConfirmCheck ,set_bPasswordConfirmCheck ] = useState(false)
     // ,[ bShowPasswordConfirm  ,set_bShowPasswordConfirm  ] = useState(false)
        ,[ oParamsModUser   ,set_oParamsModUser   ] = useState( {} )
        ,[ oParamsDelUser   ,set_oParamsDelUser   ] = useState( {} )
     // ,[ oParamsResetUser ,set_oParamsResetUser ] = useState( {} )
        ,[ oParamsResetPass ,set_oParamsResetPass ] = useState( {} )
        ,[ sPopupType       ,set_sPopupType       ] = useState('')
    ;
    const closePopup = () => set_sPopupType(''); 
    
    // !!! TODO  Fix FetchService
    const actionsOnUser = ({ params, actionType }) => {
        bDebug && console.log(params);
        if ( Object.keys( params || {} ).length ) {
            setLoading( true );
            ( async () => {
                try {
                    // Funzionante ma manca la gestione del codice per confermare l'email
                    // let resCognito = 'SUCCESS';
                    // if ( oRow['USERNAME'] !== sUserName ) {
                    //     resCognito = await auth.setAttribute({ Name: "email", Value: sUserName }, { Name: "email_verified", Value: true })
                    //     console.log('resCognito: ', resCognito);
                    //     console.log('resCognito: ', typeof resCognito);
                    // }
                    // // if ( !resCognito || !resCognito.res ) {
                    // if ( resCognito !== 'SUCCESS' ) {
                    //     throw new Error('Errore Cognito');
                    // }
                    const response = await axios.post(
                        config.API_URL + '/manage-user'
                        , null
                        , {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                            },
                            params : { pActionType: actionType
                                     , oParamsToDB: params
                                     ,...( sOrigStatus !== sUserStatus && { pToggleStatus: sUserStatus }) }
                        }
                    )
                    const { nRetVal, vErrorMessageDesc } = response.data;
                    // console.log('Response: ', response.data);
                    // console.log('Response: ', response.data.nRetVal);

                    if ( ( response.status === 200 ) && ( +nRetVal > 0 ) && ( !vErrorMessageDesc ) ) {
                        toggleNotifica({  modifica      : `Utente  ${ params.pUserName } aggiornato`
                                        , cancellazione : `Utente  ${ params.pUserName } cancellato`
                                        , resetPassword : 'Password invalidata' }[actionType] + ' correttamente', 'success', 5 );
                        reload();
                        set_bShowDialog( false );
                    } else {
                        console.error(`Errore ${ vErrorMessageDesc || '' }`);
                        toggleNotifica( `Errore ${ vErrorMessageDesc || '' }`, 'error' );
                        setLoading( false );
                    }
                } catch ( err ) {
                    console.error('ERR 134: Failed', err);
                    toggleNotifica( `Interrogazione fallita`, 'error' );
                    setLoading( false );
                }
            })();
        }
    };

    useEffect(() => {
        if ( Object.keys(oParamsModUser).length ) {
            actionsOnUser({ params: oParamsModUser, actionType: 'modifica' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsModUser ]);

    useEffect(() => {
        if ( Object.keys(oParamsDelUser).length ) {
            actionsOnUser({ params: oParamsDelUser, actionType: 'cancellazione' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsDelUser ]);

    useEffect(() => {
        if ( Object.keys(oParamsResetPass).length ) {
            // actionsOnUser({ url: '/settings/del-user', params: oParamsDelUser, actionType: 'del' });
            actionsOnUser({ params: oParamsResetPass, actionType: 'resetPassword' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsResetPass ]);

    // useEffect(()=>{
    //     set_bPasswordCheck(utils.regexPasswordValidator.test(sPassword));
    //     bDebug && console.log('passwordCheck: ', bPasswordCheck);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[sPassword]);

    // useEffect(()=>{
    //     set_bPasswordConfirmCheck(utils.regexPasswordValidator.test(sPasswordConfirm));
    //     bDebug && console.log('passwordCheck: ', bPasswordConfirmCheck);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[sPasswordConfirm]);

    // const isErrorPasswordFormat = (psw, field) => {
    //     let retVal = true
    //     psw.trim();
    //     if(psw !== ""){
    //         if ( field === 'confirm') {
    //             if ( sPassword === sPasswordConfirm ) {
    //                 retVal = !bPasswordConfirmCheck;
    //             }
    //         } else if ( field === 'newpsw') {
    //             retVal = !bPasswordCheck;
    //         }
    //     }else{
    //         retVal = false;
    //     }
    //     return retVal;
    // };

    const makeTextField = ( sKey, value, set_value ) => {
        const oMap = oMapLabelAndValues[sKey];
        // console.log('sKey: ', sKey);
        // console.log('oMap: ', oMap);

        if ( oMap ) {
            const format = oMap.format || (val => val);
            return <CustomCssTextField
                key      ={sKey}
                id       ={sKey}
                className={sKey}
                label    ={ oMap.label }
                value    ={ format( typeof value !== 'undefined' ? value : oRow[sKey] ) || '' }
                onChange ={ (event) => {
                    set_value( format( event.target.value ) );
                } }
                onClick  ={((event)=>{event.stopPropagation()})}
                disabled ={(sKey !== 'AGENTE_COD') ? !oMap.enabled : ( sUserRole !== 'Y' ? false : true ) }
                // In Tutti i casi controllo se il campo è enable, nel caso di agente_cod devo verificare se l'utente è admin
            />
        }
    };

    const makeCheckbox = ({ sFieldName, sState, setState }, cIcon ) => {
        const oMap = oMapLabelAndValues[sFieldName];
        // console.log(Icon)
        return <div className={sFieldName} >
            <div className="input-field flag-admin full-width">
                <div className='myFlexCreateUser'>
                    { cIcon }
                    <InputLabel className="inputLabel" id={ sFieldName + '-label' } disabled>&nbsp;&nbsp;{ /*oMap.format ? oMap.format(sState) : */oMap.label }</InputLabel>
                </div>
                <Checkbox
                    className="checkbox"
                    // labelId  ={ sFieldName + '-label' }
                    id       ={ sFieldName }
                    // value    ={ sState }
                    checked  ={ sState === 'Y' }
                    onChange ={ event => { setState(event.target.checked ? 'Y' : 'N' ); } }
                    // disabled ={!oMap.enabled}
                />
            </div>
        </div>
    }

    // const makeIconLabel = ({ sFieldName }) => {
    //     const oMap = oMapLabelAndValues[sFieldName];
    //     return <div className={sFieldName} >
    //         <div className="container">
    //             { oRow[ sFieldName ] === 'Y' 
    //                 ? <CheckIcon  addClass={"textGreen"}/>
    //                 : <CancelIcon addClass={"textRed"  }/> }
    //             <span>&nbsp;{ oMap.format(oRow[ sFieldName ]) }</span>
    //         </div>
    //     </div>
    // }

    // const makePswField = ({ sFieldName, sFieldLabel, sState, setState, showState, setShowState }) => (
    //     <div className={sFieldName}>
    //         <FormControl className="password-wrapper" >
    //             <Input
    //                 name        = { sFieldName  }
    //                 id          = { sFieldName  }
    //                 placeholder = { sFieldLabel }
    //                 value       = { sState      }
    //                 type        = { showState ? 'text' : 'password' }
    //                 onChange    = { (event) => { setState( event.target.value ); } }
    //                 error       = { isErrorPasswordFormat(sState, sFieldName) }
    //                 spellCheck      = { false }
    //                 startAdornment  = {
    //                     <InputAdornment position="start">
    //                         <KeyIcon className={"noHover"}/>
    //                     </InputAdornment>
    //                 }
    //                 endAdornment={
    //                     <InputAdornment position="end" tabIndex={-1}>
    //                         <IconButton onClick={ (event) => { setShowState(!showState) } } tabIndex={-1}>
    //                             { showState ? <VisibilityIcon /> : <VisibilityOffIcon />}
    //                         </IconButton>
    //                     </InputAdornment>
    //                 }
    //             />
    //         </FormControl>
    //     </div>
    // )


    return <Dialog open={ bShowDialog } className="users editing-dialog" >

        <DialogTitle>{ sTitle }</DialogTitle>

        <DialogContent className='fixPad'>
            { loading ? <div>&nbsp;<br/>&nbsp;</div> : <div>{
                <form className="editFields" autoComplete="off">
                    { makeTextField( 'USERNAME'        ,sUserName   ,set_sUserName ) }
                    {/* { makeIconLabel({ sFieldName: 'FLAG_COGNITO' }) } */}
                    { makeTextField( 'FULL_NAME'       ,sFullName   ,set_sFullName   ) }
                    { makeTextField( 'AGENTE_COD'      ,sUserAgents ,set_sUserAgents ) }
                    { makeTextField( 'DATA_INSERIMENTO'     ) }
                    { makeTextField( 'DATA_ULTIMA_MODIFICA' ) }
                    {/* { 
                        ( sOrigStatus === 'Y' ) 
                      ? makeTextField( 'DATA_ULTIMA_LOGIN'  ) 
                      : null 
                    } */}
                    {   ( sOrigStatus === 'N') 
                      ? makeTextField( 'DATA_CANCELLAZIONE' )
                      : null
                    }
                    {
                        makeCheckbox({
                             sFieldName:  'FLAG_ADMIN'
                            ,sState:      sUserRole
                            ,setState:    set_sUserRole
                            ,sFieldLabel: 'Administrator'
                        }, <SupervisorAccountIcon className={"noHover textDark"}/>)
                    }
                    {
                        makeCheckbox({
                             sFieldName:  'FLAG_TRAFFICKER'
                            ,sState:      sTrafficker
                            ,setState:    set_sTrafficker
                            ,sFieldLabel: 'Trafficker'
                                   
                        }, <TraffickerIcon className={"noHover textDark"}/>)
                    }
                    {
                        // (oRow['FLAG_COGNITO'] === 'Y') && makeCheckbox({
                        makeCheckbox({
                             sFieldName:  'FLAG_ATTIVO'
                            ,sState:      sUserStatus
                            ,setState:    set_sUserStatus
                            ,sFieldLabel: 'Attivo'
                        }, <ActiveIcon className={"noHover textDark"}/>)
                    }
                    {/*
                        false && <Button
                            className="dianButtonLarge"
                            onClick={() => {
                                set_sPopupType('Gestisci Visibilità Utente');
                            }}
                        >Gestisci Visibilità Utente</Button>
                    */}
                    {
                        // (oRow['FLAG_COGNITO'] === 'Y') && <Button
                        <Button
                            className="dianButtonLarge"
                            onClick={() => {
                                set_sPopupType('Reset Password');
                            }}
                        >Reset Password</Button>
                    }
                </form>
            }</div> }

            {/*
                <PopupConfirm { ...{ 
                 oPopupOptions:  {
                    DialogTitle:       'Gestione Visibilità Utente'
                    ,DialogContentText: {
                        
                    }
                    // ,onClick:           () => { set_aoListView({ pUserId: oRow.KUSER }); }
                    ,onCancel:          closePopup
                 }
                 ,sPopupType: sPopupType === 'Gestisci Visibilità Utente' ? true : false
                 ,set_sPopupType 
                }} /> 
            */}

            <PopupConfirm { ...{ 
                 oPopupOptions:  {
                     DialogTitle:       'Reset Password Forzato'
                    ,DialogContentText: <form className='centerText'>
                                            <span>Confermi di voler resettare la password di:</span><br/><br/>
                                            <span>{ sUserName }</span><br/><br/>
                                            <span>Sarà poi necessario procedere al recupero della password da parte dell'utente.</span>
                                        </form>
                        
                    ,onClick:           () => { set_oParamsResetPass({ pUserId: oRow.KUSER }); }
                    ,onCancel:          closePopup
                }
                ,sPopupType: (sPopupType === 'Reset Password')
                ,set_sPopupType 
            }} />

            <PopupConfirm { ...{ 
                 oPopupOptions:  {
                     DialogTitle:       'Elimina Utente'
                    ,DialogContentText: 'Sei sicuro di voler eliminare l\'utente "' + oRow.FULL_NAME + '" ?'
                    ,onClick:           () => { set_oParamsDelUser({ pUserId: oRow.KUSER }); }
                    ,onCancel:          closePopup
                    ,firstButtonLabel:  'elimina'
                    ,classFirstButton:  'popupRemove'
                }
                ,sPopupType: (sPopupType === 'delete')
                ,set_sPopupType 
            }} />
        </DialogContent>

        <DialogActions className='dialogButton'>
            { loading ? null :
                <>
                    {/*
                        <Button onClick={ () => {set_bShowMore(!bShowMore)}}
                            className='popupDian popupMoreActions' 
                            startIcon={bShowMore ? <DoNotDisturbOnOutlinedIcon />: <AddCircleOutlineIcon /> }>
                            Mostra { bShowMore ? `meno` : `più` }
                        </Button>
                    */}
                    {/* <span className='flex-end'> */}
                        <Button onClick={ ()=>{
                            set_oParamsModUser({
                                 pUserId         : oRow.KUSER
                                ,pCognome        : sFullName
                                ,pUserName       : sUserName
                                ,pEMailAddress   : sUserName
                                ,pAgenteCod      : (sUserRole === 'Y') ? '' : sUserAgents
                                ,pFlagAdmin      : sUserRole
                                ,pFlagTrafficker : sTrafficker
                                ,pFlagEnabled    : sUserStatus
                                ,pFlagCognito    : oRow.FLAG_COGNITO
                                ,pParentUserId   : oRow.PARENT_KUSER
                                ,pAlerts         : oRow.ALERTS
                                ,pMenuItems      : oRow.MENU_ITEMS
                            });
                        } } className='popupConfirm'>salva</Button>
                        { ( sOrigStatus === 'N') && localStorage.getItem('userEmail').includes('@systrategy.it') && <>
                            <Button
                                className="popupRemove"
                                onClick={() => {
                                    set_sPopupType('delete');
                                }}
                            >elimina</Button>
                        </>}
                        <Button onClick={ ()=> {
                            set_bShowDialog(false);
                            // reload();
                        } } className='popupAnnul'>annulla</Button>
                    {/* </span> */}
                </>
            }
        </DialogActions>

    </Dialog>
}

export default UserEditing;