import React, {useContext, useState, useEffect}  from 'react';
import { Link }                                  from 'react-router-dom';
import Button                                    from '@mui/material/Button';

import { AppContext }                            from '../../App.js';
import './ErrorPage.css'

const ErrorPage404 = (props) => {
    const bAdminUser          = ( localStorage.getItem('flagAdmin') || '' ) === 'Y';
    const jumpRoute = (JSON.parse(localStorage.getItem('menuItemsToDisplay') || "[{}]") )
                        .map( o => Object.keys(o) ).flat(Infinity).includes('Allarmi') || bAdminUser  ? 'allarmi' : 'home';
    const { set_dLastUpdate } = useContext(AppContext);

    useEffect(() => {
        set_dLastUpdate('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    return (
        <div className="component-container errorPage ">
            <div className="flexy component-card">
                <div className="app-login-container  ">
                    <div className="card-wrapper ">
                        <div className='card-wrapper-content'>
                            <h1 className='wrap-row title-error'>
                                404
                            </h1>
                            <h2 className='descErr'> Pagina non trovata</h2>
                            <Link to={`/${ jumpRoute }`} className='button404' >
                                <Button className="backButton text-uppercase" variant="outlined" >
                                    <span>Ritorna alla home</span>
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage404