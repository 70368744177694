import Dialog               from '@mui/material/Dialog';
import DialogTitle          from '@mui/material/DialogTitle';
import DialogContent        from '@mui/material/DialogContent';
import DialogActions        from '@mui/material/DialogActions';
import Button               from '@mui/material/Button';
import config               from '../../config.js';
import './PopupConfirm.css';

const bDebug = config.DEBUG && false;

// ----- popup di conferma operazione ----- 
export function PopupConfirm( oConfirmOptions ){
    
    const { oPopupOptions, sPopupType, set_sPopupType } = oConfirmOptions;
    // console.log('sPopupType',sPopupType);
    const closeAction = () => { 
        oPopupOptions.hasOwnProperty('onCancel') && oPopupOptions.onCancel()
        set_sPopupType(''); 
    };


    // reset button
    const resetAction = () => { oPopupOptions.onReset()};
    bDebug && console.log(oPopupOptions.DialogTitle);
    bDebug && console.log(oPopupOptions.Content);
    
    return (
        <Dialog
            open={ ( !!sPopupType ) }
            onClose={ closeAction }
            className={'dialogWrapper ' + ( oPopupOptions.sDialogCssClass || '' ) }
        >
            <DialogTitle className={ oPopupOptions.classTitle || 'popupTitle' }>{ oPopupOptions.DialogTitle }</DialogTitle>
            <DialogContent className={ oPopupOptions.classWrapperContent || 'popupWrapperContent'}>
                <div className={ oPopupOptions.classSubTitle || 'popupSubTitle' }>
                    { oPopupOptions.DialogContentText }
                </div>
                { oPopupOptions.Content }
            </DialogContent>
            <DialogActions>
                {
                    !oPopupOptions.hideFistButton ? <Button
                        onClick  ={ oPopupOptions.onClick }
                        className={ oPopupOptions.classFirstButton || 'popupConfirm' }
                        disabled ={ oPopupOptions.disabledConfirmButton || false }
                    >{ oPopupOptions.firstButtonLabel || 'conferma' }</Button> : ''
                }
                {
                    oPopupOptions.isThirdButton ? <Button 
                        onClick={ resetAction }
                        className={ oPopupOptions.classThirdButton || 'popupClear' }
                    >{ oPopupOptions.thirdButtonLabel  || 'reset' }</Button> : ''
                }
                
                <Button 
                    onClick={ closeAction } 
                    className={ oPopupOptions.classCloseButton || 'popupAnnul' }
                    // style  ={ { color: oPopupOptions.closeButtonColor || 'secondary', backgroundColor: 'white' } }
                    
                >{ oPopupOptions.closeButtonLabel || 'annulla' }</Button>
               
            </DialogActions>
        </Dialog>
    )
}

/* ----- esempio di utilizzo -----

    const [ sPopupType ,set_sPopupType ] = useState('');

    const oPopupOptions = {
         DialogTitle:       'Update Users'
        ,DialogContentText: 'Are you sure you want to update Users?'
        ,onClick:           refreshUsers
        ,firstButtonLabel:  'confirm'
    };
    
    return <PopupConfirm
        { ...{ oPopupOptions, sPopupType, set_sPopupType } }
    />

*/