import React, {
     useState
    ,useEffect
    ,useContext
}                               from 'react';
import config                   from '../../config.js';
import axios                    from 'axios';

import                          './Formati.css'
import { AppContext }           from '../../App.js';
import { AuthContext }          from '../../contexts/authContext';
import { SimpleTable }          from '../../components/SimpleTable/SimpleTable';
import CircleProgress           from '../../components/CircleProgress/CircleProgress.tsx';
import {
    CustomCssTextField, CustomMaxWidthTooltip
} from '../../components/CustomCSS/CustomCSS.tsx';

import {
      FolderOffIcon
    , SaveOutlinedIcon
    , DeleteIcon
    , UndoIcon, CheckIcon, CancelIcon, InfoIcon
} from '../../assets/icons/icons.js';

const bDebug = config.DEBUG && false;

const Formati = () => {
    const auth          = useContext(AuthContext);
    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);
    const headers       = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };
    const oFormatoVuoto = { sFormatoID: undefined ,sFormatoDesc: undefined ,nPeso: undefined };
    const
         [ loading          ,setLoading         ] = useState(true)
        ,[ onReload         ,set_onReload       ] = useState(null) // State per triggerare il lancio della chiamata
        
        ,[ oEditable        ,set_oEditable      ] = useState({ ...oFormatoVuoto })
        ,[ aoFormati        ,set_aoFormati      ] = useState([])
    ;
    
    /*
    
        GET_FORMATI
        
        [ 
             { "FORMATO_DESC": "Arricchimento" ,"PESO": 1 }
            ,{ "FORMATO_DESC": "Banner Msite"  ,"PESO": 2 }
        ]
    
    */

    const iconV = <div className='iconWrap'><CheckIcon  className={"textGrey"}      /></div>;
    const iconX = <div className='iconWrap'><CancelIcon className={"textLightGrey"} /></div>;
    
    const toggleNotifica = ( message, severity, timeout ) => {
        setLoading(false);
        set_oNotifyOptions({ message, severity,...( timeout && { timeout }) });
    };
    
    const actionsOnFormato = ({ sActionType, params }) => {
        
        bDebug && console.log(  sActionType, params );
        let sFormatoDesc = '';
        
        if ( sActionType === 'DEL' ) {          // eccezione specifica
            sFormatoDesc = params.sFormatoDesc; // recupero sFormatoDesc perché serve alla notifica
            delete params.sFormatoDesc;         // ma poi lo elimino prima di passarlo al database
        }
        
        if ( Object.keys( params || {} ).length ) {
            setLoading( true );
            ( async () => {
                try {
                    const response = await axios.post(
                         config.API_URL + '/set-formato'
                        ,null
                        ,{
                            headers,
                            params : {
                                 sActionType
                                ,oParamsToDB: params
                            }
                        }
                    );
                    
                    const { nRetVal, vErrorMessageDesc } = response.data;
                    
                    if ( ( response.status === 200 ) && ( +nRetVal > 0 ) && ( !vErrorMessageDesc ) ) {
                        toggleNotifica({
                             'INS': `Formato "${ oEditable.sFormatoDesc }" inserito`
                            ,'MOD': `Formato "${ oEditable.sFormatoDesc }" modificato`
                            ,'DEL': `Formato "${ sFormatoDesc           }" eliminato`
                        }[ sActionType ] + ' correttamente', 'success', 5 );
                        reload();
                    } else {
                        const error = `Errore ${ vErrorMessageDesc || '' }`;
                        console.error( error );
                        toggleNotifica( error, 'error' );
                    }
                    set_oEditable({ ...oFormatoVuoto });
                    
                } catch ( err ) {
                    console.error('ERR 134: Failed', err);
                    toggleNotifica( `Operazione fallita`, 'error' );
                }
            })();
        }
        
    };
    
    const formatDesc     = ( val, oRow ) => {
        return (
            <span className="modDesc">
                <CustomCssTextField
                    id          = "pFormatoDesc"
                    placeholder = "Nuovo Formato"
                    value       = { ( ( oEditable?.sFormatoID === oRow.FORMATO_ID ) ? oEditable.sFormatoDesc : val ) || '' }
                    onInput     = { (event) => {
                        const sFormatoDesc = event.target.value;
                        if ( ( typeof sFormatoDesc === 'string' ) && ( sFormatoDesc.length < 31 ) ) {
                            set_oEditable( {
                                 sFormatoID:    oRow.FORMATO_ID
                                ,sFormatoDesc
                                ,nPeso:         ( oEditable?.sFormatoID !== oRow.FORMATO_ID ) ? oRow.PESO : ( oEditable.nPeso ?? oRow.PESO )
                            } );
                        }
                    }}
                />
            </span>
        )
    }
    
    const formatPeso     = ( val, oRow ) => {
        return (
            <span className="modPeso">
                <CustomCssTextField
                    id          = "pPeso"
                    placeholder = "Peso"
                    value       = { ( ( oEditable?.sFormatoID === oRow.FORMATO_ID ) ? oEditable.nPeso : val ) ?? '' }
                    onInput     = { (event) => {
                        const newValue = event.target.value;
                        if ( ( ( newValue === '' ) || /^[0-9\b]+$/.test(newValue) ) && ( ( newValue + '' ).length < 3 ) ) {
                            const nPeso = ( newValue === '' ) ? '' : ( ( newValue > 999 ) ? 999 : ( ( newValue < 0 ) ? 0 : newValue ) );
                            set_oEditable( {
                                 sFormatoID:    oRow.FORMATO_ID
                                ,sFormatoDesc:  ( oEditable?.sFormatoID !== oRow.FORMATO_ID ) ? oRow.FORMATO_DESC : ( oEditable.sFormatoDesc ?? oRow.FORMATO_DESC )
                                ,nPeso
                            } );
                        }
                    }}
                />
            </span>
        )
    }
    
    // Dichiarazione di intestazione di tabella
    const aoHeaders = [
         { name: 'DELETE'       ,title: ''          ,width: 30  ,noFilter: true ,notSortable: true  ,noExport: true ,format: ( val, oRow ) => 
             (
                 ( oRow.FORMATO_ID === '-1' ) ? null : (
                     ( oEditable?.sFormatoID === oRow.FORMATO_ID )
                     ? <span onClick={ () => { set_oEditable({ ...oFormatoVuoto }); } } ><UndoIcon/></span>
                     : <span onClick={ () => {
                            actionsOnFormato({ sActionType: 'DEL' ,params: { pFormatoId: oRow.FORMATO_ID ,sFormatoDesc: oRow.FORMATO_DESC} });
                            // passo anche sFormatoDesc perché serve alla notifica, ma poi lo elimino prima di passarlo al database
                        } }
                     ><DeleteIcon/></span>
                 )
             )
         }
        ,{ name: 'FORMATO_DESC' ,title: 'Formato'   ,width: 300 ,filterOriginalValue: true          ,format: formatDesc ,isUniqueKeyForRow: true }
        ,{ name: 'PESO'         ,title: 'Peso'      ,width: 40  ,filterOriginalValue: true          ,format: formatPeso }
        ,{ name: 'FLAG_TEST'    ,title: 'Presente'  ,width: 60                                      
            ,format: ( val, oRow ) => ( oRow.FORMATO_ID === '-1' ) ? '' : ( ( val === 'Y' ) ? iconV : iconX )
            ,selectOptions: [
                 { label: ' '   ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
                ,{ label: iconV ,value: 'Y' ,checkFunc: val => ( val === 'Y' ) }
                ,{ label: iconX ,value: 'N' ,checkFunc: val => ( val === 'N' ) }
            ]
        }
        ,{ name: 'SAVE'         ,title: ''          ,width: 30  ,noFilter: true ,notSortable: true  ,noExport: true ,format: ( val, oRow ) => (
            ( oEditable?.sFormatoDesc && oEditable?.nPeso )
            ? <span
                className   ={ ( oEditable?.sFormatoID !== oRow.FORMATO_ID ) ? 'hide' : '' }
                onClick     ={
                    () => {
                        actionsOnFormato({
                             sActionType:   ( oRow.FORMATO_ID === '-1' ) ? 'INS' : 'MOD'
                            ,params:        {
                                 ...( ( oRow.FORMATO_ID !== '-1' ) && { pFormatoId: oRow.FORMATO_ID })
                                ,pFormatoDesc:  oEditable?.sFormatoDesc
                                ,pPeso:         oEditable?.nPeso
                             }
                        });
                    }
                }
            ><SaveOutlinedIcon/></span>
            : null
        ) }
    ];
    
    const reload = () => {
        setLoading(true);
        set_onReload(!onReload);
    };
    
    useEffect(() => {
        set_dLastUpdate('');
        setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        setLoading(true);
        // set_oNotifyOptions(null);
        
        ( async () => {
            try {
                
                const response = await axios.get(config.API_URL + '/get', {
                    headers,
                    params: {'getFormatiParams': true}
                });
                if (response?.status === 200) {
                    const result        = response?.data;
                    const aoTempResults = result?.aoRecords || [];
                    aoTempResults.unshift({ FORMATO_ID: '-1' ,FORMATO_DESC: undefined ,PESO: undefined });
                    set_aoFormati(aoTempResults);
                }
                
            } catch (err) {
                console.error('ERR 130: Failed get: ', err);
                set_oNotifyOptions({message: `Interrogazione fallita`, severity: 'error'});
            }
            setLoading(false);
        })();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onReload]);
    
    return (
        <div className="component-container formati">
            <div className="component-card">
                { loading ? <CircleProgress /> : 
                    <>
                        <div>
                            <CustomMaxWidthTooltip
                                title={
                                    <>
                                        Le stringhe di testo associate ai pesi vengono cercate nel testo della descrizione
                                        <br/>dei Lineitems indipendentemente da maiuscole o minuscole (quindi è case
                                        insensitive)
                                        <br/>
                                        <br/>Il campo "Presente" indica se il Formato specificato è presente in almeno un
                                        Lineitem
                                    </>
                                }
                                placement="right"
                                arrow
                            >
                                <InfoIcon className={'infoIcon'}/>
                            </CustomMaxWidthTooltip>
                        </div>
                        <div className="component-options-wrapper">
                            {
                                aoFormati?.length
                                ? <SimpleTable
                                    chiave            ={'FORMATI'}
                                    sTableDataType    ={'FORMATI'}
                                    aoRows            ={ aoFormati }
                                    aoCols            ={ aoHeaders }
                                    oSortOptions      ={ { isSortable: true     } }
                                    oExportOption     ={ { sFileName: `Formati` } }
                                />
                                : <div className="component-options-wrapper-alarmNoRecord">
                                    <FolderOffIcon />&nbsp;Nessun risultato trovato
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
};

export default Formati;
