import React, {
     useState
    ,useEffect
    ,useContext
}                               from 'react';
import config                   from '../../config.js';
import axios                    from 'axios';
import moment                   from 'moment';
import {
    Button,
    Checkbox
} from '@mui/material';

import                          './AgentsTraffickers.css'
import { AppContext }           from '../../App.js';
import { AuthContext }          from '../../contexts/authContext';
import { SimpleTable }          from '../../components/SimpleTable/SimpleTable';
import CircleProgress           from '../../components/CircleProgress/CircleProgress.tsx';

import {
    FolderOffIcon
    , CheckBoxFlagged
    , CheckBoxEmpty
    , ArrowDownRightIcon
    , ArrowUpRightIcon
    , AutoDistributionIcon
    , SaveOutlinedIcon, UndoIcon, InfoIcon
} from '../../assets/icons/icons.js';
import {CustomMaxWidthTooltip} from '../../components/CustomCSS/CustomCSS.tsx';

const bDebug = config.DEBUG && false;

const AgentsTraffickers = () => {
    
    const
         [ loading          ,setLoading         ] = useState(true)
        ,[ onReload         ,set_onReload       ] = useState(null) // State per triggerare il lancio della chiamata
        
        ,[ aoTraffickers    ,set_aoTraffickers  ] = useState([])
        ,[ aoAgents         ,set_aoAgents       ] = useState([])
        
        ,[ isAutoDistr      ,set_isAutoDistr    ] = useState(false)
    ;
    
    const auth              = useContext(AuthContext);
    const headers           = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };
    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);
    
    const toggleNotifica    = ( message, severity, timeout ) => {
        setLoading(false);
        set_oNotifyOptions({ message, severity,...( timeout && { timeout }) });
    };
    
    const actionsOnAgent    = async ({ sActionType, params }) => {
        
        bDebug && console.log(  sActionType, params );
        
        setLoading( true );
        try {
            const response = await axios.post(
                 config.API_URL + '/manage-agents-traffickers'
                ,null
                ,{
                    headers,
                    params : {
                         sActionType
                        ,oParamsToDB: params || {}
                    }
                }
            );
            
            const { nRetVal, vErrorMessageDesc } = response.data;
            
            if ( ( response.status === 200 ) && ( +nRetVal > 0 ) && ( !vErrorMessageDesc ) ) {
                toggleNotifica({
                     'SET':     `Distribuzione effettuata`
                    ,'ASSIGN':  `Distribuzione automatica calcolata, confermare per salvarla`
                }[ sActionType ] + '', sActionType === 'ASSIGN' ? 'warning' : 'success', 5 );
                setLoading(true);
                set_onReload( b => !b );
                return true;
            } else {
                const error = `Errore ${ vErrorMessageDesc || '' }`;
                console.error( error );
                toggleNotifica( error, 'error' );
                return false;
            }
            
        } catch ( err ) {
            console.error('ERR 134: Failed', err);
            toggleNotifica( `Operazione fallita`, 'error' );
            return false;
        }
        
    };
    
    const formatCheckBox    = ( nTraffickerID ) =>
        ( val, oRow ) => 
            <Checkbox
                checked   = { ( ( val || -1 ) === nTraffickerID ) } // -1 indica i "non assegnati"
                onClick   = { (event) => { event.stopPropagation() } }
                onChange  = { (event) => {
                    actionsOnAgent({
                         sActionType:   'SET'
                        ,params:        {
                             pFlagAutoAssignTraf:   isAutoDistr ? 'Y' : 'N'
                            ,pAgentId:              oRow.AGENT_ID
                            ,pTraffickerId:         nTraffickerID
                        }
                    });
                } }
                className = { `checkbox-custom` }
            />
    ;
    
    /*
    
        GET_AGENTS
    
        AGENT_ID    AGENT_NAME          PESO    TRAFFICKER_ID   TRAFFICKER_NAME     PESO_PER_TRAFFICKER
        204343974	Caterina Oderigo	77	    161	            David Raspa	        1259
        173165814	Isidoro Maccagnan	32	    161	            David Raspa	        1259
        
        
        GET_USERS
        
        [{
             "KUSER"                : 165
            ,"COGNOME"              : "Alessandro Lo Campo"
            ,"NOME"                 : null
            ,"FULL_NAME"            : "Alessandro Lo Campo"
            ,"FLAG_ADMIN"           : "Y"
            ,"USERNAME"             : "alessandro.locampo@gmail.com"
            ,"EMAIL_ADDRESS"        : "alessandro.locampo@gmail.com"
            ,"AGENTE_COD"           : null
            ,"FLAG_TRAFFICKER"      : "N"
            ,"FLAG_COGNITO"         : "N"
            ,"FLAG_ATTIVO"          : "N"
            ,"PARENT_KUSER"         : null
            ,"DATA_INSERIMENTO"     : "20230704114302"
            ,"DATA_ULTIMA_MODIFICA" : "20230704114302"
            ,"DATA_CANCELLAZIONE"   : "20230704114302"
            ,"DATA_ULTIMA_LOGIN"    : null
            ,"NUM_TOT"              : 11
            ,"MAX_LAST_EXECUTION"   : "20240419142134"
        }]
    
    */
    
    const iconChecked       = <div className='iconWrap d-flex'><CheckBoxFlagged className={"textGrey"}/></div>
    const iconNotChecked    = <div className='iconWrap d-flex'><CheckBoxEmpty   className={"textLightGrey"}/></div>
    
    // Crea le colonne dei trafficker
    const faoTraffickersColumns   = () => {
        let aoTraffickersColumns = [];
        for ( let n = 0; n < aoTraffickers?.length; n++ ) {
            
            const oTrafficker     = aoTraffickers[n];
            const nTraffickerID   = oTrafficker?.KUSER;
            const DIFF_PESO_MEDIO = ( nTraffickerID === -1 ) ? 0 : ( +oTrafficker?.DIFF_PESO_MEDIO || 0 );
            
            aoTraffickersColumns.push({
                 name            : `TRAFFICKER_ID`
                ,key             : nTraffickerID
                ,title           : (<>
                    <span className={ 'traffickerNameHeader ' + ( ( nTraffickerID === -1 ) ? 'non-assegnato' : '' ) }>{
                        ( oTrafficker?.FULL_NAME || 'Non assegnato' )
                    }</span><br/><span className="pesoTotHeader">{
                        ( +oTrafficker?.PESO_TOT || 0 )
                    }</span><br/><span className={ 'diffPesoMedioHeader ' + (
                            ( DIFF_PESO_MEDIO === 0 ) ? 'zero'     :
                            ( DIFF_PESO_MEDIO  >  0 ) ? 'positive' :
                                                        'negative'
                        )
                    }>{
                        ( DIFF_PESO_MEDIO === 0 ) ? '-' : ( DIFF_PESO_MEDIO > 0 ) ? ( '+' + DIFF_PESO_MEDIO ) : ( '' + DIFF_PESO_MEDIO ) 
                    }</span>
                </>)
                ,headerTooltip   : ( oTrafficker?.FULL_NAME ? (`${ oTrafficker?.FULL_NAME } (${ oTrafficker?.PESO_TOT || 0 })`) : `Non assegnato (${( +oTrafficker?.PESO_TOT || 0 )})` )
                ,group           : 'Traffickers'
                ,width           : 70
                ,format          : formatCheckBox( nTraffickerID )
                ,formatExcel     : (val) => ( ( val || -1 ) === nTraffickerID ) ? 'Y' : ''
                ,filterOriginalValue : true
                ,notSortable     : true
                ,additionalClass : () => ' cell ' + ( nTraffickerID || '' ) + ( (n === 0) ? ' firstCol' : (n + 1 === aoTraffickers?.length) ? ' lastCol' : '' )
                ,selectOptions   : [
                     { label: ' '            ,value: 'all'     ,checkFunc: () => true } // tutte le righe sono valide
                    ,{ label: iconChecked    ,value: 'include' ,checkFunc: ( rowValue ) => ( +rowValue || -1 ) === +nTraffickerID }
                    ,{ label: iconNotChecked ,value: 'exclude' ,checkFunc: ( rowValue ) => ( +rowValue || -1 ) !== +nTraffickerID }
                ]
            });
            
        }
        return aoTraffickersColumns;
    }
    
    const calcPeso          = ({ aoTraff, aoAgenti }) => {
        
        const oTraffIndexes = {};
        for ( let i = 0; i < aoTraff.length; i++ ) {
            oTraffIndexes[ aoTraff[i].KUSER + '' ] = i;
        }
        for ( const oAgente of aoAgenti ) {
            const traffID      = ( oAgente.TRAFFICKER_ID || -1 ) + '';
            const oTraffAgente = aoTraff[ oTraffIndexes[ traffID ] ];
            if ( !oTraffAgente ) {
                console.error('agente inesistente: ', traffID );
            }
            oTraffAgente.PESO_TOT = ( +oTraffAgente?.PESO_TOT || 0 ) + ( +oAgente?.PESO || 0 );
        }
        
        let sum = 0;
        for ( const oTraff of aoTraff ) {
            sum += ( +oTraff?.PESO_TOT || 0 ); 
        }
        const media = sum / ( ( aoTraff?.length - 1 ) || 1 ); // per la media ESCLUDO solo il trafficker "non assegnato" 
        for ( const oTraff of aoTraff ) {
            oTraff.DIFF_PESO_MEDIO = +oTraff.PESO_TOT - +(media.toFixed(0));
        }
        
        return ({ aoTraff, aoAgenti });
        
    };
    
    const onClickAutoBtn    = async () => {
        
        const res = await actionsOnAgent({
             sActionType:   'ASSIGN'
            ,params:        {}
        });
        
        if ( res ) { set_isAutoDistr(true); }
    }
    
    const onClickAutoConfirmButton = async () => {
        
        const res = await actionsOnAgent({
             sActionType:   'SET'
            ,params:        {
                pFlagSaveAll: 'Y'
            }
        });
        
        if ( res ) { set_isAutoDistr(false); }
    }
    
    // partendo da un nome di colonna come "MESE_MENO6" lo trasforma in -6 e restituisce il nome del mese -6 a partire dal mese attuale
    const getYearMonthFromDBcolName = ( s ) => {
        const nMonthFromToday = +( s.replaceAll('MENO','-').split('_')[1] );
        const mMomentDate     = moment().add(nMonthFromToday, 'months');
        const sYear           = mMomentDate.format('YYYY');
        const sMonth          = mMomentDate.format('MMM');
        return { sYear ,sMonth: sMonth.charAt(0).toUpperCase() + sMonth.slice(1) ,isCurrent: nMonthFromToday === 0 };
    }
    
    const asMonthsCols = ['MESE_MENO6','MESE_MENO5','MESE_MENO4','MESE_MENO3','MESE_MENO2','MESE_MENO1','MESE_0','MESE_1','MESE_2','MESE_3'];
    const calcoloTendenza = ( nCol, nVal, oRecord ) => {
        const nPreviousVal = oRecord?.[ asMonthsCols[ nCol - 1 ] ] || 0;
        return (
            ( nCol === 0 )          ? null :
            ( nPreviousVal > nVal ) ? <ArrowDownRightIcon className="red"  /> :
            ( nPreviousVal < nVal ) ? <ArrowUpRightIcon   className="green"/> :
            null /* <EqualIcon          className="grey" /> */
        );
    };
    
    const nPesoTotale = aoAgents.reduce( ( n, o ) => n + o.PESO, 0 );
    const sPesoTotale = nPesoTotale + '' + ( ( nPesoTotale !== aoTraffickers.reduce( ( n, o ) => n + o.PESO_TOT, 0 ) ) ? '!' : '' );
    
    // Intestazioni della tabella
    const aoHeaders = [
         { name: 'AGENT_NAME'   ,title: 'Agente' ,width: 140 ,isUniqueKeyForRow: true } // <><span>Agente</span><br/><span>({ aoAgents.length })</span></>
        , ...faoTraffickersColumns()
        ,{ name: 'PESO'         ,title: <><span>Peso</span><br/><span>({ sPesoTotale })</span></> ,width: 55 ,noFilter: true ,additionalClass: () => 'bold' ,headerTooltip: `Peso (${sPesoTotale})`  }
        , ...asMonthsCols.map( ( sCol, nCol ) => {
            const { sYear ,sMonth ,isCurrent } = getYearMonthFromDBcolName( sCol );
            return {
                 name:              sCol
                ,group:             'Peso per mese'
                ,title:             <span className={ isCurrent ? 'isCurrent' : '' }><span>{sYear}</span><br/><span>{sMonth}</span></span>
                ,headerTooltip:     `${ sYear } ${ sMonth }`
                ,width:             60
                ,noFilter:          true
                ,notSortable:       true
                ,additionalClass:   () => 'peso-per-mese' + ( (nCol === 0) ? ' firstCol' : (nCol + 1 === asMonthsCols?.length) ? ' lastCol' : '' )
                ,format:            ( val ,oRow ) => <>
                    <span>{ calcoloTendenza( nCol, val ,oRow ) }</span><span className={ isCurrent ? 'isCurrent' : '' }>{val}</span>
                </>
            };
        })
    ];

    useEffect(() => {
        set_dLastUpdate('');
        setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoading(true);
        
        ( async () => {
            try {
                
                let aoTempAgents = [], aoTempTraffickers = [];
                
                let response = await axios.get( config.API_URL + '/get', { headers, params: { 'getUsersByPassParams': true } } );
                if ( response?.status === 200 ) {
                    const result = response.data;
                    if ( result ) {
                        const aoUsers = result?.aoRecords || [];
                        aoTempTraffickers = aoUsers.filter( o => ( o.FLAG_TRAFFICKER === 'Y' ) && ( o.FLAG_ATTIVO === 'Y' ) );
                        // aggiungo un record vuoto all'inizio per indicare gli agenti "NON ASSEGNATI"
                        aoTempTraffickers.unshift({ KUSER: -1 ,FULL_NAME: 'Non assegnato' });
                    }
                } else {
                    throw new Error('error in getUsersParams');
                }
                
                response = await axios.get(
                     config.API_URL + '/get'
                    ,{ headers, params: { 'getAgentsTraffickersParams': true, 'pFlagAutoAssignTraf': isAutoDistr ? 'Y' : 'N' } }
                );
                if ( response?.status === 200 ) {
                    const result = response.data;
                    if ( result ) {
                        aoTempAgents = result?.aoRecords || [];
                    }
                } else {
                    throw new Error('error in getAgentsTraffickersParams');
                }
                
                // manipolo i Trafficker per fare i calcoli
                ({ aoTraff: aoTempTraffickers, aoAgenti: aoTempAgents } = calcPeso({ aoTraff: aoTempTraffickers, aoAgenti: aoTempAgents }) );
                
                // tutte le set le faccio alla fine assieme 
                // (non importa quale prima e quale dopo, la tabella verrà visualizzata se ci sono entrambi)
                set_aoTraffickers( aoTempTraffickers );
                set_aoAgents(aoTempAgents );
                
            } catch(err) {
                console.error('ERR 130: Failed get: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            }
            setLoading(false);
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);

    return (
        <div className="component-container agentsTraffickers">
            <div className="component-card">
                <CustomMaxWidthTooltip
                    title={
    <>
        Vengono presi in considerazione solo i trafficker attivi (modificabili nella pagina "Utenti").
        <br/>
        <br/>Il peso per agente viene calcolato sommando i pesi, assegnati ai vari formati,
        <br/>di tutte le campagne (lineitems) assegnate a quell'agente
        <br/>nel periodo che comprende:
        <br/>&nbsp;i 6 mesi prima del mese corrente,
        <br/>&nbsp;il mese corrente,
        <br/>&nbsp;i 3 mesi successivi al mese corrente.
        <br/>
        <br/>E' possibile assegnare un agente ad un solo Trafficker.
        <br/>
        <br/>La distribuzione automatica cerca di distribuire equamente gli agenti,
        <br/> in base al loro peso totale, ai vari Trafficker.
        <br/>Può risultare comunque uno scarto, evidenziato in rosso se in eccesso o in verde se in difetto.
        <br/>Quando si esegue la distribuzione automatica, si possono effettuare delle ulteriori modifiche
        <br/>e poi è possibile salvare il tutto confermando con l'apposito pulsante.
    </>
                    }
                    placement="bottom"
                    arrow
                >
                    <InfoIcon className={'infoIcon'}/>
                </CustomMaxWidthTooltip>
                &nbsp;
                {
                    !isAutoDistr
                    ? <Button className="auto-btn" variant="outlined" onClick={onClickAutoBtn}>
                        <AutoDistributionIcon/>&nbsp;DISTRIBUZIONE AUTOMATICA
                    </Button>
                    : <>
                        <Button className="auto-btn" variant="outlined" onClick={ () => { window.location.reload(); }}>
                            <UndoIcon/>&nbsp;ANNULLA
                        </Button>
                        &nbsp;
                        <Button className="auto-btn" variant="outlined" onClick={onClickAutoConfirmButton}>
                            <SaveOutlinedIcon/>&nbsp;CONFERMA DISTRIBUZIONE
                        </Button>
                    </>
                }
                
                { loading ? <CircleProgress /> :
                    <div className="component-options-wrapper">
                        {
                            aoAgents?.length
                            ? <SimpleTable
                                chiave            ={ 'AGENTS'  }
                                sTableDataType    ={ 'AGENTS'  }
                                aoRows            ={ aoAgents  }
                                aoCols            ={ aoHeaders }
                                oSortOptions      ={ { isSortable: true } }
                                oExportOption     ={ { sFileName: `Distribuzione Carichi` } }
                            />
                            : <div className="component-options-wrapper-alarmNoRecord">
                                <FolderOffIcon />&nbsp;Nessun risultato trovato
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
};

export default AgentsTraffickers;
