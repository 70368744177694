import React, { useState, useEffect, useContext, useCallback }   from 'react';
import { useDropzone }                              from 'react-dropzone';
import axios                                        from 'axios';
import Button                                       from '@mui/material/Button';

import './UploadFile.css';
import config                                       from '../../config.js';
import CircleProgress                               from '../../components/CircleProgress/CircleProgress.tsx';
import { SimpleTable }                              from '../../components/SimpleTable/SimpleTable.js';
import { PopupConfirm }                             from '../../components/PopupConfirm/PopupConfirm';
import { AuthContext }                              from '../../contexts/authContext.js';
import { AppContext }                               from '../../App.js';
import { AlertIcon, FolderOffIcon, UploadIcon }                from '../../assets/icons/icons.js';

 
const bDebug = config.DEBUG && false;

 
const UploadFile = (props) => {
    const auth = useContext(AuthContext);
    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);

    const [ loading, setLoading ] = useState(true)
         ,[ loadingUpload        ,setLoadingUpload          ] = useState(false)
         ,[ aoFileList           ,set_aoFileList            ] = useState([])
         ,[ aoFileListHeader     ,set_aoFileListHeader      ] = useState([])
         ,[ sPopupType           ,set_sPopupType            ] = useState('')
         
         ,[ uploadedFileName     ,setUploadedFileName       ] = useState("")
         ,[ originalFileName     ,setOriginalFileName       ] = useState("")

    const sDirectory = `in/dian/freewheel/`;
    const headers    = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth.sessionInfo.accessToken}` }

    
    const createPopupDialog = () => {

        const oPopupOptions = {
            'forceUpload': {
                 DialogTitle: <span className="dialog-title-licenses">Attenzione!</span>
                // ,Content: <></>
                // ,onClick: () => 
            }
        }[sPopupType] || {};

        return <PopupConfirm
            { ...{ oPopupOptions, sPopupType, set_sPopupType } }
        />

    };
    
    /* #region UtilsFunction */
    // Function per inviare un file su bucket
    const pushFile = async ({ url, file }) => {
        const options = {
            headers: {
                "Content-Type":                 "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000"
            }
        };
        return await axios.put(url, file, options);
    }
    
    // Function per ottenere la lista di file presenti su bucket
    const getFilesListFromBucket = async () => {
        setLoading(true);
        set_aoFileList([]);
        set_aoFileListHeader([]);
        try {
            const response = await axios.get( config.API_URL + '/get', { headers, params: { getListFileParam: true, sDirectory }} )
            if ( response && response.statusCode === 200 && response.data.asFiles ) {
                const aoFiles = response.data.asFiles.map( sFile => sFile = { FILE_NAME: sFile } );
                set_aoFileList(aoFiles);
                set_aoFileListHeader([
                    { name: 'FILE_NAME'      , title: 'File'   , width: 450 , isUniqueKeyForRow: true }
                ])
            } else {
                console.error('ERR 181: Failed GetFilesListFromBucket');
                set_oNotifyOptions({ message: `Impossibile recuperare la lista di file dal Bucket`, severity: 'error' }); 
            }
        } catch (err) {
            console.error('ERR 180: Failed GetFilesListFromBucket: ', err);
            set_oNotifyOptions({ message: `Impossibile recuperare la lista di file dal Bucket`, severity: 'error' }); 
        }
    }
    /* #endregion UtilsFunction */

    /* #region DropZone */
    const onDrop = useCallback(acceptedFiles => {
        setLoadingUpload(true);
        acceptedFiles.forEach( async(file) => {
            bDebug && console.log(file)
            if (file.type.split('/')[1] !== 'csv'){
                bDebug && console.log('file type is not csv');
                // !!! TODO Aggiungere SnackAlert
                return;
            }

            let response = '';
            let url      = `${config.API_URL}/get-url`;
            let params   = {
                // uploadSignedURL: true, // Ehi non mi serve, non sto usando la DIANget
                sDirectory,
                type: file.type,
                sFileName: file.name,
                bForceOverwrite: false // Probabilmente questo parametro andrà eliminato in quanto non richiameremo la lambda, la sSignedURL la restituiamo a prescindere
            };

            try{
                response = await axios.get( url, { headers, params } );
                bDebug && console.log(response);
                
                if ( response.status === 200 && response.data ) {
                    if ( response.data.sWarning ) {
                        /// !!! Se c'è il warning devo aprire un popup per chiedere all'tente se desidera sovrascrivere il file

                    } else if ( response.data.error ) {
                        throw new Error( response.data.error )

                    } else if ( response.data.sSignedURL ) {
                        response = pushFile({ url: response.data.sSignedURL, file });
                        bDebug && console.log(response);

                    }
                }
            } catch( error ) {
                console.error('ERR 182: Failed UploadFile', error);
                set_oNotifyOptions({ message: `Impossibile caricare il file su Bucket`, severity: 'error' });
            }

            // se tutto va a buon fine allora permetto all'utente di procedere
            // setOriginalFileName(originalName);
            // setUploadedFileName(fileName);
            setLoadingUpload(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const {
        /* acceptedFiles, */
        getRootProps,
        getInputProps,
        open,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({ onDrop, accept: {'text/csv':['.csv']},  multiple: false, maxFiles: 1 });
    
    /* #endregion DropZone */

    /* #region UseEffect */
        // Appena atterro sulla pagina estraggo la lista di file presenti su bucket
        useEffect( () => {
            getFilesListFromBucket();
            setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    /* #endregion UseEffect */

    return (
        <div className="component-container upload">
            {
                (loading || loadingUpload) && (
                <div className="">
                    <CircleProgress/>
                </div>
            )}
            { ( sPopupType !== '' ) && createPopupDialog() }
            <div className="component-card">
                <div className="component-options-wrapper">
                    <section className="section-layout main-content">
                        <div className='upload-section'>           
                            <div className="dropBox" {...getRootProps()}>
                                <input {...getInputProps()} />
                                {originalFileName === "" ? 
                                    <p className='dropBox-text'>Clicca per selezionare un file o trascinalo qui</p>
                                    :
                                    <p className='dropBox-text'>{originalFileName}</p>
                                }
                            </div>
                            <Button className="dianButton" variant="outlined" onClick={open} endIcon={<UploadIcon className='myIcon'/>} >
                                <span>Carica</span>
                            </Button>
                        </div>    
                    </section>
                </div>
                <div className="component-options-wrapper">
                    {
                        loading || loadingUpload ? <></> : <>{
                            uploadedFileName.length > 0 ? 
                                <SimpleTable
                                    chiave            ={ 'File Upload' }
                                    sTableDataType    ={ 'File' }
                                    aoRows            ={ aoFileList }
                                    aoCols            ={ aoFileListHeader }
                                />
                            : <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp; Nessun file presente su Bucket</div>
                        }</>
                    } 
                </div>
                
            </div>  
        </div>
    )
}

export default UploadFile;