import React, { useState, useEffect, useContext, createContext } from 'react';
import { Button
       , ButtonGroup }         from '@mui/material';
import './PaginationTool.css';

export default function PaginationTool({ oPaginationOption }) {
    // ____________ VARIABILI GLOBALI ____________
    const { fGetRecord, oPag } = oPaginationOption; 
    // const
    //     [ nTotRecord,       set_nTotRecord       ] = useState( oPag.nTotRecord   )
    //    ,[ nFirstRecordPage, set_nFirstRecordPage ] = useState( oPag.pFirstRecord )
    //    ,[ nRecordPerPage,   set_nRecordPerPage   ] = useState( oPag.pNumRecords  )
    // ;
    // console.log('oPag: ', oPag);
    let
        nTotRecord = oPag?.nTotRecord || 0,      
        nFirstRecordPage = oPag?.pFirstRecord || 1,
        nRecordPerPage = oPag?.pNumRecords || 100
    ;
    /* #region UtilFunction */
    const nCurrentPage = Math.ceil( nFirstRecordPage / ( nRecordPerPage || 1 ) );
    // const getCurrentPage = () => Math.ceil( nFirstRecordPage / ( nRecordPerPage || 1 ) );

    const fRangeRecord = ( pag ) => {
        let firstNum = ( nRecordPerPage * ( pag - 1 ) ) + 1;
        let lastNum  = ( nRecordPerPage * ( pag - 1 ) ) + nRecordPerPage;
        if ( lastNum > nTotRecord ) { lastNum = nTotRecord };
        return `${ firstNum } - ${ lastNum }`;
    };
    /* #endregion UtilFunction */
    
    /* #region SwitchFunction */
    const fPrevPage = async() => {
        let nPrevFirstRecord = nFirstRecordPage - nRecordPerPage;
        if ( nPrevFirstRecord > 0 ) {
            // set_nFirstRecordPage( nPrevFirstRecord );
            await fGetRecord({ oPag: { ...oPag, pFirstRecord: nPrevFirstRecord }});
        }
    };

    const fSelectPage = async( pagina ) => {
        if ( pagina !== nCurrentPage ) {
            let nSelectFirstRecord = ( nRecordPerPage * ( pagina - 1 ) ) + 1;
            if ( nSelectFirstRecord > 0 && nSelectFirstRecord <= nTotRecord ) {
                // set_nFirstRecordPage( nSelectFirstRecord );
                await fGetRecord({ oPag: { ...oPag, pFirstRecord: nSelectFirstRecord }});
            }
        }
    };
    
    const fNextPage = async() => {
        let nNextFirstRecord = nFirstRecordPage + nRecordPerPage;
        if ( nNextFirstRecord <= nTotRecord ) {
            // set_nFirstRecordPage( nNextFirstRecord );
            await fGetRecord({ oPag: { ...oPag, pFirstRecord: nNextFirstRecord }});
        }  
    };
    /* #endregion SwitchFunction */

    /* #region CreationButton */
    const fAddPrevPageButton = () => {
        return <Button onClick={ fPrevPage } >{ '<' }</Button>;
    };

    const fAddSelectPageButton = ( pag ) => {
        return <Button key={ pag } className={ ( nCurrentPage === pag ) ? 'paginaCorrente' : '' } onClick={ () => fSelectPage(pag) } >{ fRangeRecord(pag) }</Button>;
    };

    const fAddNextPageButton = () => {
        return <Button onClick={ fNextPage } >{ '>' }</Button>;
    };

    const fButtonGroupSelectPage = ({ nStackPage, nLastPage }) => {
        let aButtonGroup = [];

        for ( let pag = nStackPage; pag <= nLastPage; pag++ ) {
            aButtonGroup.push(fAddSelectPageButton( pag ));
        }

        return aButtonGroup;
    };
    
    const paginazioneDopoEstrazione = () => {
        let nLastPage = Math.ceil( ( nTotRecord || 0 ) / ( nRecordPerPage || 1 ) ) || 1;
        // let nCurrentPage  = getCurrentPage();

        if ( nLastPage < 2 ) {
            return '';

        } else {
            if ( nLastPage <= 5 ) {
                return <ButtonGroup className='paginationButtonGroup'>
                        { fButtonGroupSelectPage({ nStackPage: 1, nLastPage }) }
                    </ButtonGroup>

            } else {

                if ( nCurrentPage <= 3 ) {
                    return <ButtonGroup className='paginationButtonGroup'>
                            { fButtonGroupSelectPage({ nStackPage: 1, nLastPage: 3 }) }
                            { fAddNextPageButton() }
                            { fAddSelectPageButton( nLastPage ) }
                        </ButtonGroup>
                } else if ( nCurrentPage >= ( nLastPage - 2 ) ) {
                    return <ButtonGroup className='paginationButtonGroup'>
                            { fAddSelectPageButton( 1 ) }
                            { fAddPrevPageButton() }
                            { fButtonGroupSelectPage({ nStackPage: nLastPage - 2, nLastPage }) }
                        </ButtonGroup>
                } else {
                    return <ButtonGroup className='paginationButtonGroup'>
                            { fAddSelectPageButton( 1 ) }
                            { fAddPrevPageButton() }
                            { fAddSelectPageButton( nCurrentPage ) }
                            { fAddNextPageButton() }
                            { fAddSelectPageButton( nLastPage ) }
                        </ButtonGroup>
                }
            }
        }
    };
    /* #region CreationButton */

    return oPag?.nTotRecord ? paginazioneDopoEstrazione() : <></>;
}



