import React, {useEffect, useState, useContext }                    from "react";
import { Routes, Route, Link, Navigate, useNavigate, useLocation }  from 'react-router-dom';
import axios                                                        from "axios";

import {  Button
        , CircularProgress
        , ClickAwayListener 
        , Divider
        , IconButton
        , ListItemIcon 
        , ListItemText
        , Menu
        , MenuItem
        , MenuList 
        , Popover
        , Tooltip }                     from '@mui/material';
        
import Adjustment                       from './adjustment/Adjustment.js';
import AgentsTraffickers                from './agentsTraffickers/AgentsTraffickers';
import Allarmi                          from './allarmi/Allarmi.js';
import AllarmeDettaglio                 from './allarmi/AllarmeDettaglio.js';
import Anagrafiche                      from './anagrafiche/Anagrafiche.js';
import AnagraficheDettaglio             from './anagrafiche/AnagraficheDettaglio.js';
import AnagraficheStoricoForecast       from './anagrafiche/AnagraficheStoricoForecast.js';
import DashBoard                        from './dashboard/Dashboard.js';
import ErrorPage403                     from './errorPage/ErrorPage403.js';
import ErrorPage404                     from './errorPage/ErrorPage404.js';
import Forecast                         from './forecast/Forecast.js';
import Formati                          from './formati/Formati.js';
import Home                             from './home/Home';
import SellOrNot                        from './venduto_invenduto/SellOrNot.js';
import UploadFile                       from './uploadFile/UploadFile.js';
import Users                            from './users/Users.js';
import UserDetail                       from './users/UserDetail';
import ChangePassword                   from './users/ChangePassword.js';
import CreateUser                       from './users/CreateUser';
import TestIcons                        from '../assets/icons/TestIcons.js';
import './index.css'

import { AuthContext }                  from '../contexts/authContext';
import { AppContext }                   from '../App.js';
import { PopupConfirm }                 from '../components/PopupConfirm/PopupConfirm.js'
import config                           from '../config.js';
import utils                            from '../util/CommonUtilities';
import CircleProgress                   from '../components/CircleProgress/CircleProgress.tsx';
import Roobeek                          from '../assets/images/roobeek.png';
import {ReactComponent as SysCubeLogo}  from '../assets/images/systrategy_cube_logo.svg';
import {ReactComponent as SysWordLogo}  from '../assets/images/systrategy_word_logo.svg';

import {
     AccountCircleSolidIcon
    ,AdjustIcon
    ,DistribuzioneCarichiIcon
    ,FormatiIcon
    ,BellIcon
    ,CabinetIcon
    ,CancelIcon
    ,CreditCardIcon
    ,DashBoardIcon
    ,ForecastIcon
    ,GearIcon
    ,GroupIcon
    ,KeyIcon
    ,LogoutIcon
    ,NotificationImportantIcon
    ,UploadIcon
    ,SellOrNotIcon
    ,SettingsIcon
    ,TestIconIcon
}                                       from '../assets/icons/icons.js';
import Settings                         from './settings/Settings';

const bDebug = config.DEBUG && false;

const MainIndex = () => {
    
    const
         auth          = useContext(AuthContext)
        ,{
            set_oNotifyOptions, sConcatNamePage ,setsConcatNamePage, selectedLogo, selectedLogoOnlyText, dLastUpdate
        }                    = useContext(AppContext)
        ,navigate            = useNavigate()
        ,{ pathname }        = useLocation()
        ,bAdminUser          = ( localStorage.getItem('flagAdmin') || '' ) === 'Y'
        ,userEmail           = localStorage.getItem('userEmail') ? localStorage.getItem('userEmail') : 'Username'
        ,fullName            = localStorage.getItem('fullName')  ? localStorage.getItem('fullName')  : 'FullName'
        ,aMenuItemsToDisplay = (JSON.parse(localStorage.getItem('menuItemsToDisplay') || "[{}]") ).map( o => Object.keys(o) ).flat(Infinity)
        ,jumpRoute           = aMenuItemsToDisplay.includes('Allarmi') || bAdminUser ? 'allarmi' : 'home'
        ,headers             = {
            'Accept'        : 'application/json',
            'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
        }
        
        ,isDemo      = process.env.REACT_APP_ENV === 'demo'
        ,isLocalSole = process.env.REACT_APP_ENV === 'localsole'
        ,isProdSole  = process.env.REACT_APP_ENV === 'prodsole'
        ,isLocalDisc = process.env.REACT_APP_ENV === 'localdisc'
        ,isProdDisc  = process.env.REACT_APP_ENV === 'proddisc'
        ,isLocal     = isLocalSole || isProdDisc
        ,appName     =
                       isProdSole  ? 'DIS24'
                     : isProdDisc  ? 'Digital Analyzer'
                     : isLocalSole ? 'DIS24 Dev'
                     : isLocalDisc ? 'DIAN Dev'
                     : isDemo      ? 'DIAN demo'
                     : ''
        // Assegnazione Fittizia, a prescindere appena verrà rendirizzato un componente figlio esso rinominerà il namePage
        ,[ sCurrentSideElement,set_sCurrentSideElement] = useState('home')
        ,[ namePage          ,setNamePage           ] = useState('Home' )
        ,[ oLicenses         ,set_oLicenses         ] = useState({ TOTAL: 30 , USED: 0, UNUSED: 0 })
        ,[ loadingLicenses   ,set_LoadLicenses      ] = useState(false)
        ,[ loading           ,setLoading            ] = useState(false)
        ,[ sideHover         ,setSideHover          ] = useState(false)
     // ,[ openUserSettings  , setOpenUserSettings  ] = useState(false)
        ,[ anchorUserSetting ,setAnchorUserSetting  ] = useState(null)
        ,[ anchorSettings    ,setAnchorSettings     ] = useState(null)
        ,[ anchorNotify      ,setAnchorNotify       ] = useState(null)
        ,[ sPopupType        ,set_sPopupType        ] = useState('')
        ,[ dLastUpdateToView ,set_dLastUpdateToView ] = useState('')
        ,[ nIdInterval       ,set_nIdInterval       ] = useState('')
        ,[ nThersMessage     ,setnThersMessage      ] = useState(0)
        ,[ aoNotifyMessage   ,set_aoNotifyMessage   ] = useState([])
    ;
    

    const createPopupDialog = () => {

        const oPopupOptions = {
            'licenses': {
                 DialogTitle: <span className="dialog-title-licenses"><CreditCardIcon />Licenze Utenti</span>
                ,Content: 
                loadingLicenses 
                ?  <div className="myFlex"><div className='circularprogressWrapper'><CircularProgress color="inherit" /></div></div> 
                :
                    !(oLicenses||{}).TOTAL ? '' :
                    <div>
                        <div className="licenses-label">Ai fini del conteggio delle licenze
                        vengono inclusi i soli utenti attivi
                        ed esclusi gli eventuali utenti Systrategy
                        </div>
                        <div className="licenses-table">
                            <div><div>Licenze totali:           </div><div>{ oLicenses.TOTAL  }</div></div>
                            <div><div>Licenze utilizzate:       </div><div>{ oLicenses.USED   }</div></div>
                            <div><div>Licenze rimanenti:        </div><div>{ oLicenses.UNUSED }</div></div>
                        </div>
                    </div>
                ,hideFistButton:    true
                ,closeButtonLabel: 'chiudi'
            }
        }[sPopupType] || {};

        return <PopupConfirm
            { ...{ oPopupOptions, sPopupType, set_sPopupType } }
        />

    };

    /* #region Handler */
    const handleHover = (action) => {
        if(action === 'in'){
            setSideHover(true);
        }else{
            setSideHover(false);
        }
    }

    const handleOpen = ( event, target ) =>{
        switch ( target ) {
            case 'user-settings':
                setAnchorUserSetting(event.currentTarget);
                break;

            case 'notify':
                setnThersMessage(0);
                setAnchorNotify(event.currentTarget);
                break;                

            case 'settings':
                setAnchorSettings(event.currentTarget);
                break;

            default:
                bDebug && console.log('Sorry, somethink whent wrong!');
                break;
        }
    };

    const handleClose = (target) => {
        switch ( target ) {
            case 'user-settings':
                setAnchorUserSetting(null);
                break;

            case 'notify':
                setAnchorNotify(null);
                break;                

            case 'settings':
                setAnchorSettings(null);
                break;

            default:
                bDebug && console.log('Sorry, somethink whent wrong!');
                break;
        }
    };

    const handleClickNotify = ( notify ) => {
        // Setto la notifica come letta ( cookie letto e cancello mex dalla coda ) 
        // e nel caso di forecast renderizzo sulla pagina estraendo file da db
        handleClose('notify');
        document.cookie = notify.sCoockie;
        // dato che premendo sulla notifica ci si aspetta un rendirizzamento, 
        // potrebbe non essere necessario risettare il suo stato ( setmsgState() ) 
        // in quanto l'array di notifiche viene ricostruito ogni secondo
        // notify.setmsgState('read'); 
        navigate(notify.msgURL);
    }
    
    const handleCancelNotify = ( notify ) => {
        // !!! ToDo lanciare chiamata per cancellare messaggio dalla coda
        document.cookie = notify.sCancelCookie;
        // Necessario poi eliminare la notifica dall'array, 
        // la cosa più facile potrebbe essere riscatenare la creazione delle notifiche stesse
        getMessageCookie();
    }
    /* #endregion Handler */

    /* #region Navigate */
    const goToHome = () => {
        // if ( isLocalSole || isProdSole ) {
        //     navigate('/allarmi');
        // } else {
        //     navigate('/dashboard');
        // }
        navigate(`/${jumpRoute}`);
    };

    const logOut = () =>{
        handleClose();
        auth.signOut();
        navigate('/login', {replace: false});
    }

    const goToChangePassword = () => {
        // handleClose();
        navigate('/cambia-password');
    }
    /* #endregion Navigate */

    /* #region CallToDB */
    const getLicenses = async () => {
        set_LoadLicenses(true);

        if ( bAdminUser ) {
            try {
                // let aoLicenses = [{ TOTAL: 10, USED: 4, UNUSED: 6 }];
                let aoLicenses = await axios.get( config.API_URL + '/get', { headers, params : { "getLicensesParams": true } } );

                if ( aoLicenses?.status === 200 && aoLicenses?.data?.aoRecords ) {
                    bDebug && console.log('aoLicenses.data?.aoRecords?.length: ', aoLicenses.data?.aoRecords?.length);
                    let countLicencies = aoLicenses.data.aoRecords[0].COUNT_ACTIVE_USER
                    set_oLicenses(( countLicencies )
                        ? { TOTAL: 30, USED: countLicencies, UNUSED: (30 - countLicencies) }
                        : {}
                    );
                }
            } catch(err) {
                console.error(err);
                set_oNotifyOptions({
                     message: `Interrogazione fallita, impossibile ottenere il numero di Licenze da Database`
                    ,severity:'error'
                });
            }
        }
        
        set_LoadLicenses(false);
    }

    const loginToRoobeek = async() => {
        try{
            let url = `${config.API_URL}/link-to-roobeek`
            let params = {};
            let response = await axios.post(url, null, { headers, params });
            bDebug && console.log('response: ', response);
            
            if(response.status===200){
                let redirectUrl = response.data
                window.open(redirectUrl, '_blank');
            }
        }catch(err){
            console.error('err', err);
        }
    }
    /* #endregion CallToDB */

    /* #region CalloToAWS */
    const testRequestSqs = async() => {
        // console.log('io richiedo')
        setInterval(async ()=>{
            let response = await axios.get( config.API_URL + '/readonsqs', { headers, params : {} } );
            // console.log('Response testRequestSqs: ', response); 
            // let aResp = JSON.parse( response.data ); // Maybe
            if ( response.status === 200 && Array.isArray(response.data) && response.data?.length ){
                let defaultDateExpire = new Date();
                defaultDateExpire.setHours(defaultDateExpire.getHours() + 1); // !!! ToDo 
                // console.log(defaultDateExpire);

                const sCookies = document.cookie; 

                response.data?.forEach(messaggio => {
                    // console.log('Messaggio: ', messaggio);
                    let { Body: msgBody, MessageId } = messaggio;
                    // Controllo che il messaggio non sia già stato salvato nei cookie
                    if ( !sCookies.includes( MessageId /* maybe only MessageId ? */ )) {
                        // console.log(msgBody.split('§'));
                        // let aMsgBody = msgBody.split('§');
                        // let userCreator = msgBody[0];
                        // let expireMsgTime = msgBody[4] ? new Date(msgBody[4]) : defaultDateExpire;
                        // let expireMsgTime = defaultDateExpire;
                        document.cookie = `${ MessageId }=${ msgBody }§${ defaultDateExpire.toUTCString() }§unread; expires=${defaultDateExpire.toUTCString()}; path=/`
    
                        setnThersMessage(1);
                    }
                });
                getMessageCookie();
            }
        },100000)
        
    }
    /* #endregion CalloToAWS */

    /* #region UtilsFunction */
    const formatExecution       = (val) => utils.formatDateTime(
         val
        ,{ input: 'YYYYMMDDHHmmss', output: 'D MMM YYYY HH:mm', fromNow: true, useTimezone: true }
    );

    const getMessageCookie = () => {
        // console.log('getMessageCookie')
        // console.log('Cookie: ', document.cookie);
        // Bisogna inserire la condizione per Raccogliere solamente i cookie interessati
        let asCookie = document.cookie.split(';').filter( sCookie => sCookie.includes(`Forecast`));
        let aoMessage = [];
        for ( let i = 0; i < asCookie; i++ ) {
            aoMessage.unshift( utils.oMessage( asCookie[i] ))
        }
        // console.log('aoMessage: ',aoMessage);
        set_aoNotifyMessage( aoMessage );
        // set_aoNotifyMessage( aCookie.forEach( cookie => new utils.oMessage(cookie)));
    }
    /* #endregion UtilsFunction */
    
    // useEffect(() => {
    // testRequestSqs();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[]);

    useEffect(() => {
        bDebug && console.log('nIdInterval: ', nIdInterval);
        bDebug && console.log('dLastUpdate: ', dLastUpdate);
        clearInterval(nIdInterval);
        set_dLastUpdateToView(formatExecution(dLastUpdate));
        
        if ( dLastUpdate ) {
            set_nIdInterval(setInterval( () => {
                set_dLastUpdateToView(formatExecution(dLastUpdate))
            }, 60000 ));
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dLastUpdate ]);

    useEffect( () => {
        let sNamePage   = 'Pagina non trovata';
        let sActualPath = utils.formatTitleHeader(pathname);
        let sDocTitle   = appName + ' | 404';
        
        if ( sActualPath === 'errorPage403' ) {
            
            sNamePage = 'Accesso Negato';
            sDocTitle = appName + ' | 403';

        } else {
            
            if ( sActualPath ) {
                const [ t_last, t_last_1 ] = sActualPath.split(' | ').reverse();
                sNamePage = sActualPath;
                sDocTitle = appName + ( !t_last_1 ? '' : ` | ${ t_last_1 }` ) + ( !t_last ? '' : ` | ${ t_last }` );
                set_sCurrentSideElement((t_last_1 || t_last));
            } else {
                bDebug && console.log("Titolo non trovato");
            }
            
        }
        document.title = sDocTitle.toUpperCase();
        setNamePage(sNamePage.toUpperCase());
        setsConcatNamePage('');
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pathname ]);

    // Questa useEffect servirà per rendere più preciso il render di ErrorPage 403/404
    // inoltre renderà più dinamico e ripulirà il codice.
    // Cosa manca? chiamata db per recuperare array di pagine totali (solo la prima parte di Pathname),
    // bisognerà inoltre ripulire il routing dalle condizioni
    /* useEffect(() => {
        console.log(namePage);
    // },[namePage]) */

    // console.log('sideElement: ', sCurrentSideElement);
    
    return (
        <div className="main-background">
            {
                loading ? <CircleProgress /> : <>
                    
    <aside onMouseEnter={() => handleHover('in')} onMouseLeave={() => handleHover('out')}>
        
        <div className="logo-and-menu-container">
            <div className="side-menu-logo">
                <img src={ selectedLogo } alt="" className="logoImage"/>
                {
                    ( sideHover && ( isDemo || isProdDisc || isLocalDisc ))
                    && <img src={ selectedLogoOnlyText } alt="Digital Analyzer" className="logoText"/>
                }
            </div>
            <ul>
                {
                    ( isLocal && bAdminUser )
                        /* || !aMenuItemsToDisplay.includes('Allarmi')) */ // Questa condizione è obsoleta in quanto ora abbiamo la rotta Home
                         ?
                    <li>
                        <Link to="/dashboard" className="linkMenu">
                            <div className={`side-menu-options ${ (sCurrentSideElement === 'dashboard') && 'selected-menu-option' }`}>
                                <DashBoardIcon/>
                                {sideHover && <>&nbsp;&nbsp;Dashboard</>}
                            </div>
                        </Link>
                    </li>
                    : <></>
                }
                {
                    ( isDemo || bAdminUser || aMenuItemsToDisplay.includes('Allarmi')) ? 
                    <li>
                        <Link to="/allarmi" className="linkMenu" >
                            <div className={`side-menu-options ${ 
                                    ((sCurrentSideElement === 'allarmi') || sCurrentSideElement.includes('allarme')) && 'selected-menu-option' }`}>
                                <NotificationImportantIcon/>
                                {sideHover && <>&nbsp;&nbsp;Allarmi</>}
                            </div>
                        </Link>
                    </li>
                    : <></>
                }
                {
                    ( isDemo || bAdminUser || aMenuItemsToDisplay.includes('Anagrafiche') ) ?
                    <li>
                        <Link to="/anagrafiche" className="linkMenu">
                            <div className={`side-menu-options ${ 
                                    ((sCurrentSideElement === 'anagrafiche') || (sCurrentSideElement === 'anagrafica')) && 'selected-menu-option' }`}>
                                <CabinetIcon/>
                                {sideHover && <>&nbsp;&nbsp;Anagrafiche</>}
                            </div>
                        </Link>
                    </li>
                    : <></>
                }
                {
                    ( isLocal && bAdminUser ) ?
                    <li>
                        <Link to="/forecast" className="linkMenu">
                            <div className={`side-menu-options ${ (sCurrentSideElement === 'forecast') && 'selected-menu-option' }`}>
                                <ForecastIcon/>
                                {sideHover && <>&nbsp;&nbsp;Forecast</>}
                            </div>
                        </Link>
                    </li>
                    : <></>
                }
                {
                    (( isLocalSole || isProdSole ) && ( bAdminUser || aMenuItemsToDisplay.includes('Adjustment'))) ?
                    <li>
                        <Link to="/adjustment" className="linkMenu">
                            <div className={`side-menu-options ${ (sCurrentSideElement === 'adjustment') && 'selected-menu-option' }`}>
                            <AdjustIcon/>
                                {sideHover && <>&nbsp;&nbsp;Adjustment</>}
                            </div>
                        </Link>
                    </li>
                    : <></>
                }
                {
                    ( isLocal && bAdminUser ) ?
                    <li>
                        <Link to="/carica-file" className={`linkMenu`}>
                            <div className={`side-menu-options ${ (sCurrentSideElement === 'carica file') && 'selected-menu-option' }`}>
                                <UploadIcon/>
                                {sideHover && <>&nbsp;&nbsp;Carica File</>}
                            </div>
                        </Link>
                    </li>
                    : <></>
                }
                {
                    ( ( isDemo || ( isLocalSole || isProdSole ) ) && ( bAdminUser || aMenuItemsToDisplay.includes('Report Venduto Invenduto'))) ?  // && !isProdDisc
                    <li>
                        {/* <Link to="/venduto-invenduto" className={`linkMenu`}> */}
                        <Link to="/report-venduto-invenduto" className={`linkMenu`}>
                            {/* <div className={`side-menu-options ${ (sCurrentSideElement === 'Venduto/Invenduto') && 'selected-menu-option' }`}> */}
                            <div className={`side-menu-options ${ (sCurrentSideElement === 'report venduto invenduto') && 'selected-menu-option' }`}>
                                <SellOrNotIcon/>
                                {sideHover && <>&nbsp;&nbsp;<span>Report Venduto</span></>}
                            </div>
                        </Link>
                    </li>
                                : <></>
                }
                {
                    (( isLocalSole || isProdSole ) && ( bAdminUser || aMenuItemsToDisplay.includes('Distribuzione Carichi'))) ?  // && !isProdDisc
                    <li>
                        <Link to="/distribuzione-carichi" className={`linkMenu`}>
                            <div className={`side-menu-options ${ (sCurrentSideElement === 'distribuzione carichi') && 'selected-menu-option' }`}>
                                <DistribuzioneCarichiIcon/>
                                {sideHover && <>&nbsp;&nbsp;<span>Distribuzione Carichi</span></>}
                            </div>
                        </Link>
                    </li>
                                : <></>
                }
                {
                    (( isLocalSole || isProdSole ) && ( bAdminUser || aMenuItemsToDisplay.includes('Formati'))) ?  // && !isProdDisc
                    <li>
                        <Link to="/formati" className={`linkMenu`}>
                            <div className={`side-menu-options ${ (sCurrentSideElement === 'formati') && 'selected-menu-option' }`}>
                                <FormatiIcon/>
                                {sideHover && <>&nbsp;&nbsp;Formati</>}
                            </div>
                        </Link>
                    </li>
                                : <></>
                }
                {
                    bAdminUser  ?  // && !isProdDisc
                    <li>
                        <Link to="/utenti" className={`linkMenu`}>
                            <div className={`side-menu-options ${ (sCurrentSideElement === 'utenti') && 'selected-menu-option' }`}>
                                <GroupIcon/>
                                {sideHover && <>&nbsp;&nbsp;Utenti</>}
                            </div>
                        </Link>
                    </li>
                    : <></>
                }
                {
                    isLocal && bAdminUser  ?
                    <li>
                        <Link to="/settings" className={`linkMenu`}>
                            <div className={`side-menu-options ${ (sCurrentSideElement === 'settings') && 'selected-menu-option' }`}>
                                <SettingsIcon/>
                                {sideHover && <>&nbsp;&nbsp;Settings</>}
                            </div>
                        </Link>
                    </li>
                    : <></>
                }
                {
                    ( isLocal && bAdminUser ) ?
                    <li>
                        <Link to="/icone" className={`linkMenu`}>
                            <div className={`side-menu-options ${ (sCurrentSideElement === 'icone') && 'selected-menu-option' }`}>
                                <TestIconIcon/>
                                {sideHover && <>&nbsp;&nbsp;Icone</>}
                            </div>
                        </Link>
                    </li>
                    : <></>
                }
                {
                    ( isLocal || isProdSole ) ?
                    <li>
                        <div className="roobeekPath side-menu-options" onClick={loginToRoobeek}>
                            <img src={Roobeek} alt="Roobeek" title="Roobeek" className="logoRoobeek" />&nbsp;&nbsp;
                            {sideHover && 'Roobeek'}
                        </div>
                    </li>
                    : <></>
                }
            </ul>
        </div>
        
        <a href='https://www.systrategy.it/' target='_blank' rel="noreferrer" className="logo-container roobeekPath side-menu-options">
            <div style={{width: '30%'}}><SysCubeLogo style={{ paddingLeft:'10px'}}/></div>
            <div className='logo-wrapper' style={{width: '80%' }}>{sideHover && (<SysWordLogo/>)}</div>
        </a>
        
    </aside>
                    
    <div className="main-content">
        <div className="header">
            <div className="header-container">
                
                <div>
                    <span className="app-name cursor-pointer" onClick={goToHome}>{ appName }</span>
                    <span className="app-name cursor-default"> | {namePage} { sConcatNamePage ? ` | ${ sConcatNamePage }`: '' }</span>
                    { dLastUpdateToView ? <span className="text-grey cursor-default">&nbsp;&nbsp;&nbsp;{dLastUpdateToView}</span> : <></> }
                </div>
                
                <div className="header-container">
                    
                    { bAdminUser && <div>
                        <Tooltip title="Impostazioni Applicazione">
                            <IconButton type="button" onClick={ (event) => handleOpen( event, 'settings' ) }>
                                <GearIcon className={ `textBlack bigIcon ${ Boolean(anchorSettings) ? 'openMenu' : ''}`}/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            className = "menu-settings"
                            anchorEl  = {anchorSettings}
                            open      = {Boolean(anchorSettings)}
                            onClose   = {() => handleClose('settings')}
                            style     = {{ marginTop: 5, left: -8 }}
                            keepMounted 
                        >
                            <MenuItem className="dropdown-user-settings" onClick={ ()=> { getLicenses(); set_sPopupType('licenses'); }}>
                                <div className='menu-icon-container'>
                                    <CreditCardIcon />
                                </div>
                                &nbsp;&nbsp;&nbsp;Licenze
                            </MenuItem>
                        </Menu>
                    </div> }&nbsp;
                    
                    { isLocal && <div>
                        <Tooltip title={`Notifiche: ${aoNotifyMessage.length}`}>
                            <IconButton 
                              id            =  "notify-button"
                              aria-controls = { Boolean(anchorNotify) ? 'notify-menu' : undefined }
                              aria-haspopup = "true"
                              aria-expanded = { Boolean(anchorNotify) ? 'true' : undefined }
                              type          = "button" 
                              onClick       = { (event) => handleOpen(event, 'notify') }
                            >
                                { (nThersMessage > 0) && <span className="green-dot"/> }
                                {/* { (aoNotifyMessage.length > 0) && <span className="grey-dot"/> } */}
                                <BellIcon className={ `textBlack bigIcon ${ Boolean(anchorNotify) ? 'openMenu' : ''}`}/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id            = "notify-menu"
                            MenuListProps = {{ 'aria-labelledby': 'notify-button' }}
                            anchorEl      = { anchorNotify }
                            open          = { Boolean(anchorNotify) }
                            onClose       = { () => handleClose('notify') }
                            className     = "menu-notifiche"
                            keepMounted
                        >
                            {/* Devo ciclare sui coockie e costruire un item per ogni notifica dell'utente */}
                            {
                                aoNotifyMessage.length ?
                                    aoNotifyMessage.map(( message, index ) => (<>
                                        <MenuItem
                                            key={ message.messageId || '' } 
                                            onClick={()=> { console.log(message); handleClickNotify(message); }} 
                                            className={ message.msgState }
                                        >
                                            <ListItemIcon>
                                                { message.msgIcon }
                                            </ListItemIcon> 
                                            <ListItemText>
                                                { message.messageBody || '' }
                                            </ListItemText> 
                                            <ListItemIcon>
                                                <CancelIcon onClick={()=> handleCancelNotify(message)} />
                                            </ListItemIcon> 
                                        </MenuItem> 
                                        { ( ( aoNotifyMessage.length > 1 ) && ( index < aoNotifyMessage.length ) ) ? <Divider sx={{ my: 0.5 }} /> : '' }
                                    </>))

                                : <MenuItem key={ 'Nessuna notifica' } value=''>
                                      Nessuna notifica
                                  </MenuItem>
                            }
                        </Menu>
                    </div>}
                    
                    <div className="user-settings-wrapper" onClick={(event) => handleOpen(event, 'user-settings')}>
                        <Tooltip title={userEmail}><span>{fullName}</span></Tooltip>&nbsp;
                        <AccountCircleSolidIcon className="cursor-pointer"/>
                        <Popover
                            open={Boolean(anchorUserSetting)}
                            anchorEl={anchorUserSetting}
                            onClose={() => handleClose('user-settings')}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <ClickAwayListener onClickAway={() => handleClose('user-settings')}>
                            <div className="dropdown-user-settings">
                                <Button className="dropdown-user-options cursor-pointer" onClick={goToChangePassword} startIcon={<KeyIcon/>}>Cambia Password</Button>
                                <Button className="dropdown-user-options cursor-pointer" onClick={logOut} startIcon={<LogoutIcon />}>Esci</Button>
                            </div>
                            </ClickAwayListener>
                        </Popover>
                    </div>
                    
                </div>
            </div>
        </div>
        
        { ( sPopupType !== '' ) && createPopupDialog() }

        <Routes>
            
            {   // Rotte in Test
                ( isLocal && bAdminUser ) ?
                    <>
                        <Route path="/dashboard"                    element={<DashBoard                 />}/>
                        <Route path="/carica-file"                  element={<UploadFile                />}/>
                        <Route path="/forecast"                     element={<Forecast                  />}/>
                        <Route path="/settings"                     element={<Settings                  />}/>
                        <Route path="/icone"                        element={<TestIcons                 />}/>
                    </>
                : <></>
            }

            { /* Rotte per cliente SOLE 24 ORE */
                ( isDemo || isLocalSole || isProdSole ) ?
                <>
                    { ( bAdminUser || aMenuItemsToDisplay.includes('Adjustment') ) &&
                        <>
                            <Route path="/adjustment"                       element={<Adjustment                />}/>
                            <Route path="/adjustment/:adjustId/dettaglio"   element={<Adjustment                />}/>
                        </>
                    }
                    { ( bAdminUser || aMenuItemsToDisplay.includes('Report Venduto Invenduto') ) &&
                            <Route path="/report-venduto-invenduto"         element={<SellOrNot                 />}/> }
                    { ( bAdminUser || aMenuItemsToDisplay.includes('Distribuzione Carichi') ) &&
                            <Route path="/distribuzione-carichi"            element={<AgentsTraffickers         />}/> }
                                        
                    { ( bAdminUser || aMenuItemsToDisplay.includes('Formati') ) &&
                            <Route path="/formati"                          element={<Formati/>}/> }
                </>
                : <></>
            }

            {/* Rotte disponibili a tutti gli Ambienti, ma solo ad alcuni utenti (admin o privilegio) */}
            
            { bAdminUser &&
                <>
                    <Route path="/utenti"                               element={<Users                     />}/>
                    <Route path="/crea-utente"                          element={<CreateUser                />}/>
                </>
            }

            { ( bAdminUser || aMenuItemsToDisplay.includes('Allarmi') ) &&
                <>
                    <Route path="/allarmi"                              element={<Allarmi                   />}/>
                    <Route path="/allarme/:id"                          element={<AllarmeDettaglio          />}/>
                    <Route path="/allarme/:id/:idDettaglio/:dettaglio"  element={<AllarmeDettaglio          />}/>
                </> 
            }
            
            { ( bAdminUser || aMenuItemsToDisplay.includes('Anagrafiche') ) &&
                <>
                    <Route path="/anagrafiche"                                                                 element={<Anagrafiche                />}/>
                    <Route path="/anagrafica/:account/:accountcode/:dettaglio"                                 element={<AnagraficheDettaglio       />}/>
                    <Route path="/anagrafica/:account/:accountcode/:dettaglio/:idDettaglio"                    element={<AnagraficheDettaglio       />}/>
                {/* <Route path="/anagrafica/:account/:accountcode/:dettaglio/:idDettaglio/:origin"            element={<AnagraficheDettaglio       />}/> // Rotta nascosta perchè l'origin è stato inserito nella urlParam */}
                    <Route path="/anagrafica/:account/:accountcode/:dettaglio/:id/dettaglio"                   element={<AnagraficheStoricoForecast />}/>
                    <Route path="/anagrafica/:account/:accountcode/:dettaglio/:id/dettaglio/:isForecastNotify" element={<AnagraficheStoricoForecast />}/>
                </>
            }
            
            {/* Rotte disponibili a tutti gli Ambienti e a tutti gli Utenti  */}
                    <Route path="/home"                                         element={<Home                          />}/>
                    <Route path="/cambia-password"                              element={<ChangePassword                />}/>
                    <Route path="/"                                             element={<Navigate to={`/${jumpRoute}`} />}/>
                    <Route path=""                                              element={<Navigate to={`/${jumpRoute}`} />}/>
                    {/* ErrorPage */}
                    <Route path="/errorPage403"                                 element={<ErrorPage403                  />}/>
                    <Route path="/*"                                            element={<ErrorPage404                  />}/>
            
        </Routes>
                        
    </div>
                    
                </>
            }
        </div>
    )

};

export default MainIndex;