import React, {
      useState
    , useEffect
    , useContext
}                                   from 'react';
import axios                        from 'axios';
import moment                       from 'moment';

import './Forecast.css';
import config                       from '../../config.js';
import utils                        from '../../util/CommonUtilities';
import { AuthContext }              from '../../contexts/authContext';
import { AppContext }               from '../../App.js';
import { SimpleTable }              from '../../components/SimpleTable/SimpleTable';
import Graph                        from '../../components/Graph/Graph';
import { PopupConfirm }             from '../../components/PopupConfirm/PopupConfirm';
import {
      CustomCssTextField
    , CustomCssTab 
    , CustomCssTabList
}                                   from '../../components/CustomCSS/CustomCSS.tsx';
import CircleProgress               from '../../components/CircleProgress/CircleProgress.tsx';

import Box                          from '@mui/material/Box';
import TabContext                   from '@mui/lab/TabContext';
import TabPanel                     from '@mui/lab/TabPanel';
import { LocalizationProvider }     from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment }            from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker }               from '@mui/x-date-pickers/DatePicker';
import InputAdornment               from '@mui/material/InputAdornment';
import Autocomplete                 from '@mui/material/Autocomplete';
import FormControl                  from '@mui/material/FormControl';
import Tooltip                      from '@mui/material/Tooltip';
import IconButton                   from "@mui/material/IconButton";

import {
         FolderOffIcon
       , SaveOutlinedIcon
       , SearchIcon
}                                   from '../../assets/icons/icons.js';


// import CancelPresentationIcon    from '@mui/icons-material/CancelPresentation';
// import LeaderboardIcon           from '@mui/icons-material/LeaderboardOutlined';
// import DoneAllIcon               from '@mui/icons-material/DoneAll';
// import RemoveDoneIcon            from '@mui/icons-material/RemoveDone';

const bDebug = config.DEBUG && true;

const aoValuesSign = [{ value: '+' }, { value: '-' } ];
const aoValuesType = [{ value: '%'},  { value: '#' } ];
const maxAdjustDiffDates = 10;

const Forecast = (props) => {
    
    const auth = useContext(AuthContext);
    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);
    const headers = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };

    const
         sGAMurl                 = config.GAM_URL
        ,formatNum0dec           = ( val ) => utils.formatNumberWithOptions(val, { nOuputDecimals: 0 })
        ,formatDate              = ( val ) => moment(val, 'YYYYMMDD').format('D MMM YYYY')
        ,formatZero            = (val) => val === 0 ? 'zero' : ''
        // ,formatGoToSelectAdUnit  = ( val, oRow ) => <div className='svgIconForecast' title='Vedi AD Units' onClick={ () => { fGoToSelectAdUnit({ AD_UNIT_ID: oRow.AD_UNIT_ID, AD_UNIT_NAME: oRow.AD_UNIT_NAME }) }}><DoubleArrowRightIcon /></div>
        // ,formatReturnToPla       = () => <div className='svgIconForecast' title='Ritorna al dettaglio Placement' onClick={ () => { fReturnToPlacement(); }}><DoubleArrowLeftIcon /></div>
        ,formatAdjustVal         = ( val, oRow ) => <span className={ val > 0 ? 'textGreen' : val < 0 ? 'textRed' : '' }>{ `${ val > 0 ? '+' :'' }${val} ${ oRow.ADJ_TYPE === 'P' ? '%' : '' }` }</span>
        ,formatAdUnitLink        = ( val ) => <a href={sGAMurl+`#inventory/ad_unit/detail/ad_unit_id=${ val }`}     target='_blank' className='myExternalLinkID' rel='noreferrer'>{val}</a>
     // ,formatRemoveAdjust      = ( val ) => <div className='svgIconForecast' title='Rimuovi Adjustment' onClick={ () => foPopupRemoveAdjust() }><CancelPresentationIcon /></div>
     
    ;
    /* #region Dichiarazioni */
    const
          [ loading ,setLoading ] = useState(false)
         ,[ bFindingAnythinkLoad ,set_bFindingAnythinkLoad ] = useState(false) // State per indicare se si sta attendendo una risposta dal DB, necessario per controllare l'attivazione del bottone 'FindInLine' e <CircularProgress />
         ,[ isCallToDB           ,set_isCallToDB           ] = useState(false) // questo state non indica che stiamo facendo la chiamata, ma che la chiamata ha già risposto
         ,[ onReload             ,set_onReload             ] = useState(null) // State per triggerare il lancio della chiamata
         ,[ bShowResult,          set_bShowResult          ] = useState(false) // state per Indicare se bisogna mostrare la tabella
         ,[ nValueTab,            set_nValueTab            ] = useState('2') // State per indicare la Tab da visualizzare
         ,[ bChangeTab,           set_bChangeTab           ] = useState(null)

         ,[ bFindInLine,          set_bFindInLine          ] = useState(false) // State per indicare se la ricerca avviene ad inizio stringa o in mezzo
         ,[ aosPlacement,         set_aosPlacement         ] = useState([])
         ,[ aosAdUnit,            set_aosAdUnit            ] = useState([])
         ,[ oPlacementToAdjust,           set_oPlacementToAdjust           ] = useState(null) // State per contenere l'oggetto Placement a cui assegnare l'Adjust
         ,[ sPlacementToAdjustInputValue, set_sPlacementToAdjustInputValue ] = useState('') // State per contenere la stringa per ricercare il Placement
         ,[ oAdUnitToAdjust,              set_oAdUnitToAdjust              ] = useState(null) // State per contenere l'oggetto AdUnit a cui assegnare l'Adjust
         ,[ sAdUnitName                 ,set_sAdUnitName    ] = useState('') // State per contenere la stringa per ricercare l'AdUnit
         
         ,[ aoAdjustmentsPlacement,        set_aoAdjustmentsPlacement        ] = useState([]) // State per contenere l'elenco di adjustment da visualizzare in tabella
         ,[ aoAdjustmentsPlacementHeaders, set_aoAdjustmentsPlacementHeaders ] = useState([])
         ,[ aoAdjustmentsAdUnit,           set_aoAdjustmentsAdUnit           ] = useState([]) // State per contenere l'elenco di Adjustment del'AdUnit selezionata appartenente al Pla selezionato
         ,[ aoAdjustmentsAdUnitHeaders,    set_aoAdjustmentsAdUnitHeaders    ] = useState([])

         ,[ dDataInizio,          set_dDataInizio          ] = useState(moment())
         ,[ dDataFine,            set_dDataFine            ] = useState(moment())
         ,[ nValueAdjust,         set_nValueAdjust         ] = useState('')
         ,[ sValueSign,           set_sValueSign           ] = useState('+')
         ,[ sValueType,           set_sValueType           ] = useState('%')
         ,[ bSelectSignOnFocus,   set_bSelectSignOnFocus   ] = useState(false) // State per scatenare l'aggiunta di classe al TextFieldSign
         ,[ bSelectTypeOnFocus,   set_bSelectTypeOnFocus   ] = useState(false) // State per scatenare l'aggiunta di classe al TextFieldValue
         ,[ sPopupType,           set_sPopupType           ] = useState('')  // State per condizionare l'apertura del Popup di conferma
         ,[ sModifyTarget,        set_sModifyTarget        ] = useState('') // State per indicare di quale riga (corrispondente all'id) si vuole modificare l'adjustment
         ,[ vModifyContent,       set_vModifyContent       ] = useState(null) // State che indica il valore di adjustment modificato
         ,[ sGraphEnabled,        set_sGraphEnabled        ] = useState('') // State per Identificare se si vuole visualizzare il Grafico a Linee ('line'), a Barre ('bar') oppure la tabella ('')
    ;
    const aoAdjustsHeaders =
        [
             { name: 'adunitId'                          ,title: 'AD Unit ID' ,width: 120  ,isNum: true  ,format: formatAdUnitLink ,isUniqueKeyForRow: true ,filterOriginalValue: true }
            ,{ name: 'giorno'                            ,title: 'Data'       ,width: 120  ,isNum: true  ,format: formatDate ,isUniqueKeyForRow: true  }
            ,( bDebug && {...{ name: 'adjustId'  ,group: 'Adjustments'   ,title: 'ID'         ,width: 60   ,isNum: true  }})
            ,( bDebug && {...{ name: 'name'      ,group: 'Adjustments'   ,title: 'Etichetta'  ,width: 300  }})
            ,{ name: 'value'        ,title: 'Valore'     ,width: 60   ,isNum: true  }
        ]
    ;
    /* #endregion */

    const handleChangeTab = ( event, newValue ) => {
        set_bShowResult(false);
        set_nValueTab(newValue);
    };

    const fGoToSelectAdUnit = ({ AD_UNIT_ID, AD_UNIT_NAME }) => {
        set_bShowResult(false);
        set_oAdUnitToAdjust({ AD_UNIT_ID, AD_UNIT_NAME });
        set_nValueTab('2');
        set_bChangeTab(!bChangeTab);
    };

    const fReturnToPlacement = () => {
        set_bShowResult(false);
        set_nValueTab('1');
        set_bChangeTab(!bChangeTab);
    };

    // Da decidere se scatenare immediatamente il caricamento della tabella al cambio di TAB
    useEffect(() => {
        if ( bChangeTab !== null ) {
            findAdjustForDate()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ bChangeTab ]);

    const settingGraph = (field) => {
        if ( field !== sGraphEnabled ) {
            set_sGraphEnabled( field );
        }
    };

    // UseEffect per controllare il cambio delle Date
    useEffect(() => {
        bDebug && console.log('Dentro UseEffect dDataInizio ', dDataInizio.format('DD/MM/YYYY') );
        if ( dDataInizio && dDataInizio.isValid() ) {
            bDebug && console.log( `${dDataInizio} ${dDataInizio.isValid()}` );
            let bIsMin = (dDataInizio || moment()).format('YYYYMMDD') <= ( dDataFine || moment() ).format('YYYYMMDD');
            if ( !bIsMin ) {
                set_dDataFine(moment(dDataInizio));
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ dDataInizio ]);
    
    useEffect(() => {
        bDebug && console.log('Dentro UseEffect dDataFine ', dDataFine.format('DD/MM/YYYY') );
        if ( dDataFine && dDataFine.isValid() ) {
            bDebug && console.log( `${dDataFine} ${dDataFine.isValid()}` );
            let bIsMag = (dDataFine || moment()).format('YYYYMMDD') >= (dDataInizio || moment()).format('YYYYMMDD');
            if ( !bIsMag ) {
                set_dDataInizio(moment(dDataFine));
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ dDataFine ]);

    const handleDate = () => {
        let diffDate = dDataFine?.diff(dDataInizio || moment(), 'days');
        bDebug && console.log('DiffDate: ', dDataFine?.diff(dDataInizio || moment(), 'days'));
        return diffDate <= maxAdjustDiffDates;
    };

    // Function per controllare che nel campo ValueAdjust vengano inseriti solamente valori numerici senza segno
    const handleChangeValueAdjust = (value) => {
        bDebug && console.log(value);
        bDebug && console.log(typeof value);
        const regEx = /^[0-9]*$/

        bDebug && console.log(regEx.test(value));
        if ( regEx.test(value) || value === '' ) {
            set_nValueAdjust(value)
        }
    };


    const clearAdjParam = () => {
        set_nValueAdjust('');
        set_sValueSign('+');
        set_sPopupType('');
    };

    /* #region PopupConfirmAddAdjust */
    const fAddAdjust = async () => {
        bDebug && console.log('FAddAdjust');
        setLoading(true);

        try {
            let params = { modAdjustPlacement: true
                         , pPlacementId: oPlacementToAdjust.PLACEMENT_ID
                         , pDataInizio: dDataInizio.format('YYYYMMDD')
                         , pDataFine: dDataFine.format('YYYYMMDD')
                         , pAdjType: sValueType === '%' ? 'P' : 'V'
                         , pAdjVal: `${ sValueSign + nValueAdjust }` } // Devo passare l'ID del Placement
            const response = await axios.get( config.API_URL + '/get', { headers, params } );
            if ( response && ( response.status === 200 ) && (response?.data?.nRetVal === 1 ) ) {
                set_oNotifyOptions({ message: 'Adjustment inserita con successo', severity:'success', timeout: 2 });
                findAdjustForDate();
                // set_sModifyTarget(sPlacementToAdjust);
            } else {
                console.error(`Errore ${ response?.data?.vErrorMessageDesc }`);
                set_oNotifyOptions({ message: `Errore ${ response?.data?.vErrorMessageDesc }`, severity:'error' });
                setLoading(false);
            }
        } catch ( err ) {
            console.error('ERR 151: Failed AddAdjust', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            setLoading(false);
        }
        clearAdjParam();
    };

    const oPopupAddAdjustPlacement = {
         DialogTitle: `Confermi di voler inserire il seguente Adjustment`
        ,Content: <div style={{ width: 'fit-content'}}>
                    <table className='tablePopup'>
                      <tbody>
                        <tr>
                            <td>Placement:</td>
                            <td className='myTruncText'>{ oPlacementToAdjust?.PLACEMENT_NAME }</td>
                        </tr>
                        <tr>
                            <td>Data Inizio:</td>
                            <td>{ ( dDataInizio || moment() ).format('DD/MM/YYYY') }</td>
                        </tr>
                        <tr>
                            <td>Data Fine:</td>
                            <td>{ ( dDataFine || moment() ).format('DD/MM/YYYY') }</td>
                        </tr>
                        <tr>
                            <td>Valore:</td>
                            <td>{`${ sValueSign }${ nValueAdjust }${sValueType === '%' ? '%' : ''}`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
        ,onClick:               fAddAdjust
        ,onCancel:              clearAdjParam
        ,firstButtonLabel:      'conferma'
        ,classFirstButton:      'popupConfirm'
        ,classCloseButton:      'popupAnnul'
    };
    
    const oPopupAddAdjustAdUnit = {
         DialogTitle: `Confermi di voler inserire il seguente Adjustment`
        ,Content: <div style={{ width: 'fit-content'}}>
                    <table className='tablePopup'>
                      <tbody>
                        <tr>
                            <td>AdUnit:</td>
                            <td className='myTruncText'>{ oAdUnitToAdjust?.AD_UNIT_NAME }</td>
                        </tr>
                        <tr>
                            <td>Data Inizio:</td>
                            <td>{ ( dDataInizio || moment() ).format('DD/MM/YYYY') }</td>
                        </tr>
                        <tr>
                            <td>Data Fine:</td>
                            <td>{ ( dDataFine || moment() ).format('DD/MM/YYYY') }</td>
                        </tr>
                        <tr>
                            <td>Valore:</td>
                            <td>{`${ sValueSign }${ nValueAdjust }${sValueType === '%' ? '%' : ''}`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
        ,onClick:               fAddAdjust
        ,onCancel:              clearAdjParam
        ,firstButtonLabel:      'conferma'
        ,classFirstButton:      'popupConfirm'
        ,classCloseButton:      'popupAnnul'
    };

    const foPopupAddAdjust = () => {
        bDebug && console.log('FoPopupAddAdjust');
        if ( oPlacementToAdjust && nValueAdjust ) {
            if ( handleDate() ) {
                if ( sValueSign === '-' && sValueType === '%' && (+nValueAdjust >= 100) ) {
                    set_oNotifyOptions({ message: `Invalid Adjustment Value`, severity:'error', timeout: 5 });
                } else {
                    if ( nValueTab === '1' ) {
                        set_sPopupType('AddAdjustPlacement');
                    } else {
                        if ( oAdUnitToAdjust ) {
                            set_sPopupType('AddAdjustAdUnit')
                        } else {
                            set_oNotifyOptions({ message: `E' necessario selezionare una Ad Unit.`, severity:'error', timeout: 5 });
                        }
                    }
                }
            } else {
                set_oNotifyOptions({ message: `Il periodo selezionato è troppo ampio.`, severity:'error', timeout: 5 });
            }
        } else {
            set_oNotifyOptions({ message: `E' necessario riempire tutti i campi.`, severity:'error', timeout: 5 });
        };
    };
    /* #endregion */

    /* #region PopupConfirmRemoveAdjust */
    const fRemoveAdjust = async () => {
        bDebug && console.log('fRemoveAdjust');
        setLoading(true);
        try {
            let params = { }
            const response = await axios.get( config.API_URL + '/get', { headers, params } );
            if ( response && ( response.status === 200 ) && (response?.data?.nRetVal === 1 ) ) {
                set_oNotifyOptions({ message: 'Adjustment rimossa con successo', severity:'success', timeout: 2 });
                findAdjustForDate();
            } else {
                console.error(`Errore ${ response?.data?.vErrorMessageDesc }`);
                set_oNotifyOptions({ message: `Errore ${ response?.data?.vErrorMessageDesc }`, severity:'error' });
                setLoading(false);
            }
        } catch ( err ) {
            console.error('ERR 152: Failed', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            setLoading(false);
        }
        set_sPopupType('');
    };
    
    const oPopupRemoveAdjust = {
         DialogTitle: `Confermi di voler rimuovere il seguente Adjustment`
        ,DialogContentText:`Line Item: ${ 'sAdjustToRemove' } `
        ,Content: <div style={{ width: '200px'}}>
                    {`Data Inizio: ${ ( dDataInizio !== null ? dDataInizio : moment() ).format('DD/MM/YYYY') }
                      Data Fine:   ${ ( dDataFine !== null ? dDataFine : moment() ).format('DD/MM/YYYY') }
                      Valore:      ${ 'sValueSign' }  ${ 'nValueAdjust' } %`}
                  </div>
        
        ,onClick:               fRemoveAdjust
        ,firstButtonLabel:     'rimuovi'
        ,classFirstButton:     'popupRemove'
        ,classCloseButton:     'popupAnnul'
    };

    // Function da associare al bottone dentro la tabella per rimuovere l'adjust
    const foPopupRemoveAdjust = () => {
        set_sPopupType('RemoveAdjust');
    };
    /* #endregion */

    /* #regionModifyAdjust */
    const formatAdjustTarget = (val, oRow) => {
        return sModifyTarget === (( oRow.LINE_ITEM_ID || 0 ) + '' ) /* && bIsModifyAction */ ? (<>
        <FormControl sx={{ m: 1 }} variant="outlined">
            <CustomCssTextField
                id              ={ `${oRow.LINE_ITEM_ID}` }
                key             ={ oRow.LINE_ITEM_ID }
                label           ={ "" }
                value           ={ vModifyContent }
                onChange        ={ (e) => set_vModifyContent(e.target.value) }
                type            ="number"
                size            ="small"
                className       ="OutlinedInputNum"
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <Tooltip title='Salva modifica' placement='bottom' arrow>
                            <IconButton onClick={ fModifyTarget }>
                                <SaveOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                }}
            />
        </FormControl></>)
        : formatNum0dec( val )
    };

    const fModifyTarget = async () => {
        bDebug && console.log('FSetModifyTarget');
        setLoading(true);
        try {
            let params = { }
            const response = await axios.get( config.API_URL + '/get', { headers, params } );

            if ( response && ( response.status === 200 ) && (response?.data?.nRetVal === 1 ) ) {
                set_oNotifyOptions({ message: 'Aggiornamento del valore eseguito con successo', severity:'success', timeout: 2 });
                findAdjustForDate();
            } else {
                console.error(`Errore ${ response?.data?.vErrorMessageDesc }`)
                set_oNotifyOptions({ message: `Errore ${ response?.data?.vErrorMessageDesc }`, severity:'error' });
                setLoading(false);
            }
        } catch ( err ) {
            console.error('ERR 153: Failed', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            setLoading(false);
        };
    };

    const handleScroll = (sModifyTarget) => {
        bDebug && console.log('HandleScroll sModifyTarget', sModifyTarget);
        
        if (sModifyTarget) {
            document.getElementById(sModifyTarget).scrollIntoView({ behavior: "auto", block: "center" });
        }
    };

    /* #endregion */

    /* #region Find Adjustment */
    // Function per ottenere l'elenco dei Placements
    const bringMyPlacements = async () => {
        try {
            bDebug && console.log('Dentro BringMyPlacements');
            let pPlacementDesc = `${ bFindInLine ? '%' : ''}${ sPlacementToAdjustInputValue.toUpperCase() }`;
            let params = { onlyGetAdjusts: true, GAMaccount: 'ilsole24ore', pPlacementDesc };
            const response = await axios.get( config.API_URL + '/gam-adjust', { headers, params } );
            if ( response && ( response.status === 200 ) && response?.data ) {
                set_aosPlacement(response?.data?.aoRecords);
            }
        } catch (err) {
            console.error('ERR 154: Failed BringMyPlacements: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
        }
        // set_bFindingAnythinkLoad(false);
    };

    // Function per ottenere l'elenco delle AdUnits dato un determinato Placement
    const bringMyAdUnit = async () => {
        try {
            bDebug && console.log('Dentro BringMyAdUnit');
            let pPlacementDesc = `${ bFindInLine ? '%' : ''}${ sAdUnitName }`;
            let params = { getForecastAdUnitList: true, pPlacementId: oPlacementToAdjust?.PLACEMENT_ID, pPlacementDesc, pDataInizio: dDataInizio.format('YYYYMMDD'), pDataFine: dDataFine.format('YYYYMMDD') };
            const response = await axios.get( config.API_URL + '/get', { headers, params } );
            if ( response && ( response.status === 200 ) && response?.data ) {
                set_aosAdUnit(response?.data?.aoRecords);
            }
        } catch (err) {
            console.error('ERR 154: Failed BringMyAdUnit: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
        }
        // set_bFindingAnythinkLoad(false);
    }

    // UseEffect per ottenere l'elenco di Placement che contengono la stringa digitata
    useEffect(() => {
        // Non va fatto il controllo nValueTab === '1' perchè anche in tab 2 devo ottenere l'elenco placement
        bDebug && console.log('Dentro UseEffect sPlacementToAdjustInputValue');
        set_dLastUpdate('');
        // set_bFindingAnythinkLoad(true);
        // Potrei nascondere la tabella a questo punto, così da mostrarla solo dopo aver ottenuto l'elenco di adjustment dal db
        set_bShowResult(false);
        utils.debounce( bringMyPlacements, 1000 )();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sPlacementToAdjustInputValue, bFindInLine ]);
    
    // UseEffect per ottenere l'elenco di AdUnits che contengono la stringa digitata
    useEffect(() => {
        if ( nValueTab === '2' ) {
            bDebug && console.log('Dentro UseEffect sAdUnitToAdjustInputValue');
            // set_bFindingAnythinkLoad(true);
            // Potrei nascondere la tabella a questo punto, così da mostrarla solo dopo aver ottenuto l'elenco di adjustment dal db
            set_bShowResult(false);
            utils.debounce( bringMyAdUnit, 1000 )();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sAdUnitName, bFindInLine ]);

    const findAdjustForDate = () => {
        if ( handleDate() ) {
            setLoading(true);
            set_onReload(!onReload);
        } else {
            set_oNotifyOptions({ message: `Il periodo selezionato è troppo ampio per essere visualizzato.`, severity:'warning', timeout: 3 });
        }
    };
    
    // Use Effect per scatenare le chiamate per ottenere gli Adjustment
    useEffect(() => {
        if ( onReload !== null ) {
            bDebug && console.log('Dentro UseEffect OnReload');
            // if ( oPlacementToAdjust ) {
                setLoading(true);
                if ( nValueTab === '2' ) {
                    // if ( oAdUnitToAdjust ) {
                        getAdjustAdUnit();
                    // } else {
                    //     set_oNotifyOptions({ message: `Seleziona una Ad Unit`, severity:'error' });
                    // }
                } else {
                    getAdjustmentPlacement();
                }
            // } else {
            //     set_oNotifyOptions({ message: `Seleziona un Placement`, severity:'error' });
            // }
        }
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);

    const getAdjustmentPlacement = async () => {
        bDebug && console.log('Dentro getAdjustmentPlacement');
        setLoading(true);
        try {
            let params =  {   getAdjustsPlacementList: true
                            , pPlacementId: oPlacementToAdjust?.PLACEMENT_ID // Devo passare L'ID del Placement
                            , pDataInizio: dDataInizio.format('YYYYMMDD') || moment()
                            , pDataFine: dDataFine.format('YYYYMMDD')     || moment()
                            };
            const response = await axios.get( config.API_URL + '/get', { headers, params } );
            
            // Setto l'aoAdjustments prima dell'if così scateno la sua useEffect a prescindere dall'aver ottenuto un risultato
            set_aoAdjustmentsPlacement( ( ( response && ( response.status === 200 ) && response?.data ) || {} ).aoRecords || []);
            if ( response && ( response.status === 200 ) && response?.data ) {
                // !!! TODO set_dDataFine();
                set_aoAdjustmentsPlacementHeaders([
                      { name: 'DATA_RIF'            ,title: 'Data'                   ,width: 95   ,format: formatDate             ,isUniqueKeyForRow: true  }
                     ,{ name: 'AD_UNIT_ID'          ,title: 'AD Unit ID'             ,width: 120  ,format: formatAdUnitLink       ,isUniqueKeyForRow: true  ,isNum: true ,filterOriginalValue: true  }
                     ,{ name: 'AD_UNIT_NAME'        ,title: 'AD Unit'                ,width: 550  ,tooltip: true                  }
                     ,{ name: 'BACINO_FORECAST'     ,title: 'Forecast'               ,width: 120                                  ,isNum: true  }
                     ,{ name: 'BACINO_FORECAST_ADJ' ,title: 'Forecast + Adjustment'  ,width: 160                                  ,isNum: true  }
                     ,{ name: 'ADJ_VAL'             ,title: 'Adjustment'             ,width: 100  ,format: formatAdjustVal        ,isNum: true  }

                    // ,{ name: 'AD_UNIT_NAME'            ,title: 'Elenco AdUnits'       ,width: 110  ,format: formatGoToSelectAdUnit  }
                    // ,{ name: 'AD_UNIT_ID'          ,title: ''                       ,width: 70   ,format: formatRemoveAdjust }
                    // Aggiungere bottone 'Rimuovi Adjust'
                ]);
            } else {
                console.error(`Errore ${ response?.data?.vErrorMessageDesc }`);
                set_oNotifyOptions({ message: `Errore ${ response?.data?.vErrorMessageDesc }`, severity:'error' });
            }
        } catch (err) {
            console.error('ERR 150: Failed GetAdjustmentForecast: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
        }
    };
    
    // Function per ottenere  il dettaglio AdUnit del periodo selezionato
    const getAdjustAdUnit = async () => {
        try {
            bDebug && console.log('Dentro GetAdjustAdUnit');
            setLoading(true);
            let params = {
                  onlyGetAdjusts : true
                , GAMaccount     : 'ilsole24ore'
                , pPlacementId   : oPlacementToAdjust?.PLACEMENT_ID
                , pAdUnitId      : oAdUnitToAdjust?.AD_UNIT_ID
                , pDataInizio    : ( dDataInizio || moment() ).format('YYYYMMDD')
                , pDataFine      : ( dDataFine   || moment() ).format(  'YYYYMMDD')
            }
            const response = await axios.get( config.API_URL + '/gam-adjust', { headers, params } );
            const resValid = ( response && ( response.status === 200 ) && response?.data );
            if ( resValid ) {
                
                // *** PARTE NUOVA ***
                let { aoSegments, aoAdjustsFromGAM } = response?.data;
                
                /* primo esperimento: conversione in semplice array di oggetti [{ nSegmentId, nAdunitId }]
                aoSegments = aoSegments.map( oSegment => (
                    { nSegmentId: oSegment.id, nAdunitId: +oSegment?.targeting?.inventoryTargeting?.targetedAdUnits?.[0]?.adUnitId || 0 }
                ));*/
                
                // converto l'elenco di segments in un oggetto che ha come chiavi le Adunit ID e come valore il Segment ID
                const oAdunitsWithSegment = aoSegments.reduce( ( o, oSegment ) => ({
                    ...o
                    ,[ +oSegment?.targeting?.inventoryTargeting?.targetedAdUnits?.[0]?.adUnitId || 0 ] : oSegment.id
                }) ,{});
                // così per ottenere il segment id di una adunit basta fare: oAdunitsWithSegment[123] (dove 123 è ID di Adunit)
                
                // converto aoAdjustsFromGAM in un altro array in cui elemento corrisponde a una terna (AdunitID, giorno, valore)
                let aoAdjusts = [];
                for ( let n1 = 0; n1 < aoAdjustsFromGAM.length; n1++ ) {
                    
                    const { id, name, status, trafficForecastSegmentId, dailyVolumeSettings, dateRange } = aoAdjustsFromGAM[n1];
                    const asAdjustValues = dailyVolumeSettings.adOpportunityCounts
                    const oAdjust = { adjustId: id, name, status, adunitId: oAdunitsWithSegment[ trafficForecastSegmentId ] };
                    const asDates = utils.getSingleDates( dateRange.startDate, dateRange.endDate );
                    
                    for ( let n2 = 0; n2 < asAdjustValues.length; n2++ ) {
                        
                        aoAdjusts.push({ ...oAdjust, giorno: asDates[n2], value: asAdjustValues[n2] });
                    }
                    
                }
                aoAdjusts = aoAdjusts.filter( o => o.giorno > '20190408' );
                console.log(aoAdjusts);
                /* esempio di record
                    adjustId    : "16307"
                    adunitId    : undefined
                    giorno      : "20181113"
                    name        : "09/21/2018 - 04/13/2019"
                    status      : "ACTIVE"
                    value       : "88032"
                */
                set_aoAdjustmentsAdUnit(aoAdjusts || [] )
            } else {
                console.error('ERR 155: Failed GetAdjustAdUnit');
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            }
        } catch (err) {
            console.error('ERR 155: Failed GetAdjustAdUnit: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
        }
    };

    // Use effect per mostrare la tabella solamente dopo aver ottenuto la risposta da DB
    useEffect(() => {
        if ( onReload != null ) {
            set_bShowResult(true);
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ aoAdjustmentsPlacement, aoAdjustmentsAdUnit ])

    /* #endregion */
    
    // // UseEffect per scatenare lo scroll verso un target appena modificato dopo il reload
    // useEffect( () => {
    //     if ( loading === false && sModifyTarget ) {
    //         console.log('useEffectLoading');
    //         bDebug && console.log('sModifyTarget: ', sModifyTarget);
    //         handleScroll(sModifyTarget);
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[ loading ])

    const myKeyPress = (e) => {
        bDebug && console.log(e);
        if (e.key === 'Enter') {
            e.preventDefault();
            bDebug && console.log('MyKeyPress: ', e.key);
            // if ( sModifyTarget !== '') {
            //     fModifyTarget();
            // } else if
            findAdjustForDate();
        }
        
        if ( e.key === 'Escape' && sModifyTarget !== '' ) {
            e.preventDefault();
            set_sModifyTarget('');
            set_vModifyContent(null);
        }

    };

    const oMappingField = {
        'BACINO_FORECAST'    : { 'description': 'Bacino Forecast'     }
       ,'BACINO_FORECAST_ADJ': { 'description': 'Bacino Forecast ADJ' }
   }

    return (
        <div className="component-container forecast" onKeyDown={ (e) => myKeyPress(e) }>
            <div className="component-card">
            {
                loading
                  ? <CircleProgress/>
                  : <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={nValueTab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <CustomCssTabList onChange={handleChangeTab} aria-label="">
                                    {/* <CustomCssTab label="Placement" value="1" /> */}
                                    <CustomCssTab label="AD Unit"   value="2" />
                                </CustomCssTabList>
                            </Box>
                            {/*
                            <TabPanel value="1" className="tabPanel tabPanelPlacement">
                                <span className='insAdjustmentGroupWrapper'>
                                    <div className='insAdjustmentGroup'>
                                        <span className='selPlacementGroup'>
                                            <Autocomplete
                                                clearOnBlur     = { false }
                                                id              = "sPlacementToAdjust"
                                                options         = { aosPlacement }
                                                getOptionLabel  = {( option => option.PLACEMENT_NAME )}
                                                value           = { oPlacementToAdjust }
                                                onChange        = { (event, newValue) => set_oPlacementToAdjust(newValue) }
                                                inputValue      = { sPlacementToAdjustInputValue }
                                                onInputChange   = { (event, newInputValue) => set_sPlacementToAdjustInputValue(newInputValue)}
                                                renderInput     = { (params) => <CustomCssTextField {...params} label="Seleziona Placement" /> }
                                            />
                                            <Tooltip title={`Passa a ricerca ${ bFindInLine ? 'ad inizio stringa' : 'interna alla stringa' }`} placement='bottom' arrow>
                                                    <FormControl className="input-field searchTypeBox">
                                                        <span>ricerca inline</span>
                                                        <Checkbox
                                                            name     = "searchType"
                                                            id       = "searchType"
                                                            checked  = { bFindInLine }
                                                            onChange = { e => { set_bFindInLine( !bFindInLine ); } }
                                                            className='checkSearchType'
                                                        />
                                                    </FormControl>
                                            </Tooltip>
                                            <div className='circularprogressWrapper'>
                                                { bFindingAnythinkLoad ?  <CircularProgress color="inherit" /> : <></> }
                                            </div>
                                            <span className='selPlacementDateGroup'>
                                                <LocalizationProvider dateAdapter={AdapterMoment} key="date" >
                                                    <span className="TextFieldDateSpan" >
                                                        <DatePicker
                                                            id          = "dataInizio"
                                                            label       = "Data Inizio"
                                                            key         = "dataInizio"
                                                            format      = "DD/MM/YYYY"
                                                            views       = {['day']}
                                                            value       = { dDataInizio }
                                                            minDate     = { moment() }
                                                            onChange    = {( value ) => set_dDataInizio(value) }
                                                            slotProps   = {{ textField: { variant: 'outlined' } }}
                                                            // renderInput = {( params ) => <CustomCssTextField {...params} helperText={null} />}
                                                            // variant     = "outlined"
                                                            className   = "TextFieldDate"
                                                        />
                                                    </span>
                                                    <span className="TextFieldDateSpan" >
                                                        <DatePicker
                                                            id          = "dataFine"
                                                            label       = "Data Fine"
                                                            key         = "dataFine"
                                                            format      = "DD/MM/YYYY"
                                                            views       = {['day']}
                                                            value       = { dDataFine }
                                                            minDate     = { moment() }
                                                            onChange    = { ( value ) => set_dDataFine(value) }
                                                            slotProps   = {{ textField: { variant: 'outlined' } }}
                                                            // renderInput = {( params ) => <CustomCssTextField {...params} helperText={null} />}
                                                            // variant     = "outlined"
                                                            className   = "TextFieldDate"
                                                        />
                                                    </span>
                                                </LocalizationProvider>
                                                <Tooltip title={`Cerca Adjustment ${ oPlacementToAdjust ? `per ${ oPlacementToAdjust?.PLACEMENT_NAME }` : '' }`} placement='bottom' arrow>
                                                    <IconButton className="findAdjustForDate" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ findAdjustForDate } >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </span>
                                        </span>
                                        <span className='blankSpan'></span>
                                        <span className='insNewAdjustButtonGroup'>
                                            <span>
                                                <Select
                                                    id        = 'selectSign'
                                                    value     = { sValueSign }
                                                    onChange  = { ( event) => set_sValueSign( event.target.value ) }
                                                    className = {`selectSign ${ bSelectSignOnFocus ? 'onFocus' : ''}`}
                                                    onFocus   = { () => set_bSelectSignOnFocus(true) }
                                                    onBlur    = { () => set_bSelectSignOnFocus(false) }
                                                >
                                                    {
                                                        aoValuesSign.map( oSign => <MenuItem key={ oSign.value } value={ oSign.value }>{ oSign.value }</MenuItem>)
                                                    }
                                                </Select>
                                                <CustomCssTextField
                                                    autoComplete    = "off"
                                                    id              = "valueAdjust"
                                                    key             = "valueAdjust"
                                                    label           = "Adjustment"
                                                    placeholder     = "0"
                                                    value           = { nValueAdjust }
                                                    onChange        = { (event) => handleChangeValueAdjust(event.target.value) }
                                                    InputLabelProps = {{ shrink: true }}
                                                    variant         = "outlined"
                                                    type            = "text"
                                                    className       = "TextFieldNum"
                                                />
                                                <Select
                                                    id        = 'selectValueType'
                                                    value     = { sValueType }
                                                    onChange  = { ( event) => set_sValueType( event.target.value ) }
                                                    className = {`selectValueType ${ bSelectTypeOnFocus ? 'onFocus' : ''}`}
                                                    onFocus   = { () => set_bSelectTypeOnFocus(true) }
                                                    onBlur    = { () => set_bSelectTypeOnFocus(false) }
                                                >
                                                    {
                                                        aoValuesType.map( oValueType => <MenuItem key={ oValueType.value } value={ oValueType.value }>{ oValueType.value }</MenuItem>)
                                                    }
                                                </Select>
                                            </span>
                                            <IconButton className="addNewAdjustButton" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ foPopupAddAdjust }>
                                                <SaveOutlinedIcon />
                                            </IconButton>
                                        </span>
                                    </div>
                                    <div className='insAdjustmentGroup'>
                                        <ButtonGroup variant="outlined" disableElevation aria-label="outlined button group" className={`buttonGroup ${ aoAdjustmentsPlacement.length && onReload !== null ? 'invisible' : 'invisible'}`}>
                                            <Button className={`graphButton ${ sGraphEnabled === 'line' ? 'activedButton' : '' }`} title="Mostra Grafico Lineare" variant="outlined" onClick={ () => settingGraph('line') } disableElevation>
                                                <SsidChartIcon />
                                            </Button>
                                            <Button className={`graphButton ${ sGraphEnabled === '' ? 'activedButton' : '' }`} title="Mostra Tabella" variant="outlined" onClick={ () => settingGraph('') } disableElevation>
                                                <TableChartIcon />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </span>
                                {
                                    sPopupType !== '' ?
                                        <PopupConfirm { ...{ oPopupOptions: ( sPopupType === 'AddAdjustPlacement' ? oPopupAddAdjustPlacement : oPopupRemoveAdjust ), sPopupType, set_sPopupType } } />
                                        : ''
                                }
                                {
                                    loading ? <></> : <>{
                                        !bShowResult ? <></> : <>{
                                          (aoAdjustmentsPlacement.length && Array.isArray(aoAdjustmentsPlacement)) ?
                                              sGraphEnabled ?
                                                  <Graph oGraphContent={{ sChartType: sGraphEnabled ,aoRows: aoAdjustmentsPlacement ,asElementX: [ 'giorno' ] ,asElementY: [ 'value' ] , oMappingField }} className='grafico'/>
                                                : <div className="table-wrapper-forecast">
                                                    <SimpleTable
                                                            chiave         = { 'FORECAST' }
                                                            sTableDataType = { 'FORECAST' }
                                                            aoRows         = { aoAdjustmentsPlacement }
                                                            aoCols         = { aoAdjustsHeaders }
                                                            noHeight       = { true }
                                                            // isEditable     = { true }
                                                            // editFunction   = { ( oRow ) => {
                                                            //     setLoading(true);
                                                            //     if ( ( oRow.LINE_ITEM_ID + '' ) === sModifyTarget ) {
                                                            //         set_sModifyTarget('');
                                                            //         set_vModifyContent(null);
                                                            //     } else {
                                                            //         set_sModifyTarget( oRow.LINE_ITEM_ID + '' );
                                                            //         set_vModifyContent( oRow.ADJUSTMENT );
                                                            //     }
                                                            // } }
                                                        />
                                                  </div>
                                            : <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp; Nessun risultato trovato </div>
                                        }</>
                                    }</>
                                }
                            </TabPanel>
                            */}
                            <TabPanel value="2" className="tabPanel tabPanelAdUnit">
                                <span className='insAdjustmentGroupWrapper'>
                                    <div className='insAdjustmentGroup'>
                                        {/* <span>
                                            <span className='selPlacementGroup'>
                                                <Autocomplete
                                                        clearOnBlur     = { false }
                                                        options         = { aosPlacement }
                                                        getOptionLabel  = {( option => option.PLACEMENT_NAME )}
                                                        value           = { oPlacementToAdjust }
                                                        onChange        = { (event, newValue) => set_oPlacementToAdjust(newValue) }
                                                        inputValue      = { sPlacementToAdjustInputValue }
                                                        onInputChange   = { (event, newInputValue) => set_sPlacementToAdjustInputValue(newInputValue)}
                                                        renderInput     = { (params) => <CustomCssTextField {...params} label="Placement" /> }
                                                />
                                            </span>
                                        </span>*/}
                                        <span>
                                            <span className='selAdUnitGroup'>
                                                {/* <Tooltip title={`Passa a ricerca ${ bFindInLine ? 'ad inizio stringa' : 'interna alla stringa' }`} placement='bottom' arrow>
                                                    <IconButton className="findAdjustForDate" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ handleChangeBringMyPlacementsType } disabled={ bFindingAnythinkLoad }>
                                                        { bFindInLine ? <PowerInputOutlinedIcon /> : <StringCursorIcon /> }
                                                    </IconButton>
                                                </Tooltip>
                                                <div className='circularprogressWrapper'>
                                                    { bFindingAnythinkLoad ?  <CircularProgress color="inherit" /> : <></> }
                                                </div>
                                                */}
                                            </span>
                                            <span className='insNewAdjustButtonGroup'>
                                                <span>
                                                    <Autocomplete
                                                        className       = "AutocompleteFieldAdunit"
                                                        options         = { aosAdUnit }
                                                        noOptionsText   = { isCallToDB ? 'Nessun elemento trovato' : "Loading..." }
                                                        getOptionLabel  = {( option => option.AD_UNIT_NAME )}
                                                        value           = { oAdUnitToAdjust }
                                                        onChange        = { (event, newValue) => set_oAdUnitToAdjust(newValue) }
                                                        inputValue      = { sAdUnitName }
                                                        onInputChange   = { (event, newInputValue) => set_sAdUnitName(newInputValue)}
                                                        clearOnBlur     = { false }
                                                        renderInput     = { (params) => <CustomCssTextField {...params} label="Ad Unit" /> }
                                                    />
                                                    <LocalizationProvider dateAdapter={AdapterMoment} key="date" >
                                                        <span className="TextFieldDateSpan" >
                                                            <DatePicker
                                                                id          = "dataInizio"
                                                                label       = "Data Inizio"
                                                                key         = "dataInizio"
                                                                format      = "DD/MM/YYYY"
                                                                views       = {['day']}
                                                                value       = { dDataInizio }
                                                                minDate     = { moment() }
                                                                onChange    = {( value ) => set_dDataInizio(value) }
                                                                slotProps   = {{ textField: { variant: 'outlined' } }}
                                                                className   = "TextFieldDate"
                                                            />
                                                        </span>
                                                        <span className="TextFieldDateSpan" >
                                                            <DatePicker
                                                                id          = "dataFine"
                                                                label       = "Data Fine"
                                                                key         = "dataFine"
                                                                format      = "DD/MM/YYYY"
                                                                views       = {['day']}
                                                                value       = { dDataFine }
                                                                minDate     = { moment() }
                                                                onChange    = { ( value ) => set_dDataFine(value) }
                                                                slotProps   = {{ textField: { variant: 'outlined' } }}
                                                                className   = "TextFieldDate"
                                                            />
                                                        </span>
                                                    </LocalizationProvider>
                                                    {/* <Select
                                                        id        = 'selectSign'
                                                        value     = { sValueSign }
                                                        onChange  = { ( event) => set_sValueSign( event.target.value ) }
                                                        className = {`selectSign ${ bSelectSignOnFocus ? 'onFocus' : ''}`}
                                                        onFocus   = { () => set_bSelectSignOnFocus(true) }
                                                        onBlur    = { () => set_bSelectSignOnFocus(false) }
                                                    >
                                                        {
                                                            aoValuesSign.map( oSign => <MenuItem key={ oSign.value } value={ oSign.value }>{ oSign.value }</MenuItem>)
                                                        }
                                                    </Select> */}
                                                    <CustomCssTextField
                                                        autoComplete    = "off"
                                                        id              = "valueAdjust"
                                                        key             = "valueAdjust"
                                                        label           = "Adjustment"
                                                        placeholder     = "0"
                                                        value           = { nValueAdjust }
                                                        onChange        = { (event) => handleChangeValueAdjust(event.target.value) }
                                                        InputLabelProps = {{ shrink: true }}
                                                        variant         = "outlined"
                                                        type            = "text"
                                                        className       = "TextFieldNum"
                                                    />
                                                    {/* <Select
                                                        id        = 'selectValueType'
                                                        value     = { sValueType }
                                                        onChange  = { ( event) => set_sValueType( event.target.value ) }
                                                        className = {`selectValueType ${ bSelectTypeOnFocus ? 'onFocus' : ''}`}
                                                        onFocus   = { () => set_bSelectTypeOnFocus(true) }
                                                        onBlur    = { () => set_bSelectTypeOnFocus(false) }
                                                    >
                                                        {
                                                            aoValuesType.map( oValueType => <MenuItem key={ oValueType.value } value={ oValueType.value }>{ oValueType.value }</MenuItem>)
                                                        }
                                                    </Select>*/}
                                                </span>
                                                <IconButton className="addNewAdjustButton" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ foPopupAddAdjust }>
                                                    <SaveOutlinedIcon />
                                                </IconButton>
                                                <span className='selPlacementDateGroup'>
                                                    <Tooltip title="Aggiorna" placement="bottom" arrow>
                                                        <IconButton className="findAdjustForDate" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ findAdjustForDate } >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    { /*<div className='insAdjustmentGroup'>
                                        <ButtonGroup variant="outlined" disableElevation aria-label="outlined button group" className={`buttonGroup ${ aoAdjustmentsAdUnit.length && ( ( onReload !== null ) ? '' : 'invisible' ) }`}>
                                            <Button className={`graphButton ${ sGraphEnabled === 'line' ? 'activedButton' : '' }`} title="Mostra Grafico Lineare" variant="outlined" onClick={ () => settingGraph('line') } disableElevation>
                                                <SsidChartIcon />
                                            </Button>
                                            <Button className={`graphButton ${ sGraphEnabled === '' ? 'activedButton' : '' }`} title="Mostra Tabella" variant="outlined" onClick={ () => settingGraph('') } disableElevation>
                                                <TableChartIcon />
                                            </Button>
                                        </ButtonGroup>
                                    </div> */}
                                </span>
                                {
                                    sPopupType !== '' ?
                                        <PopupConfirm { ...{ oPopupOptions: ( sPopupType === 'AddAdjustAdUnit' ? oPopupAddAdjustAdUnit : oPopupRemoveAdjust ), sPopupType, set_sPopupType } } />
                                        : ''
                                }
                                {
                                    loading ? <></> : <>{
                                        !bShowResult ? <></> : <>{
                                          (aoAdjustmentsAdUnit.length && Array.isArray(aoAdjustmentsAdUnit)) ?
                                              sGraphEnabled ?
                                                  <Graph oGraphContent={{ sChartType: sGraphEnabled ,aoRows: aoAdjustmentsAdUnit ,asElementX: [ 'giorno' ] ,asElementY: [ 'value' ] , oMappingField }} className='grafico'/>
                                                : <div className="table-wrapper-forecast">
                                                    <SimpleTable
                                                            chiave         = { 'FORECAST' }
                                                            sTableDataType = { 'FORECAST' }
                                                            aoRows         = { aoAdjustmentsAdUnit || [] }
                                                            aoCols         = { aoAdjustsHeaders }
                                                            noHeight       = { true }
                                                            // isEditable     = { true }
                                                            // editFunction   = { ( oRow ) => {
                                                            //     setLoading(true);
                                                            //     if ( ( oRow.LINE_ITEM_ID + '' ) === sModifyTarget ) {
                                                            //         set_sModifyTarget('');
                                                            //         set_vModifyContent(null);
                                                            //     } else {
                                                            //         set_sModifyTarget( oRow.LINE_ITEM_ID + '' );
                                                            //         set_vModifyContent( oRow.ADJUSTMENT );
                                                            //     }
                                                            // } }
                                                        />
                                                  </div>
                                            : <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp; Nessun risultato trovato </div>
                                        }</>
                                    }</>
                                }
                            </TabPanel>
                        </TabContext>
                    </Box>
            }
            </div>
        </div>
    )
}

export default Forecast;
