import React, { useState, useEffect, useContext }   from 'react';
import {useNavigate}                                from 'react-router-dom';
import config                                       from '../../config.js';
import axios                                        from 'axios';
import moment                                       from 'moment';
import Box                                          from '@mui/material/Box';
import TabContext                                   from '@mui/lab/TabContext';
import TabPanel                                     from '@mui/lab/TabPanel';
import { Autocomplete                               
        ,Button  
        ,ButtonGroup                                   
        ,Checkbox
        ,IconButton                                 
        ,MenuItem                                   
        ,Select                                     
        ,Tooltip }                                  from '@mui/material';

import './Users.css'
import { AppContext }                               from '../../App.js';
import { AuthContext }                              from '../../contexts/authContext';
import { SimpleTable }                              from '../../components/SimpleTable/SimpleTable';
import { PopupConfirm }                             from '../../components/PopupConfirm/PopupConfirm'
import CircleProgress                               from '../../components/CircleProgress/CircleProgress.tsx';
import utils                                        from '../../util/CommonUtilities';
import UserEditing                                  from'./UserEditing.js'
import { CustomCssTextField
       , CustomCssInput
       , CustomCssTab
       , CustomCssTabList
       , CustomMaxWidthTooltip }                    from '../../components/CustomCSS/CustomCSS.tsx';

import { AddCircleOutlineIcon
       , CancelIcon
       , CalendarClockIcon
       , CheckIcon
       , CheckBoxFlagged
       , CheckBoxEmpty  
       , DoDisturbIcon
       , DoNotDisturbOnOutlinedIcon
       , FolderOffIcon
       , InfoIcon
       , MenuIcon
       , DistribuzioneCarichiIcon
       , AdjustIcon
       , CabinetIcon
       , GroupIcon
       , FormatiIcon
       , NotificationImportantIcon
       , AlertIcon
       , SellOrNotIcon
       , NotSupervisorAccountIcon
       , SupervisorAccountIcon 
       , UserIcon              }                    from '../../assets/icons/icons.js';

const bDebug = config.DEBUG && false;
const oAlarmsHeader = { 1:  'LineItem senza materiali associati'
                       ,2:  'LineItem non partiti'
                       ,3:  'LineItem in delivery con click a zero'
                       ,4:  'LineItem in delivery senza Placement e AdUnit'
                       ,5:  'LineItem in underdelivery'
                       ,6:  'LineItem in pausa'
                       ,7:  'Placement con bacino in decrescita'
                       ,8:  'AdUnit con bacino in decrescita'
                       ,9:  'AdUnit con dato storico a zero'
                       ,10: 'LineItem con viewability sotto soglia'
                       ,11: 'LineItem con Errori Vast sopra soglia'
                    };
const nAlertsCount  = Object.keys(oAlarmsHeader).length;

const oMenuColumns =  { 'Allarmi'                  : <div className='iconWrap d-flex'><NotificationImportantIcon className={"textGrey"}/></div> 
                       ,'Anagrafiche'              : <div className='iconWrap d-flex'><CabinetIcon               className={"textGrey"}/></div> 
                       ,'Adjustment'               : <div className='iconWrap d-flex'><AdjustIcon                className={"textGrey"}/></div> 
                    //    ,'Venduto/Invenduto'     : <div className='iconWrap d-flex'><SellOrNotIcon             className={"textGrey"}/></div> 
                       ,'Report Venduto Invenduto' : <div className='iconWrap d-flex'><SellOrNotIcon             className={"textGrey"}/></div> 
                       ,'Distribuzione Carichi'    : <div className='iconWrap d-flex'><DistribuzioneCarichiIcon  className={"textGrey"}/></div> 
                       ,'Formati'                  : <div className='iconWrap d-flex'><FormatiIcon               className={"textGrey"}/></div>
                    //    ,'Utenti'                : <div className='iconWrap d-flex'><GroupIcon                 className={"textGrey"}/></div>
                    };
const nMenuColumnsCount  = Object.keys(oMenuColumns).length;
/*
    NON BISOGNA PAGINARE GLI UTENTI !!!
*/

const Users = () => {
    
    const
         [ loading          ,setLoading             ] = useState(true)
        ,[ onReload         ,set_onReload           ] = useState(null) // State per triggerare il lancio della chiamata
        ,[ nValueTab        ,set_nValueTab          ] = useState('1')
        
        ,[ aoUsers          ,set_aoUsers            ] = useState([])
        ,[ aoUsersHeaders   ,set_aoUsersHeaders     ] = useState([])
        ,[ sTableType       ,set_sTableType         ] = useState('date')
        ,[ sVerifiedTarget  ,set_sVerifiedTarget    ] = useState('')
        
        ,[ KUSERselected    ,set_KUSERselected      ] = useState('')  // State per contenere il KUser dell'utente selezionato dall'elenco <Select>
        ,[ KUSERFocused     ,set_KUSERFocused       ] = useState('')  // State per contenere il KUser dell'urente OnFocus, sul quale sto operando
        ,[ KUSERParent      ,set_KUSERParent        ] = useState('')  // State per contenere il KUser dell'utente Genitore
        ,[ KUSERChild       ,set_KUSERChild         ] = useState('')  // State per contenere il KUser dell'Utente figlio
        
        ,[ sPopupType       ,set_sPopupType         ] = useState('')  // State per condizionare il tipo di popup da aprire in gerarchia
        ,[ sButtonState     ,set_sButtonState       ] = useState('')  // State per condizionare il comportamento del programma in base a quale bottone ho premuto
        ,[ userToSearch     ,set_userToSearch       ] = useState(null)  // State per contenere l'utente da cercare in gerarchia
        ,[ userToSearchInputValue ,set_userToSearchInputValue   ] = useState('')  // State per contenere l'utente da cercare in gerarchia
        ,[ bShowDialog     ,set_bShowDialog         ] = useState(false) // State per condizionare l'apertura della finestra Modifica Utente
        ,[ oDialogOptions  ,set_oDialogOptions      ] = useState({}) // { sTitle: '', content: null, sConfirmButton: '' } // State per fornire il contenuto alla Mod Utente
    ;
    
    const
         navigate           = useNavigate()
        ,bAdminUser         = ( localStorage.getItem('flagAdmin') || '' ) === 'Y'
        ,isDemo             = process.env.REACT_APP_ENV.includes('demo')
        ,isProd             = process.env.REACT_APP_ENV.includes('prod')
        ,auth               = useContext(AuthContext)
        ,{ set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext)
        ,headers            = {
            'Accept'        : 'application/json',
            'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
        }
        
        ,iconAdminYes       = <div className='iconWrap'><SupervisorAccountIcon      className={"textGrey" }/></div>
        ,iconAdminNoFilter  = <div className='iconWrap'><NotSupervisorAccountIcon   className={"textGrey" }/></div>
        ,iconAdminNo        = <div className='iconWrap'><NotSupervisorAccountIcon   className={"textLightGrey" }/></div>
        ,iconAttivo         = <div className='iconWrap'><CheckIcon                  className={"textGreen"}/></div>
        ,iconAttivoTraf     = <div className='iconWrap'><CheckIcon                  className={"textGrey" }/></div>
        ,iconDisattivo      = <div className='iconWrap'><DoDisturbIcon              className={"textRed"  }/></div>
        ,iconXFilter        = <div className='iconWrap'><CancelIcon                 className={"textGrey" }/></div>
        ,iconX              = <div className='iconWrap'><CancelIcon                 className={"textLightGrey" }/></div>
        ,iconChecked        = <div className='iconWrap d-flex'><CheckBoxFlagged     className={"textGrey" }/></div>
        ,iconNotChecked     = <div className='iconWrap d-flex'><CheckBoxEmpty       className={"textGrey" }/></div>
    ;
    const input             = 'YYYYMMDDHHmmss';
    const output            = 'D MMM YYYY HH:mm';
    const formatDate        = (val) => !val ? 'N.D.'                : utils.formatDateTime( val, { input, output, useTimezone: true });
    const formatDateExcel   = (val) => !val ? 'N.D.'                : utils.formatDateTime( val, { input, output: 'DD/MM/YYYY HH:mm', useTimezone: true });
    const formatExecution   = (val) =>                                utils.formatDateTime( val, { input, output, fromNow: true , useTimezone: true });
    const formatLogin       = (val) => !val ? 'Utente mai connesso' : utils.formatDateTime( val, { input, output, fromNow: true , useTimezone: true });
    const formatDisable     = (val) => !val ? ''                    : utils.formatDateTime( val, { input, output, fromNow: true , useTimezone: true });
    const formatCheckBox    = (sValCheck, sField) =>
        (val, oRow) => {
            let obj = JSON.parse(val || "[]")?.find(o => Object.keys(o).find( s => s === sValCheck )) || {}
            // return val && <Checkbox id        = { oRow?.USERNAME + '' }
            return <Checkbox id        = { oRow?.USERNAME + ' ' + sValCheck  }
                             checked   = { !!obj?.[sValCheck] || false }
                             onClick   = {(event) => { event.stopPropagation() }}
                             onChange  = {(event) => { setUserField(sValCheck, sField, oRow) }}
                             // title     = { formatDateCheckBox(obj?.['D']) }
                             className = { `checkbox-custom`}/>};

    const formatFilterCheckColumn = (sCompare) => {
        return [
            { label: ' '            ,value: ''            ,checkFunc: () => true } // tutte le righe sono valide
           ,{ label: iconChecked    ,value: 'Y'           ,checkFunc: ( val ) => { return val.includes(`"${sCompare}":"R"`) }}
            // Versione Read/write
            // ,{ label: iconChecked    ,value: `{${sCompare}}:"R"` ,checkFunc: ( val, valToCheck ) => {
            //     return Object.keys(JSON.parse(val || '{}')).includes(JSON.parse(valToCheck))} }
           ,{ label: iconNotChecked ,value: 'N'           ,checkFunc: ( val ) => { return !val.includes(`"${sCompare}"`) }}
        //    ,{ label: iconNotChecked ,value: `"${sCompare}":`     ,checkFunc: ( val, valToCheck ) => { return !val.includes(valToCheck) }}
       ]
    }

    // Function per creare le colonne degli allarmi
    const foAlarmColumns = () => {
        let oAlarmColumn = {};
        //{ 1:  'LineItem senza materiali associati' ,2:  'LineItem non partiti' }

        for ( let cont = 1; cont <= nAlertsCount; cont++ ) {
            const sAlert    = 'A' + (cont + '').padStart(2,'0');
            const sKeyAlert = 'ALERTS ' + (cont + '').padStart(2,'0');
            oAlarmColumn[sKeyAlert] = {
                 name            : 'ALERTS'
                ,key             : sAlert
                ,title           : sAlert
                ,group           : 'Allarmi'
                ,width           : 41
                ,format          : formatCheckBox(sAlert, 'allarmi')
                ,formatExcel     : (val) => JSON.parse(val || "[]")?.find(o => Object.keys(o).find( s => s === sAlert )) ? 'Y' : ''
                ,notSortable     : true
                ,additionalClass : () => sAlert + ( (cont === 1) ? ' firstCol' : (cont  === nAlertsCount) ? ' lastCol' : '' )
                ,headerTooltip   : oAlarmsHeader[ cont ]
                ,selectOptions   : formatFilterCheckColumn(sAlert)
            }
        }
        return oAlarmColumn
    }

    // Function per creare le colonne degli allarmi
    const foMenuColumns = () => {
        let oMenuColumn = {};
        // { 'Allarmi': <>iconaAllarmi</> ,'Anagrafiche': <>iconaAnagrafiche</> }
        for (let cont = 0; cont < nMenuColumnsCount; cont ++) {

            // sMenuKey = 'Allarmi' oppure 'Anagrafiche'
            const sMenuKey  = Object.keys(oMenuColumns)[cont];
            oMenuColumn[sMenuKey] = {
                 name            : 'MENU_ITEMS'
                ,key             : sMenuKey
                ,title           : oMenuColumns[sMenuKey]
                ,group           : 'Voci di Menu'
                ,width           : 41
                ,format          : formatCheckBox(sMenuKey, 'menu')
                ,formatExcel     : (val) => JSON.parse(val || "[]")?.find(o => Object.keys(o).find( s => s === sMenuKey )) ? 'Y' : ''
                ,notSortable     : true
                ,additionalClass : () => 'M' + (cont + '').padStart(2,'0') + ( (cont === 0) ? ' firstCol' : (cont + 1 === nMenuColumnsCount) ? ' lastCol' : '' )
                ,headerTooltip   : sMenuKey
                ,selectOptions   : formatFilterCheckColumn(sMenuKey)
            }
        }
        return oMenuColumn;
    }
    
    // Dichiarazione di intestazione di tabella
    const oHeadersMap = {
        'FULL_NAME'   : { name: 'FULL_NAME'                ,title: 'Nome'                  ,width: 160     ,tooltip: true }
       ,'USERNAME'    : { name: 'USERNAME'                 ,title: 'Utente'                ,width: 265     ,tooltip: true        ,isUniqueKeyForRow: true }
       ,'FLAG_ATTIVO' : { name: 'FLAG_ATTIVO'              ,title: 'Attivo'                ,width: 60      ,format: (val) => val === 'Y' ? iconAttivo : iconDisattivo
           ,selectOptions: [
                { label: ' '           ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
               ,{ label: iconAttivo    ,value: 'Y' ,checkFunc: ( val ) => ( val === 'Y' ) }
               ,{ label: iconDisattivo ,value: 'N' ,checkFunc: ( val ) => ( val === 'N' ) }
           ]
       }
       ,'FLAG_ADMIN'  : { name: 'FLAG_ADMIN'               ,title: 'Admin'                 ,width: 60      ,format: (val) => val === 'Y' ? iconAdminYes : iconAdminNo
           ,selectOptions: [
                { label: ' '               ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
               ,{ label: iconAdminYes      ,value: 'Y' ,checkFunc: ( val ) => ( val === 'Y' ) }
               ,{ label: iconAdminNoFilter ,value: 'N' ,checkFunc: ( val ) => ( val === 'N' ) }
           ]
       }
       ,'FLAG_TRAFFICKER'      : { name: 'FLAG_TRAFFICKER'          ,title:'Trafficker'             ,width: 60      ,format: (val) => val === 'Y' ? iconAttivoTraf : iconX
           ,selectOptions: [
                { label: ' '            ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
               ,{ label: iconAttivoTraf ,value: 'Y' ,checkFunc: ( val ) => ( val === 'Y' ) }
               ,{ label: iconXFilter    ,value: 'N' ,checkFunc: ( val ) => ( val === 'N' ) }
           ]
       }
       ,'AGENTE_COD'           : { name: 'AGENTE_COD'               ,title: 'Codice Agente'         ,width: 95                      ,format: (val) => !val? 'N.D.' : val }
       ,'DATA_INSERIMENTO'     : { name: 'DATA_INSERIMENTO'         ,title: 'Data Creazione'        ,width: 135     ,group: 'Date'  ,additionalClass : () => 'Date firstCol' ,format: formatDate ,formatExcel: formatDateExcel }
       ,'DATA_ULTIMA_MODIFICA' : { name: 'DATA_ULTIMA_MODIFICA'     ,title: 'Ultima Modifica'       ,width: 135     ,group: 'Date'  ,additionalClass : () => 'Date'          ,format: formatDate ,formatExcel: formatDateExcel }
       ,'DATA_ULTIMA_LOGIN'    : { name: 'DATA_ULTIMA_LOGIN'        ,title: 'Ultima Connessione'    ,width: 135     ,group: 'Date'  ,additionalClass : () => 'Date'          ,format: formatDate ,formatExcel: formatDateExcel }
       ,'DATA_CANCELLAZIONE'   : { name: 'DATA_CANCELLAZIONE'       ,title: 'Data Cancellazione'    ,width: 135     ,group: 'Date'  ,additionalClass : () => 'Date lastCol'  ,format: formatDate ,formatExcel: formatDateExcel } /* FLAG_ATTIVO scatena il cambio di stato di DATA_CANCELLAZIONE */
       ,...foAlarmColumns()
       ,...foMenuColumns()
    };

    const reload = () => {
        setLoading(true);
        set_onReload( b => !b ); 
    };

    const goToUserCreation = () => {
        navigate('/crea-utente');
    };

    const handleChangeTab = (event, newValue) => {
        set_nValueTab(newValue);
    };
    
    const toggleNotifica = ( message, severity, timeout ) => {
        setLoading(false);
        set_bShowDialog(false);
        set_oNotifyOptions({ message, severity,...( timeout && { timeout }) });
    };

    // Function per modificare i campi degli utenti [ Allarmi, Voci di Menu ]
    const setUserField = async (sValCheck, sField, obj) => {
        setLoading(true);
        try {
            
            const oParamsToDB = {
                 pUserId         : obj.KUSER
                ,pCognome        : obj.FULL_NAME
                ,pUserName       : obj.USERNAME
                ,pEMailAddress   : obj.USERNAME
                ,pAgenteCod      : obj.AGENTE_COD
                ,pFlagAdmin      : obj.FLAG_ADMIN
                ,pFlagTrafficker : obj.FLAG_TRAFFICKER
                ,pFlagEnabled    : obj.FLAG_ATTIVO
                ,pFlagCognito    : obj.FLAG_COGNITO
                ,pParentUserId   : obj.PARENT_KUSER
                ,pAlerts         : obj.ALERTS     // [{'codiceallarme':'W'}]
                ,pMenuItems      : obj.MENU_ITEMS // [{'Allarmi':'W'}]
                // ,pAlerts         : sField === 'allarmi' ? sValCheck : (obj.ALERTS     || '')
                // ,pMenuItems      : sField === 'menu' ? sValCheck : (obj.MENU_ITEMS || '')
            }
            
            const response = await axios.post(
                 config.API_URL + '/manage-user'
                ,null
                ,{
                    headers,
                    params : {
                         pActionType: 'modifica'
                        ,oParamsToDB
                        ,oFieldToSet: {
                             sField
                            ,sVal: sValCheck
                         }
                    }
                }
            );
            const { nRetVal, vErrorMessageDesc } = response.data;

            if ( ( response.status === 200 ) && ( +nRetVal > 0 ) && ( !vErrorMessageDesc ) ) {
                set_oNotifyOptions({ message: `Utente ${ obj.USERNAME } aggiornato correttamente`, severity:'success', timeout: 5 });
            } else {
                console.error(`Errore ${ vErrorMessageDesc || '' }`);
                set_oNotifyOptions({ message: `Errore ${ vErrorMessageDesc || '' }`, severity:'error' });
            }
            
        } catch ( err ) {
            console.error('ERR 134: Failed', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity: 'error' });
        }
        set_sVerifiedTarget(obj.USERNAME + ' ' + sValCheck);
        set_onReload( b => !b );
    };
    
    const handleScroll = (sModifyTarget) => {
        let element = document.getElementById(sModifyTarget);
        if (sModifyTarget && element) {
            element.scrollIntoView({ behavior: "auto", block: "center", inline: 'center' });
            sVerifiedTarget && set_sVerifiedTarget('')
        }
    };

    useEffect(() => {
        if ( !bAdminUser ) {
            navigate('/errorPage403');
        }
        set_dLastUpdate('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function per cambiare l'intestazione di tabella

    const settingTable = (field) => {
        if ( field !== sTableType ) {
            set_sTableType( field );
        }
    };

    // UseEffect per intercettare il cambio di intestazione della tabella
    useEffect(() => {
        
        let asHeaders = [ 'FULL_NAME', 'USERNAME', 'FLAG_ATTIVO', 'FLAG_ADMIN', 'FLAG_TRAFFICKER' ];
        
        if (        sTableType === 'date' ) {
            asHeaders.push(...['AGENTE_COD', 'DATA_INSERIMENTO', 'DATA_ULTIMA_MODIFICA', 'DATA_ULTIMA_LOGIN', 'DATA_CANCELLAZIONE' ]);
            
        } else if ( sTableType === 'allarmi' ) {
            for (let cont = 1; cont <= nAlertsCount; cont ++) {
                asHeaders.push(...[`ALERTS ${(cont + '').padStart(2,'0')}`]);
                // asHeaders.push(`ALERTS`);
            }
            
        } else if ( sTableType === 'voci_menu' ) {
            // for (let cont = 0; cont < nMenuColumnsCount.length; cont ++) {
            asHeaders.push(...Object.keys(oMenuColumns));
            // }
        }
        
        set_aoUsersHeaders( asHeaders.map( sHeader => ({ ...oHeadersMap[sHeader] }) ) );
        
    }, [ sTableType ]);    

    /* #region Gerarchia */

        let aoRigaPrec = [];

        const handleChangeKUser = (event) => {
            let KUSER = event.target.value;
            set_KUSERselected(KUSER);

            if (sButtonState === 'first') {
                set_KUSERParent(KUSER)
            } else if (sButtonState === 'last') {
                set_KUSERChild(KUSER)
            }
        };

        const openPopupSelectUser = ({ KUSER, sButton }) => {
            bDebug && console.log('openPopupSelectUser: ', { KUSER, sButton });

            set_KUSERFocused(KUSER);
            if (sButton === 'first') {
                set_KUSERChild(KUSER)
                set_sButtonState('first')
            } else if (sButton === 'last') {
                set_KUSERParent(KUSER)
                set_sButtonState('last')
            }
            set_KUSERselected('')
            set_sPopupType('SelectUser');
        };

        const clearUserParams = () => {
            set_KUSERChild('');
            set_KUSERParent('');
            set_KUSERFocused('');
            set_KUSERselected('');
            set_sButtonState('');
            set_sPopupType('');
            reload();
        };

        const addUserInGerarchia = async () => {
            bDebug && console.log('KUSERParent', KUSERParent );
            bDebug && console.log('KUSERParenttype', typeof KUSERParent );
            bDebug && console.log('KUSERChild', KUSERChild );
            bDebug && console.log('sButtonState: ', sButtonState);
            set_oNotifyOptions(null);

            if (KUSERParent != null && KUSERChild ) {
                // Creo nuova persona inserendo il KUSER nel suo PARENT_KUSER
                setLoading(true);

                try {
                    let params =  { modUserGerarchia: true, pUserId: KUSERChild, pParentUserId: KUSERParent };
                    bDebug && console.log('params: ', params);

                    const response = await axios.get( config.API_URL + '/get', { headers, params } );

                    if ( response && ( response.status === 200 ) && (response?.data?.nRetVal === 1 ) ) {
                        // set_sModifyTarget(''); // non posso settare qui a '' perchè altrimenti non entro nell'handlescroll dell'useEffectOnReload
                    } else {
                        console.error(`Errore ${ response?.data?.vErrorMessageDesc }`);
                        set_oNotifyOptions({ message: `Errore ${ response?.data?.vErrorMessageDesc }`, severity:'error' });
                        setLoading(false);
                    }

                } catch(err) {
                    console.error('ERR 131: Failed addUserInGerarchia', err);
                    set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' })
                    setLoading(false);
                }
            }
            clearUserParams();
        };

        const oPopupOptionsModify = {
            DialogTitle:       `Seleziona elemento ${ sButtonState === 'first' ? 'superiore' : 'inferiore' }`
            ,DialogContentText: `di ${( aoUsers.find( oUser => oUser.KUSER === KUSERFocused ) || {} ).FULL_NAME || 'Utente non riconosciuto' }`
            ,Content:           <Select
                                    id="demo-simple-select"
                                    value={KUSERselected}
                                    label=""
                                    onChange={ handleChangeKUser }
                                    input = { <CustomCssInput /> }
                                >
                                    {
                                        aoUsers //Necessario filtrare tutti i padri dei padri nel bottone last
                                            .filter(oUser => oUser.KUSER !== KUSERFocused && (
                                                sButtonState === 'last'
                                                    ? oUser.PARENT_KUSER !== KUSERFocused
                                                    : true
                                            ))
                                            .map( oUser => <MenuItem key={oUser.KUSER} value={oUser.KUSER}>{oUser.FULL_NAME}</MenuItem> )
                                    }
                                </Select>
            ,onClick:               addUserInGerarchia
            ,onCancel:              clearUserParams
            ,firstButtonLabel:       'inserisci'
            ,classFirstButton:       'popupConfirm'
            ,classCloseButton:       'popupAnnul'
        };
        
        const foPopupOptionsRemove = () => {
            let oUserattuale = aoUsers.find( o => o.KUSER === KUSERChild );
            let oUserParent = aoUsers.find( o => o.KUSER === oUserattuale.PARENT_KUSER);

            return {
                DialogTitle:        `Confermi di voler rimuovere ${ oUserattuale.FULL_NAME }`
                ,DialogContentText: ` da ${ oUserParent.FULL_NAME }`
                ,onClick:               addUserInGerarchia
                ,onCancel:              clearUserParams
                ,firstButtonLabel:     'rimuovi'
                ,classFirstButton:     'popupRemove'
                ,classCloseButton:     'popupAnnul'
            }
        };

        const removeUserFromTreeGerArk = ({ KUSER }) => {
            set_KUSERChild(KUSER);
            set_KUSERParent('');
            set_sButtonState('remove');
            set_sPopupType('removeUser');
        };

        const sortingRow = ( aRow1, aRow2 ) => {
            let result = 0
            if ( aRow1 && aRow2 ) {
                for (let i = 0; i < aRow1.length; i++) {
                    bDebug && console.log(aRow1[i].PARENT_KUSER);
                    bDebug && console.log((aRow2[i] || {}).PARENT_KUSER || 0);

                    let fullName1 = ((aRow1[i] || {}).FULL_NAME || '') + '';
                    let fullName2 = ((aRow2[i] || {}).FULL_NAME || '') + '';

                    if ( fullName1 < fullName2 ) {
                        return -1;
                    } else if ( fullName1 > fullName2 ) {
                        return 1;
                    } else {
                        let username1 = ((aRow1[i] || {}).USERNAME || '') + '';
                        let username2 = ((aRow2[i] || {}).USERNAME || '') + '';

                        if (username1 < username2 ) {
                            return -1;
                        } else if ( username1 > username2 ) {
                            return 1;
                        } else {
                            result = 0;
                        }
                    }
                }
                return result;
            } else {
                return 0;
            }
        };

        const addRowSpan = ( aoRow, nRow, aaoRow ) => {

            const aoRowPrec = aaoRow[nRow-1];
            
            for ( let i = 0; i < aoRow.length; i++ ) {
                const oCellRigaAttuale = aoRow[i];
                const oCellRigaPrec    = ( aoRowPrec || [] )[i] || {};
                const sCellRigaAttualeUSERNAME = oCellRigaAttuale.USERNAME || ''
                const sCellRigaPrecUSERNAME    =    oCellRigaPrec.USERNAME || ''

                if ( sCellRigaAttualeUSERNAME === sCellRigaPrecUSERNAME ) {
                    oCellRigaAttuale.nRowSpan = ( oCellRigaPrec.nRowSpan || 0 ) + 1;
                } else {
                    oCellRigaAttuale.nRowSpan = 1;
                }
            }

            return aoRow;
        };

        const creaCella = ( oCella, nCella, aoCelle ) => {
            bDebug && console.log(`CreaCella ${ oCella.FULL_NAME }`);

            const isToRender = aoCelle[nCella] !== aoRigaPrec[nCella];
            if ( nCella === ( aoCelle.length - 1 ) ) {
                aoRigaPrec = aoCelle;
            }

            return ( oCella && isToRender && <td
                    key={ `${oCella.KUSER} ${oCella.FULL_NAME} cell` }
                    className={ `${( oCella.isLeaf ? 'isLeaf ' : '' )} ${( 'nRowSpan' + ( oCella.nRowSpan || '' ) )} ${( oCella.isRadix ? ' isRadix' : '' )} ${ (userToSearch?.USERNAME || '' ) === oCella.USERNAME ? 'found' : '' } ` }
                    rowSpan={( oCella.nRowSpan || '' ) }
                >
                <div className="wrapperCell">
                    
                    <div key={`StartButton`} className='cellUserButton' >
                        <Tooltip title='Modifica elemento superiore' placement='top' arrow>
                            <IconButton className='addUser' onClick={ () => openPopupSelectUser({ KUSER: oCella.KUSER, sButton: 'first' }) } >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Rimuovi elemento superiore e rendi radice della gerarchia' placement='bottom' arrow>
                            <IconButton className="removeUser" onClick={ () => removeUserFromTreeGerArk({ KUSER: oCella.KUSER }) }>
                                <DoNotDisturbOnOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <div className="cellUser">
                        {
                            oCella.FLAG_ATTIVO === 'Y' ?
                                oCella.FLAG_ADMIN === 'Y' ?
                                    <SupervisorAccountIcon className={"textGrey"} />
                                  : <UserIcon              className={"textGrey"} />
                              : <DoDisturbIcon className={"textRed activeFlag"}/>
                        }
                        <span id={`${ oCella.USERNAME }`}>
                            <Tooltip title={`${ oCella.FULL_NAME }`} placement='top-start'>
                                <div>{ oCella.FULL_NAME }</div>
                            </Tooltip>
                            <Tooltip title={`${ oCella.USERNAME }`} placement='bottom-start'>
                                <div>{ oCella.USERNAME }</div>
                            </Tooltip>
                        </span>
                    </div>

                    <div key={ `${oCella.KUSER} ${oCella.FULL_NAME} Button` } className="cellUserButton">
                        <Tooltip title='Aggiungi elemento inferiore' placement='top' arrow>
                            <IconButton className='addUser' onClick={ () => openPopupSelectUser({ KUSER: oCella.KUSER, sButton: 'last' }) }>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                </div>
            </td>);
        };

        const creaRigaRicorsivamente = ( oRow ) => {
            try {
                if ( !oRow.PARENT_KUSER ) {

                    oRow.isLeaf = !aoUsers.some( o => o.PARENT_KUSER === oRow.KUSER );
                    oRow.isRadix = true;
                    return [ oRow ];
                } else {

                    let oRowParent = null;
                    let isLeaf = false;
                    aoUsers.forEach( oActualRow => {
                        if ( oRow.PARENT_KUSER === oActualRow.KUSER ) {
                            oRowParent = oActualRow;
                        }
                        isLeaf       = !aoUsers.some( o => o.PARENT_KUSER === oRow.KUSER );

                        oRow.isLeaf = isLeaf;
                    });
                    return ( oRowParent && [ ...creaRigaRicorsivamente( oRowParent ), oRow ] );
                }
            } catch(e) {
                console.error(e);
                return [];
            }
        };

        const creaRiga = ( aRow ) => {
            const rigaWithLeaf = aRow && aRow.find( o => o.isLeaf );
            return ( rigaWithLeaf?.KUSER ? aRow : null )
        };

        const creaRigaHTML = ( aoCelle ) => {
            return (
                <tr key={`${aoCelle[ aoCelle.length -1 ].KUSER} Row`} >
                    { aoCelle.map(creaCella) }
                </tr>
            )
        };

        const gerarchiaTable = () => {
            bDebug && console.log(aoUsers.map( creaRigaRicorsivamente )
            .sort( sortingRow )
            .map( creaRiga )
            .filter( Boolean ));
            return (
                <table className="gerarchiaTable">
                    <tbody>
                        {
                            aoUsers
                                .map( creaRigaRicorsivamente )
                                .sort( sortingRow )
                                .map( creaRiga )
                                .filter( Boolean )
                                .map( addRowSpan )
                                .map( creaRigaHTML )
                        }
                    </tbody>
                </table>
            )
        };

        const searchUserGerarchia = () => {
            bDebug && console.log(userToSearch);
            let utenteTrovato = userToSearch;

            if ( utenteTrovato ) {
                document.getElementById(`${ utenteTrovato.USERNAME }`).scrollIntoView({ behavior: "auto", block: "center" });
            } else {
                set_oNotifyOptions({ message: `Utente non trovato`, severity:'warning', timeout: 3 })
            }
        };
        
        useEffect(() => {
            if ( sPopupType === '' ) {
                set_sButtonState('');
            }
        },[ sPopupType ]);

        useEffect(() => {
            if ( userToSearch ) {
                searchUserGerarchia();
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[ userToSearch ]);

    /* #endregion */

    /* #region ModUtente */
        // const oFormatRole        = { 'Y': 'Admin', 'N': 'User', 'Admin': 'Y', 'User': 'N' };
        // const oFormatVerified    = { 'Y': 'Email verificata', 'N': 'Email non verificata' };
        const oMapLabelAndValues = {
             'USERNAME'             : { label: 'Utente'                  ,enabled: false }
            ,'FULL_NAME'            : { label: 'Nome'                    ,enabled: true  }
            ,'FLAG_ADMIN'           : { label: 'Admin'                   ,enabled: true  } /* ,format: val => oFormatRole[val]  */
            ,'FLAG_TRAFFICKER'      : { label: 'Trafficker'              ,enabled: true  } /* ,format: val => oFormatRole[val]  */
            ,'AGENTE_COD'           : { label: 'Codice Agente'           ,enabled: true  ,format: val => val ? val.replace(/[^a-zA-Z0-9]/g, '') : '' }
            // ,'FLAG_COGNITO'         : { label: 'Cognito'                 ,enabled: false ,format: val => oFormatVerified[val]}
            ,'FLAG_ATTIVO'          : { label: 'Attivo'                  ,enabled: true  }
            ,'DATA_INSERIMENTO'     : { label: 'Data Creazione'          ,enabled: false ,format: val => formatExecution(val) }
            ,'DATA_ULTIMA_MODIFICA' : { label: 'Data Ultima Modifica'    ,enabled: false ,format: val => formatExecution(val) }
            ,'DATA_ULTIMA_LOGIN'    : { label: 'Data Ultima Connessione' ,enabled: false ,format: val => formatLogin(val)     }
            ,'DATA_CANCELLAZIONE'   : { label: 'Data Disabilitazione'    ,enabled: false ,format: val => formatDisable(val)   }/* FLAG_ATTIVO scatena il cambio di stato di DATA_CANCELLAZIONE */
            // ,'NOME'                 : { label: 'Name'                    ,enabled: true  }
            // ,'EMAIL_ADDRESS'        : { label: 'Email'                   ,enabled: true  }
        };

        const onClickEdit     = ( oRow ) => {
            set_oDialogOptions({ oRow });
            set_bShowDialog(true);
        };
    /* #endregion ModUtente */
    
    useEffect(() => {
        /*
            NON BISOGNA PAGINARE GLI UTENTI !!!
        */
        setLoading(true);
        
        bAdminUser && ( async () => {
            try {
                const response = await axios.get( config.API_URL + '/get', { headers, params : { "getUsersParams": true } } );
                if ( response.status === 200 ) {
                    
                    const result = response.data;
                    
                    set_dLastUpdate( result.aoRecords[0].MAX_LAST_EXECUTION || '' );
                    
                    if ( result ) {
                        let aoRecords = result.aoRecords || [];
                        /*
                            // Moltiplichiamo i record per allungare la tabella
                            let temp = [];
                            for (let i = 0; i <= aoRecords.length; i++) {
                                temp[i] = { 'USERNAME': i};
                            }
                            aoRecords = aoRecords.concat(temp);
                        */
                        
                        set_aoUsers(aoRecords);
                    }
                    
                }
            } catch(err) {
                console.error('ERR 130: Failed GetUsers: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            }
            setLoading(false);
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);

    useEffect( () => {
        if ( loading === false && sVerifiedTarget ) {
            bDebug && console.log('useEffectLoading');
            handleScroll( sVerifiedTarget );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ loading ]);

    return (
        <div className="component-container users">
            <div className="component-card">
                
                { loading ? <CircleProgress /> :
                  
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={nValueTab}>
                        
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <CustomCssTabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                <CustomCssTab label="Elenco"    value="1" />
                   { !isProd && !isDemo && <CustomCssTab label="Gerarchia" value="2" /> }
                            </CustomCssTabList>
                        </Box>
                        
                        <TabPanel value="1" className="tabPanel elenco">
                            
                            { /* ----- popup di modifica dati utente ----- */ }
                            { !bShowDialog ? null : <UserEditing
                                options ={{ bShowDialog, set_bShowDialog, oDialogOptions, loading, setLoading,
                                    sTitle: 'Profilo Utente', oMapLabelAndValues, reload, toggleNotifica }}
                            /> }
                            
                            <div className='component-options-wrapper-title'>
                                <Button className="add-user-btn" variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={goToUserCreation}>
                                    Crea Nuovo Utente
                                </Button>

                                <div>
                                    { ['allarmi', 'voci_menu'].includes(sTableType) && <span className='users-tooltip-wrapper'>
                                        <CustomMaxWidthTooltip
                                            title={
                                                <>
                                                    Gerarchia Utenti
                                                    <br/>Gli Utenti Admin hanno sempre visibilità su tutte le voci di menu e su tutti gli allarmi.
                                                    <br/>Tutti gli altri Utenti possono accedere solamente alle voci di menu e allarmi selezionati.
                                                </>
                                            }
                                            placement="right"
                                            arrow
                                        >
                                            <InfoIcon className={'infoIcon'}/>
                                        </CustomMaxWidthTooltip>
                                    </span> }

                                    <ButtonGroup variant="outlined" disableElevation aria-label="outlined button group" className='buttonGroup'> { /*sx={{ borderRadius: '5px'}} */ }
                                        <Button 
                                            className   ={`graphButton link myShadow ${ sTableType === 'date' ? 'activedButton' : '' }`}
                                            title       ="Mostra Date"
                                            variant     ="outlined"
                                            onClick     ={ () => { settingTable('date'); } }
                                            disableElevation
                                        >
                                            <CalendarClockIcon />
                                        </Button>
                                        <Button
                                            className   ={`graphButton link myShadow ${ sTableType === 'allarmi' ? 'activedButton' : '' }`}
                                            title       ="Mostra Allarmi"
                                            variant     ="outlined"
                                            onClick     ={ () => { settingTable('allarmi'); } }
                                            disableElevation
                                        >
                                            <AlertIcon />
                                        </Button>
                                        <Button
                                            className   ={`graphButtonLast link myShadow ${ sTableType === 'voci_menu' ? 'activedButton' : '' }`}
                                            title       ="Mostra Voci di Menu"
                                            variant     ="outlined"
                                            onClick     ={ () => { settingTable('voci_menu'); } }
                                            disableElevation
                                        >
                                            <MenuIcon />
                                        </Button>
                                    </ButtonGroup>
                                </div>    
                            </div>
                            
                            <div className="component-options-wrapper">
                            {
                                loading ? <></> : <>{
                                    aoUsers?.length && aoUsersHeaders?.length ? 
                                        <SimpleTable
                                            chiave            ={ 'USERS' }
                                            sTableDataType    ={ 'USERS' }
                                            aoRows            ={ aoUsers }
                                            aoCols            ={ aoUsersHeaders }
                                            isEditable        ={ true }
                                            oRowOptions       ={{ addClass: (oRow) => ( oRow['FLAG_ATTIVO'] || '' ) === 'N' ? ' user-disabled ' : '' }}
                                            editFunction      ={ onClickEdit }
                                            oHeadersOptions   ={ { tooltip: true, breakline: true } }
                                            oSortOptions      ={ { isSortable: true } }
                                            oExportOption     ={ { sFileName: `Utenti` } }
                                        />
                                    : <div className="component-options-wrapper-alarmNoRecord">
                                        <FolderOffIcon />&nbsp; Nessun risultato trovato
                                    </div>
                                }</>
                            }
                            </div>
                            
                        </TabPanel>
                        
                        <TabPanel value="2" className="tabPanel gerarchia">
                            {
                               ( sPopupType !== '' )
                                    ? <PopupConfirm { ...{
                                        oPopupOptions: (sPopupType === 'removeUser') 
                                                          ? foPopupOptionsRemove() 
                                                          : oPopupOptionsModify, sPopupType, set_sPopupType
                                    } } />
                                    : null
                            }
                            <div className="searchFieldWrapper">
                                <Autocomplete
                                    autoComplete
                                    freeSolo
                                    clearOnBlur
                                    id             = "combo-box-usersGerarchia"
                                    options        = {aoUsers}
                                    getOptionLabel = {( option                  => option.FULL_NAME + ' (' + option.USERNAME + ')' )}
                                    value          = { userToSearch }
                                    onChange       = { (event, newValue)        => set_userToSearch(newValue) }
                                    inputValue     = { userToSearchInputValue }
                                    onInputChange  = { (event, newInputValue)   => set_userToSearchInputValue(newInputValue)}
                                    sx             = {{ width: 700 }}
                                    renderInput    = {(params)                  => <CustomCssTextField {...params} label= "Cerca" />}
                                />
                            </div>
                            <div className="gerarchiaTableWrapper">{gerarchiaTable()}</div>
                        </TabPanel>
                        
                    </TabContext>
                </Box>
                
                }
            </div>
        </div>
    )
};

export default Users;
