import React, { useState, useEffect, useContext }   from 'react';
import { Link }                                     from 'react-router-dom';
import axios                                        from 'axios';
// import moment                                       from 'moment';
// import 'moment/locale/it';

import './Anagrafiche.css';
import config                                       from '../../config.js';
import { AuthContext }                              from '../../contexts/authContext';
import { AppContext }                               from '../../App.js';
import { SimpleTable }                              from '../../components/SimpleTable/SimpleTable';

import CircleProgress                               from '../../components/CircleProgress/CircleProgress.tsx';
import utils                                        from '../../util/CommonUtilities';

import { DoubleArrowRightIcon
       , FolderOffIcon }                            from '../../assets/icons/icons.js';
import { Select 
       , InputLabel
       , FormControl 
       , MenuItem
       }  from '@mui/material';

// moment.locale('it');
const bDebug = config.DEBUG && false;

const Anagrafiche = (props) => {
    /* #region costanti di supporto */
    
        const isSole = process.env.REACT_APP_ENV.includes('sole');
        const isDemo = process.env.REACT_APP_ENV.includes('demo')
        const auth = useContext(AuthContext);
        const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);

        // Variabile per contenere l'elenco di anagrafiche che possono avere associato una pagina di dettaglio
        const asMappingAnagrafiche = [ 'AD_UNITS', 'LINE_ITEMS', 'PLACEMENTS' ];

        // Funzioni di formattazione campi tabella
        const formatAnagName  = val => utils.formatNamePage(val);
        const formatNum0dec   = val => 
            <span className={`${ val > 0 ? 'textGreen' : 'textRed' }`} >
                { utils.formatNumberWithOptions(val) }
            </span>;
        const formatLink      = ( val, oRow ) => 
            <Link to={`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/${val}`} className='link'>
                <DoubleArrowRightIcon/>
            </Link >;

    /* #endregion */

    const [ loading              ,setLoading               ] = useState(true);
    const [ aoAnagrafiche        ,set_aoAnagrafiche        ] = useState([]);
    const [ aoAnagraficheHeaders ,set_aoAnagraficheHeaders ] = useState([]);
    const [ accDesc              ,setAccDesc               ] = useState(isDemo ? 'ilsole24ore' : 'dvn' );
    const [ accountList          ,setAccountList           ] = useState([ ( isDemo ? 'ilsole24ore' : 'dvn' ) ]);

    const handleAccount = ( value ) => {
        if ( value !== accDesc ) {
            setAccDesc(value);
        }
    };

    useEffect(() => {
        setLoading(true);
        set_dLastUpdate('');
        // set_aoAnagrafiche([]);
        
        ( async () => {
            try {
                const response = await axios.get(
                    config.API_URL + '/get',
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                        params  : { getAnagraficheParams : true }
                    }
                )
                if ( response?.status === 200 && response?.data?.aoRecords?.length ) {
                    let aoRecords = response.data.aoRecords || [];
                    if ( !isSole ) {
                        const asAccounts = [...new Set (aoRecords.map((account) => account.ACCOUNT_DESC ))];
                        bDebug && console.log('asAccounts', asAccounts)
                        setAccountList(asAccounts)
                        aoRecords = aoRecords.filter( oRecord => oRecord.ACCOUNT_DESC === accDesc );
                    }
                    
                    aoRecords = aoRecords.filter( oRecord => asMappingAnagrafiche.includes( oRecord.SUBCATEGORIA ));
                 
                    // Function per sommare gli archived con i non archived
                    const sumArchived = (data) => { 
                        let lineItemsSum  = 0;
                        let ordersSum  = 0;

                        // Ci salviamo i valori archived
                        data.forEach(element => {
                            if (element.SUBCATEGORIA === 'LINE_ITEMS_ARCHIVED') {
                                lineItemsSum  = element.NUM_ELEM;
                            }

                            if (element.SUBCATEGORIA === 'ORDERS_ARCHIVED') {
                                ordersSum  = element.NUM_ELEM;
                            }
                        });
                        
                        // Aggiungiamo i valori archived ai non archived
                        data.forEach(element => { 
                            if (element.SUBCATEGORIA === 'LINE_ITEMS' ) {
                                element.NUM_ELEM += lineItemsSum ;
                            }
                            
                            if (element.SUBCATEGORIA === 'ORDERS' ) {
                                element.NUM_ELEM += ordersSum ;
                            }
                        });
                    }

                    sumArchived(aoRecords);

                    set_dLastUpdate( aoRecords[0]?.MAX_LAST_EXECUTION || '' );
                    let aoAnagHead = [
                        { name: 'SUBCATEGORIA' ,title: 'Anagrafica' ,width: 200 ,format: formatAnagName ,isUniqueKeyForRow: true }
                       ,{ name: 'NUM_ELEM'     ,title: ''           ,width: 80  ,format: formatNum0dec  ,isNum:             true }
                       ,{ name: 'SUBCATEGORIA' ,title: ''           ,width: 80  ,format: formatLink     ,notSortable:       true }
                    // ,{ name: 'LAST_EXECUTION' ,title: 'Eseguito' ,width: 310 ,format: ( val, oRow ) => oRow.FLAG_ENABLED !== 'S' ? null : val }
                    ];
                    set_aoAnagraficheHeaders(aoAnagHead);
                    set_aoAnagrafiche(aoRecords);
                } else {
                    throw new Error('Nessuna risposta');
                }          
            } catch(err) {
                console.error('ERR 190: Failed GetAnagrafiche: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' })
            }
            setLoading(false)
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps        
    },[ accDesc ]);


    return (
        <div className="component-container anagrafiche">
            <div className="component-card">
                { !( isSole || isDemo ) &&
                  <FormControl>
                   <InputLabel id="GAM-Account-select-label" className='smallMarginLeft' >GAM Account</InputLabel>
                    <Select
                        id             = "selectAccount"
                        key            = "selectAccount"
                        label          = "GAM Account"
                        labelId        = "GAM-Account-select-label"
                        value          = { accDesc }
                        onChange       = {(event) => handleAccount( event.target.value )}
                        className      = 'mybordercolor accSelect'
                    >
                        { accountList.map((account) => (
                            <MenuItem key={account} value={account}>
                               {account === "dvn" ? account.toUpperCase() : account.charAt(0).toUpperCase() + account.slice(1)}
                            </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                }
                <div className="component-options-wrapper">
                {
                    loading ? <CircleProgress /> : <>{
                        ( aoAnagrafiche?.length && aoAnagraficheHeaders?.length ) 
                        ? <SimpleTable
                              chiave            = { 'ANAGRAFICHE' }
                              sTableDataType    = { 'ANAGRAFICHE' }
                              aoRows            = { aoAnagrafiche }
                              aoCols            = { aoAnagraficheHeaders }
                            //   oSortOptions      = { { isSortable: false } }
                              oExportOption     = { { sFileName: 'Anagrafiche' } }
                              noFilter          = { true }
                              randomizeOnlyNumbersForDemo = { true }
                          />
                        : <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp; Nessun risultato trovato </div>
                    }</>
                }
                </div>
            </div>  
        </div>
    )
};
 
export default Anagrafiche;
