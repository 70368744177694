import React from 'react'
import utils                        from '../../../util/CommonUtilities'; 


const AnagrafichePercentuale = ({ perc, reusingComponent=false }) => {
    const ACTUAL_PROG_PERC = perc.ACTUAL_PROG_PERC; //Attualmente sto utilizzando questa per renderizzare la percentuale 
    const OBJ_PROG_PERC = perc.OBJ_PROG_PERC;
    const PACING = perc.PACING; // Linea 
    const DELIVERY_ESTIMATED_PERC = perc.DELIVERY_ESTIMATED_PERC;
  
    let formatNum1decPerc = (val) => { return `${ utils.formatNumberWithOptions(val, { nOuputDecimals: 1 }) }` }

    const PACING_LIMIT = reusingComponent === false ? 98 : 91;
    const PACING_MIN   = reusingComponent === false ? 1.5: 7;
   
    const getPacing = (PACING) => {
      return PACING > PACING_LIMIT ? PACING_LIMIT 
      : PACING < PACING_MIN ? PACING_MIN 
      : PACING 
    };
    let adjustedPacing = getPacing(PACING);

    let ValLinea = ACTUAL_PROG_PERC * (PACING/100);

    let ValLineaAdjusted = getPacing(ValLinea);





    // funzione che genera il div parametrizzato
    const creadiv = ( color ) => <div className='percentage'
    style={{
        background: `linear-gradient(90deg, ${color} ${ACTUAL_PROG_PERC}%, white ${ACTUAL_PROG_PERC}%)`
      }}>
        <div className='percentagePacing' style={{left: `${ValLineaAdjusted}%`}}></div>
    </div>
      
    // dichiarazione colori
    const color =
      DELIVERY_ESTIMATED_PERC < 100 ?  ' var(--color-redlight)' : 'var(--color-greenperc)'  

    return (
        // <div title={`PACING: ${formatNum1decPerc(PACING)} % \nDELIVERY ESTIMATED: ${formatNum1decPerc(DELIVERY_ESTIMATED_PERC)} %` }>
        !ACTUAL_PROG_PERC ?  <></> 
             :
            <div>
            {
                creadiv( color )
            }
        </div>
      
    );
}
  

export default AnagrafichePercentuale