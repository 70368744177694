import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import './assets/fonts/CourierPrime/CourierPrime-Regular.ttf';
import './assets/fonts/CourierPrime/CourierPrime-Bold.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <App />
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//se wrappa App renderizza tutto due volte
//è intenzionale lato react e accade solo in dev envù
//secondo https://stackoverflow.com/questions/48846289/why-is-my-react-component-is-rendering-twice serve per trovare eventuali
//effetti collaterali accidentali nella fase di render
/* <React.StrictMode> */
/* </React.StrictMode> */
