import React, {useEffect, useState, useContext }        from "react";

import FormControl                 from '@mui/material/FormControl';
import InputLabel                  from '@mui/material/InputLabel';
import Select                      from '@mui/material/Select';
import MenuItem                    from '@mui/material/MenuItem';
import Button                      from '@mui/material/Button';
import IconButton                  from '@mui/material/IconButton';
import ButtonGroup                 from '@mui/material/ButtonGroup';
import axios                       from "axios";
import moment                      from 'moment';

import './Dashboard.css'
import config                      from '../../config.js';
import { AuthContext }             from '../../contexts/authContext';
import { AppContext }              from '../../App.js';
import { SimpleTable }             from '../../components/SimpleTable/SimpleTable'; 
import Graph                       from '../../components/Graph/Graph';
import CircleProgress              from '../../components/CircleProgress/CircleProgress.tsx';
import utils                       from '../../util/CommonUtilities';
import { CustomCssInput }          from "../../components/CustomCSS/CustomCSS.tsx";

import  { CachedIcon
        , FolderOffIcon
        , LeaderboardIcon
        , SsidChartIcon
        , TableChartIcon }         from '../../assets/icons/icons.js';
        
const bDebug = config.DEBUG && false;

const DashBoard = (props) => {

    // const bEnv = process.env.REACT_APP_ENV.includes('sole');
    // const auth = useContext(AuthContext);
    // const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);
    // const headers = {
    //     'Accept'        : 'application/json',
    //     'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    // };
    // const apAccountDesc = [ 'Tutti gli account', 'dvn', 'eurosport', 'youtube' ];

    // const
    //      [ loading            ,setLoading             ] = useState(false)
    //     ,[ onReload           ,set_onReload           ] = useState(null) // State per triggerare il lancio della chiamata
    //     ,[ aoRecordsFromDB    ,set_aoRecordsFromDB    ] = useState([])  // State per contenere i record della tabella dettaglio ottenuti direttamente dal DB
    //     // ,[ aoDashBoard        ,set_aoDashBoard        ] = useState(null)
    //     ,[ aoDashBoardHeaders ,set_aoDashBoardHeaders ] = useState([])
    //     ,[ sGraphEnabled      ,set_sGraphEnabled      ] = useState('') // State per Identificare se si vuole visualizzare il Grafico a Linee ('line'), a Barre ('bar') oppure la tabella ('')
    //     ,[ pAccountDesc       ,set_pAccountDesc       ] = useState('')
    //     ,[ bSelectAccountOnFocus, set_bSelectAccountOnFocus ] = useState(false) // State per scatenare l'aggiunta di classe alla Select
    // ;

    // /* #region Format */
    //     const
    //          sGAMurl               = config.GAM_URL
    //         // ,{  pMMFastDays ,pMMSlowDays ,pMMFastPendDays } = oValues || oGetParams
    //         // ,formatZero            = (val) => val === 0 ? 'zero' : ''
    //         // ,formatNum2decPerc     = (val) => { return `${ utils.formatNumberWithOptions(val, { nOuputDecimals: 2 }) } %` }
    //         // ,formatPercentage      = (val, oRow ) => <div><AnagrafichePercentuale perc={{ACTUAL_PROG_PERC: oRow.ACTUAL_PROG_PERC, OBJ_PROG_PERC: oRow.OBJ_PROG_PERC, PACING: oRow.PACING, DELIVERY_ESTIMATED_PERC: oRow.DELIVERY_ESTIMATED_PERC, otherProp: val}} /></div>
    //         ,formatNum0dec         = (val) => utils.formatNumberWithOptions(val, { nOuputDecimals: 0 })
    //         // ,formatDate            = (val) => moment(val, 'YYYYMMDD').format('D MMM YYYY')
    //         // ,formatLineItemLink    = (val, oRow ) => <a href={ sGAMurl + oRow.ACCOUNT_CODE +`#delivery/line_item/detail/line_item_id=${ val }`}  target='_blank' className='myExternalLinkID' rel='noreferrer'>{val}</a>
    //         // ,formatPlacementLink   = (val, oRow ) => <a href={ sGAMurl + oRow.ACCOUNT_CODE +`#inventory/placement/detail/placement_id=${ val }`} target='_blank' className='myExternalLinkID' rel='noreferrer'>{val}</a>
    //         // ,formatAdUnitLink      = (val, oRow ) => <a href={ sGAMurl + oRow.ACCOUNT_CODE +`#inventory/ad_unit/detail/ad_unit_id=${ val }`}     target='_blank' className='myExternalLinkID' rel='noreferrer'>{val}</a>
    //         // ,formatUpAndDown       = (val) => val !== 'Y' ? <div className='trend'><TrendingUpIcon addClass={"textGreen"} /></div> : <div className='trend'><TrendingDownIcon addClass={"textRed"} /></div>
    //         ;
    // /* #endregion */

    // // !!! TODO è inutile avere due state [ aoDashBoard, aoRecordsFromDb ] che contengono la stessa informazione. Per ottenere lo stesso risultato sarebbe sufficiente associare 

    // /* #region FunctionCallToDB */
    // const getDetailTable = () => {
    //     bDebug && console.log('GetDetailTable');

    //     set_aoDashBoardHeaders([
    //          { name: 'ACCOUNT_DESC'        , title: 'ACCOUNT'              , width: 100   , format: val => val.toUpperCase()        , filterOriginalValue: true , isUniqueKeyForRow: true  }
    //         ,{ name: 'DATA_1'              , title: '8 Maggio'             , width: 100   , format: formatNum0dec   , isNum:   true , filterOriginalValue: true }
    //         ,{ name: 'DATA_2'              , title: '15 Maggio'            , width: 100   , format: formatNum0dec   , isNum:   true , filterOriginalValue: true }
    //         ,{ name: 'DATA_3'              , title: '22 Maggio'            , width: 100   , format: formatNum0dec   , isNum:   true , filterOriginalValue: true }
    //         ,{ name: 'DATA_4'              , title: '29 Maggio'            , width: 100   , format: formatNum0dec   , isNum:   true , filterOriginalValue: true }
    //     ]);

    //     setLoading(false);
    // };

    // const getDashBoardFromDB = async () => {
    //     bDebug && console.log('getDashBoardFromDB');
    //     setLoading(true);
    //     set_aoRecordsFromDB([]);
    //     try {
    //         const response = await axios.get( config.API_URL + '/get', { headers, params: { getDashBoardParams: true, pAccountDesc: pAccountDesc === 'Tutti gli account'? '' : pAccountDesc } } ); // !!! TODO Bisognerà implementare una Select
    //         if ( response && ( response.status === 200 ) && response?.data?.aoRecords?.length ) {
    //             // !!! TODO set_dLastUpdate();
    //             bDebug && console.log(response.data.aoRecords);
    //             set_aoRecordsFromDB([...response.data.aoRecords]);

    //         } else {
    //             setLoading(false);
    //         }
    //     } catch (err) {
    //         console.error('ERR 170: Failed getDashBoardFromDB: ', err);
    //         set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
    //         setLoading(false);
    //     }
    // };
    // /* #endregion */ 
    
    // /* #region UseEffect */

    // // UseEffect da scatenare appena atterrati nella pagina
    // useEffect(() => {
    //     set_dLastUpdate('');
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // // UseEffect da scatenare una volta ottenuti i dati dal DB per assicurarci di settare il loading a false solo dopo aver ottenuto la risposta dal DB
    // /* !!! TODO è SICURO che una volta implementata la chiamata al db questa useEffect sarà da rivedere perchè setterà loading a false prima di aver ottenuto i dati.
    //     Possibili correzioni:
    //     1 - implementare uno state che, all'interno della funzione che chiama il db, viene settato a true dopo aver ricevuto una qualunque response
    //     2 - Forse setare inizialmente aoDashBoard a null e controllare che sia != null è sufficiente
    // */
    // useEffect(() => {
    //     bDebug && console.log('UseEffect aoDashBoard');
    //     if ( aoRecordsFromDB.length ) {
    //         if ( !sGraphEnabled ) {
    //             getDetailTable();
    //         } else {
    //             return;
    //         }
    //     };
         
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ aoRecordsFromDB ]);

    // // Use effect per scatenare il ricarico della pagina e appena selezionato il pAccountDesc per lanciare la chiamata al DB
    // useEffect( () => {
    //     bDebug && console.log('UseEffect pAccountDesc');
    //     set_dLastUpdate('');
    //     getDashBoardFromDB();
    //         // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ pAccountDesc ]);

    // // Use effect per scatenare il ricarico della pagina e appena selezionato il pAccountDesc per lanciare la chiamata al DB
    // useEffect( () => {
    //     bDebug && console.log('UseEffect OnReload');
    //     if ( onReload != null ) {
    //         set_dLastUpdate('');
    //         getDashBoardFromDB();
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ onReload ]);

    // /* #endregion */
    
    // /* #region SettingGraph */
    // // Questa function mi serve per controllare se l'utente sta premendo su un bottone già attivo, nel qual caso non scateno il cambio di stato
    // const settingGraph = (field) => {
    //     if ( field !== sGraphEnabled ) {
    //         set_sGraphEnabled( field );
    //     }
    // };

    // const oMappingField = {
    //     'BACINO': 'Bacino'
    //    ,'DATA_RIF': 'Data Riferimento'
    //    ,'BACINO_MM_FAST': 'Bacino Media Veloce'
    //    ,'BACINO_MM_SLOW': 'Bacino Media Lenta'
    // };
    // /* #endregion */ 

    // const myKeyPress = (e) => {
    //     bDebug && console.log('MyKeyPress: ', e.key);
    //     bDebug && console.log(e);
    //     if (e.key === 'Enter') {
    //         e.preventDefault();
    //         set_onReload(!onReload);
    //     }
        
    //     if ( e.key === 'Escape' ) {
    //         e.preventDefault();
    //     }
    // };

    return <div className="component-container dashboard" /*onKeyDown={ (e) => myKeyPress(e) }*/>
        <div className="component-card">
            {/*<div className="component-options-wrapper">
                 <div  className="component-options-wrapper-title">
                    <FormControl>
                        {/* <InputLabel id="demo-simple-select-helper-label" className={`${ bSelectAccountOnFocus ? 'selectlabelOnFocus' : '' }`}>Seleziona Account</InputLabel> */}
                        {/*}
                        <Select
                            id="selectAccount"
                            value={ pAccountDesc }
                            nullable = 'true'
                            label="Seleziona Account"
                            onChange={ event => { set_pAccountDesc(event.target.value); }}
                            onFocus={ () => set_bSelectAccountOnFocus(true)}
                            onBlur ={ () => set_bSelectAccountOnFocus(false)}
                            className={`mybordercolor ${ bSelectAccountOnFocus ? 'onFocus' : '' }`}
                        >
                            { 
                                apAccountDesc
                                    .map( pAccountDesc => <MenuItem key={pAccountDesc} value={pAccountDesc}>{ pAccountDesc.toUpperCase() }</MenuItem> )
                            }
                        </Select>
                    </FormControl>
                </div>
                <div  className="component-options-wrapper-title">*/}
                    {/* <ButtonGroup variant="outlined" disableElevation aria-label="outlined button group" className='buttonGroup'>
                        <Button className={`graphButton link myShadow ${ sGraphEnabled === 'line' ? 'activedButton' : '' }`} title="Mostra Grafico Lineare" variant="outlined" onClick={ () => settingGraph('line') } disableElevation>
                            <SsidChartIcon />
                        </Button>
                        <Button className={`graphButton link myShadow ${ sGraphEnabled === 'bar' ? 'activedButton' : '' }`} title="Mostra Grafico a Barre" variant="outlined" onClick={ () => settingGraph('bar') } disableElevation>
                            <LeaderboardIcon />
                        </Button>
                        <Button className={`graphButton link myShadow ${ sGraphEnabled === '' ? 'activedButton' : '' }`} title="Mostra Tabella" variant="outlined" onClick={ () => settingGraph('') } disableElevation>
                            <TableChartIcon />
                        </Button>
                    </ButtonGroup> */}
                    {/*}
                    <IconButton className="reloadButton link myShadow" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ () => set_onReload(!onReload) }>
                        <CachedIcon />
                    </IconButton>
                </div>
            </div>
            {
                loading ? <CircleProgress /> : <>{
                    aoRecordsFromDB?.length && Array.isArray(aoRecordsFromDB) && aoDashBoardHeaders?.length
                    ? sGraphEnabled 
                        ? <Graph oGraphContent={{ sChartType: sGraphEnabled ,aoRows: ( aoRecordsFromDB || [] ) ,asElementX: [ 'DATA_RIF' ] ,asElementY: [ 'BACINO' ,'BACINO_MM_FAST' ,'BACINO_MM_SLOW' ] , oMappingField }} className='grafico'/>
                        : <SimpleTable
                            chiave            ={ 'DASHBOARD' }
                            sTableDataType    ={ 'DASHBOARD' }
                            aoRows            ={ aoRecordsFromDB }
                            aoCols            ={ aoDashBoardHeaders }
                            noFilter          ={ true }
                        />
                    : <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp; Nessun risultato trovato </div>
                }</>
            } */}
        </div>
    </div>;
};

export default DashBoard;