import   React, { useState, useEffect, useContext } from 'react';
import { useParams }                                from 'react-router-dom'; 
import   axios                                      from 'axios';
import   moment                                     from 'moment';
import { nanoid }                                   from 'nanoid';

import { Autocomplete
       , Checkbox
       , FormControl
       , InputLabel
       , MenuItem
       , Select
       , Tooltip }                                   from '@mui/material';
import { LocalizationProvider }                     from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment }                            from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker }                               from '@mui/x-date-pickers/DatePicker';

import   config                                     from '../../../config.js';
import   utils                                      from '../../../util/CommonUtilities';
import { AuthContext }                              from '../../../contexts/authContext';
import { AppContext }                               from '../../../App.js';    
import { PopupConfirm }                             from '../../../components/PopupConfirm/PopupConfirm';
import { CustomCssTextField }                       from '../../../components/CustomCSS/CustomCSS.tsx';
import { AlertIcon     
        ,AlertSlashIcon
        ,FilterIcon }                               from '../../../assets/icons/icons.js';

const bDebug = config.DEBUG && false;

/* #region ObjectXMapping */
    const   isSole    = process.env.REACT_APP_ENV.includes("sole");
    
    // !!! TODO valori statici da rendere dinamici
    const aoValuesSign = [{ value: "<" }, { value: "="}, { value: ">"}];
    const statusDel = [
          "(TUTTI)"                  
        , "ARCHIVED"                 
        , "COMPLETED"                
        , "INACTIVE"                 
        , "PAUSED_INVENTORY_RELEASED"
        , "READY"                    
        , "DELIVERING"               
        , "DRAFT"                    
        , "PAUSED"                   
    ];
    
    const GAMaccount = [
             "DVN"
            ,"Youtube"    
            ,"Eurosport"  
    ];

    const aoLineItemType = [
          "(TUTTI)"        
        , "AD_EXCHANGE"    
        , "BULK"           
        , "CLICK_TRACKING" 
        , "HOUSE"          
        , "NETWORK"        
        , "PREFERRED_DEAL" 
        , "PRICE_PRIORITY" 
        , "SPONSORSHIP"    
        , "STANDARD"       
    ];

    const asPriorityNumbers = [
        '(TUTTI)'
        ,'1' , '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'

    ];

    const
         iconAlert    = <div className='iconWrap'><AlertIcon      className={"textGrey"}/></div>
        ,iconNotAlert = <div className='iconWrap'><AlertSlashIcon className={"textGrey"}/></div>
    ;
    const aoAlertsConditions = [
         { label: '(TUTTI)'  ,value: '' ,key: 'all' ,tooltip: 'Tutti' }  // tutte le righe sono valide
        ,{ label: <span className='alert-flex'>{iconNotAlert}&nbsp; Senza Allarme</span>                       ,value: '<NO_ALERTS>' ,key: 'not-alerted'          ,tooltip: 'Line Item senza Allarmi rilevati'} // Validi solo i record presenti in nessun allarme
        ,{ label: <span className='alert-flex'>{iconAlert   }&nbsp; Con Allarme </span>                        ,value: '%'           ,key: 'only-alerted'         ,tooltip: 'Line Item con almeno un Allarme rilevato'}  // Validi solo i record presenti in almeno un allarme
        ,{ label: <span className='alert-flex'>{iconAlert   }&nbsp; Con Allarme e con Flag Verificato </span>  ,value: '"C":"Y"'     ,key: 'alerted-verified'     ,tooltip: 'Line Item con almeno un Allarme Verificato '}
        ,{ label: <span className='alert-flex'>{iconNotAlert}&nbsp; Con Allarme e senza Flag Verificato</span> ,value: '"C":"N"'     ,key: 'alerted-not-verified' ,tooltip: 'Line Item con almeno un Allarme Non Verificato'}
    ];

/* #endregion ObjectXMapping */

function AnagrafichePopup({utilParam}) {
    /* #region Dichiarazioni */
        // Dichiazioni Generiche   
        const   auth    = useContext(AuthContext);
        const   headers = {
            'Accept'        : 'application/json',
            'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
        };
        let     sPlacementContainerName = localStorage.getItem('sPlacementContainerName') || '';
        const { setLoading, onReload, set_onReload, showConfirmDialog, setShowConfirmDialog } = utilParam;
        const { set_oNotifyOptions, oFilterParamsTotal ,set_oFilterParamsTotal } = useContext(AppContext);
        const { account, dettaglio, idDettaglio } = useParams();
        const { [dettaglio]: oFilterParams } = oFilterParamsTotal;
        const   isLineItem                   = dettaglio === 'LINE_ITEMS';
        const   isAdUnit                     = dettaglio === 'AD_UNITS';
        const   isPlacement                  = dettaglio === 'PLACEMENTS';
        
        const [ pAccountDesc         ,set_pAccountDesc         ] = useState( account === "dvn" ? account.toUpperCase() : account.charAt(0).toUpperCase() + account.slice(1) );
        const [ asLineItemsForSelect ,set_asLineItemsForSelect ] = useState([]);
        const [ asOrderForSelect     ,set_asOrderForSelect     ] = useState([]);
        const [ asADUnitForSelect    ,set_asADUnitForSelect    ] = useState([]);
        const [ asPlacementForSelect ,set_asPlacementForSelect ] = useState([]);
        const [ aoSitoForSelect      ,set_aoSitoForSelect      ] = useState([]);
        const [ keyValue             ,setKeyValue              ] = useState(''); // KEY AUTOCOMPLETE State per aggiornare l'id di Autocomplete al Reset
        const [ isCallToDB           ,set_isCallToDB           ] = useState(false); // questo state non indica che stiamo facendo la chiamata, ma che la chiamata ha già risposto
        const [ hasValue             ,setHasValue              ] = useState(false);
        const [ isDettaglioOnFocus   ,set_isDettaglioOnFocus   ] = useState(null);
        const [ isSelectOnFocus      ,set_isSelectOnFocus      ] = useState({ selectedGAMLabel: false ,pPacingOper: false ,selectSign: false ,selectedStatus: false });
        const [ isResectAction       ,set_isResectAction       ] = useState(null)

        // LINE ITEM  
        const [ pLineItemName     ,set_pLineItemName     ] = useState(                oFilterParams?.pLineItemName  || '' ); // EVER ( isLineItem || isAdUnit || isPlacement )
        const [ pLineItemId       ,set_pLineItemId       ] = useState(   isLineItem ? oFilterParams?.pLineItemId       || '' : '' );
        const [ pOrderName        ,set_pOrderName        ] = useState(   isLineItem ? oFilterParams?.pOrderName        || '' : '' );
        const [ pStatusList       ,set_pStatusList       ] = useState(   isLineItem ? oFilterParams?.pStatusList       || '' : '' );
        const [ pLineItemTypeList ,set_pLineItemTypeList ] = useState(   isLineItem ? oFilterParams?.pLineItemTypeList || '' : '' );
        const [ pPacing           ,set_pPacing           ] = useState(   isLineItem ? oFilterParams?.pPacing           || '' : '' );
        const [ pPacingOper       ,set_pPacingOper       ] = useState(   isLineItem ? oFilterParams?.pPacingOper       || '' : '' );
        const [ pDeliveryEstim    ,set_pDeliveryEstim    ] = useState(   isLineItem ? oFilterParams?.pDeliveryEstim    || '' : '' );
        const [ pDelivOper        ,set_pDelivOper        ] = useState(   isLineItem ? oFilterParams?.pDelivOper        || '' : '' );
        const [ pAlerts           ,set_pAlerts           ] = useState(   isLineItem ? oFilterParams?.pAlerts           || '' : '' );
        const [ pContendingCount  ,set_pContendingCount  ] = useState(   isLineItem ? oFilterParams?.pContendingCount  || '' : '' );
        const [ pContendingOper   ,set_pContendingOper   ] = useState(   isLineItem ? oFilterParams?.pContendingOper   || '' : '' );
        const [ pPriorityList     ,set_pPriorityList     ] = useState(   isLineItem ? oFilterParams?.pPriorityList     || '' : '' );
        const [ pDataFine         ,set_pDataFine         ] = useState(   isLineItem ? oFilterParams?.pDataFine         || '' : '' );
        const [ pDataInizio       ,set_pDataInizio       ] = useState(   isLineItem ? oFilterParams?.pDataInizio       || '' : '' );
        const [ pDataFinePopUp    ,set_pDataFinePopUp    ] = useState(   isLineItem ? moment(oFilterParams?.pDataFine     || '', 'YYYYMMDD' ) : moment(''));
        const [ pDataInizioPopUp  ,set_pDataInizioPopUp  ] = useState(   isLineItem ? moment(oFilterParams?.pDataInizio   || '', 'YYYYMMDD' ) : moment(''));

        // AD UNIT
        const [ pAdUnitId         ,set_pAdUnitId         ] = useState(     isAdUnit ? oFilterParams?.pAdUnitId      || '' : '' );
        const [ pAdUnitName       ,set_pAdUnitName       ] = useState(     isAdUnit ? oFilterParams?.pAdUnitName    || '' : '' );
        const [ pSizes            ,set_pSizes            ] = useState(     isAdUnit ? oFilterParams?.pSizes         || '' : '' );
        const [ pSitoName         ,set_pSitoName         ] = useState(     isAdUnit ? oFilterParams?.pSitoName      || '' : '' );
        const [ pFlagForecast     ,set_pFlagForecast     ] = useState(( isAdUnit && ( oFilterParams?.pFlagForecast === 'Y' ) ) );
        const [ pFlagFoglia       ,set_pFlagFoglia       ] = useState(( isAdUnit && ( oFilterParams?.pFlagFoglia   === 'Y' ) ) );
        
        // PLACEMENT
        const [ pPlacementName ,set_pPlacementName ] = useState((isPlacement || isAdUnit) ? oFilterParams.pPlacementName || '' : '' );
        const [ pPlacementId   ,set_pPlacementId   ] = useState(isPlacement ? oFilterParams.pPlacementId       || '' : '' );
        
    /* #endregion Dichiarazioni */

    /* #region Funzioni di Supporto */
        const set_oFilterParams = () => {
            bDebug && console.log('dentro Set ofilterparam');
            bDebug && console.log(oFilterParams)

            isLineItem && set_oFilterParamsTotal({
                ...oFilterParamsTotal
                , [dettaglio]: { 
                    ...oFilterParams
                    ,pAccountDesc
                    ,pLineItemName
                    ,pLineItemId
                    ,pStatusList
                    ,pLineItemTypeList
                    ,pPacing
                    ,pPacingOper
                    ,pDeliveryEstim 
                    ,pDelivOper     
                    ,pAlerts
                    // ,pContendingCount
                    // ,pContendingOper 
                    ,pPriorityList
                    ,pOrderName 
                    ,pDataFine
                    ,pDataInizio
                }
            });

            isAdUnit && set_oFilterParamsTotal({
                ...oFilterParamsTotal
                , [dettaglio]: { 
                    ...oFilterParams
                    ,pAccountDesc
                    ,pAdUnitName
                    ,pAdUnitId     
                    ,pLineItemName
                    ,pPlacementName
                    ,pSitoName
                    ,pFlagForecast: ( pFlagForecast === true ) ? 'Y' : '' 
                    ,pFlagFoglia  : ( pFlagFoglia   === true ) ? 'Y' : '' 
                    ,pSizes
                }
            });
            
            isPlacement && set_oFilterParamsTotal({
                ...oFilterParamsTotal
                , [dettaglio]: {
                    ...oFilterParams
                    ,pAccountDesc
                    ,pPlacementId
                    ,pPlacementName
                    // ,pLineItemName
                }
            });
        };
    /* #endregion Funzioni di Supporto */

    /* #region CallToDB */
        const getLineItemsFromDB = async () => {
            bDebug && console.log('GetLineItemsFromDB')
            // set_asLineItemsForSelect([]);
            if (isDettaglioOnFocus !== null ) {
            // if (isDettaglioOnFocus !== null && ( pLineItemName === '' )) {
                bDebug && console.log('Dentro GetLineItemsFromDB')
                try {
                    const params = {
                        getAnagraficaLineItemUniqueParams: true
                        ,oFilterParams: {
                              pAccountDesc
                            , pLineItemName
                        }
                    };
                    const response = await axios.get(config.API_URL + '/get', { headers, params });
                
                    if (response && (response?.status === 200) && response?.data?.aoRecords?.length && Array.isArray(response.data.aoRecords)) {
                        bDebug && console.log(response.data.aoRecords);
                        // console.table(response.data.aoRecords)
                        set_asLineItemsForSelect([...response.data.aoRecords].map(item => item.LINE_ITEM_NAME));
                    }
                } catch (err) {
                    console.error('ERR 196: Failed GetLineItemsFromDB: ', err);
                    set_oNotifyOptions({ message: `Interrogazione fallita`, severity: 'error' });
                };
            }
            set_isCallToDB(true);
        };

        const getOrderFromDB = async () => {
            bDebug && console.log('GetOrderFromDB')
            // set_asOrderForSelect([]);
            if ( isDettaglioOnFocus !== null ) {
                bDebug && console.log('Dentro GetOrderFromDB')
                try {
                    const params = {
                         getAnagraficaOrdersUniqueParams: true
                        ,oFilterParams: {
                              pAccountDesc
                            , pOrderName
                        }
                    };
                    const response = await axios.get(config.API_URL + '/get', { headers, params });

                    if (response && (response?.status === 200) && response?.data?.aoRecords?.length && Array.isArray(response.data.aoRecords)) {
                        bDebug && console.log(response.data.aoRecords);
                        set_asOrderForSelect([...response.data.aoRecords].map(item => item.ORDER_NAME));
                    }
                } catch (err) {
                    console.error('ERR 196: Failed GetOrderFromDB: ', err);
                    set_oNotifyOptions({ message: `Interrogazione fallita`, severity: 'error' });
                };
            }
            set_isCallToDB(true);
        };

        const getADunitFromDB = async () => {
            bDebug && console.log('GetADunitFromDB')
            // set_asADUnitForSelect([]);
            if ( isDettaglioOnFocus !== null ) {
                bDebug && console.log('Dentro GetADunitFromDB')
                try {
                    const params = {
                         getAnagraficaAdUnitUniqueNameIDParams: true
                        ,oFilterParams: {
                              pAccountDesc
                            , pAdUnitName
                            , pAdUnitId: ''
                        }
                    };
                    const response = await axios.get(config.API_URL + '/get', { headers, params });
            
                    if (response && (response?.status === 200) && response?.data?.aoRecords?.length && Array.isArray(response.data.aoRecords)) {
                        bDebug && console.log(response.data.aoRecords);
                        set_asADUnitForSelect([...response.data.aoRecords].map(item => item.AD_UNIT_NAME));
                    }
                } catch (err) {
                    console.error('ERR 196: Failed GetADunitFromDB: ', err);
                    set_oNotifyOptions({ message: `Interrogazione fallita`, severity: 'error' });
                };
            }
            set_isCallToDB(true);
        };

        const getSitoNameFromDB = async () => {
            try {
                const params = {
                        getAnagraficaSitoNameUniqueParams: true
                    ,oFilterParams: {
                          pAccountDesc
                        , pSitoName: ''
                    }
                };
                const response = await axios.get(config.API_URL + '/get', { headers, params });
        
                if (response && (response?.status === 200) && response?.data?.aoRecords?.length && Array.isArray(response.data.aoRecords)) {
                    bDebug && console.log(response.data.aoRecords);
                    set_aoSitoForSelect([ { SITO_NAME: '' }, ...response.data.aoRecords  ]);
                }
            } catch (err) {
                console.error('ERR 196: Failed GetSitoNameFromDB: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity: 'error' });
            };
            set_isCallToDB(true);
        };

        const getPlacementFromDB = async () => {
            bDebug && console.log('GetPlacementFromDB')
            // set_asPlacementForSelect([]);
            if ( isDettaglioOnFocus !== null ) {
                bDebug && console.log('Dentro GetPlacementFromDB')
                try {
                    const params = {
                         getAnagraficaPlacementsUniqueParams: true
                        ,oFilterParams: {
                              pAccountDesc
                            , pPlacementName
                            , pPlacementId : idDettaglio ? idDettaglio : '' // Probabilmente inutile perchè nascondiamo i filtri in dettaglio
                        }
                    };
                    const response = await axios.get(config.API_URL + '/get', { headers, params });

                    if (response && (response?.status === 200) && response?.data?.aoRecords?.length && Array.isArray(response.data.aoRecords)) {
                        bDebug && console.log(response.data.aoRecords);
                        set_asPlacementForSelect([...response.data.aoRecords].map(item => item.PLACEMENT_NAME));
                    }
                } catch (err) {
                    console.error('ERR 196: Failed getLineItemsFromDB: ', err);
                    set_oNotifyOptions({ message: `Interrogazione fallita`, severity: 'error' });
                };
            }
            set_isCallToDB(true);
        };

    /* #endregion CallToDB */

    /* #region Hadler */
        const handleClick = () => {
            handleRiSetParam();
            setShowConfirmDialog(true);
        };

        const handleClose = () => {
            handleRiSetParam();
            setShowConfirmDialog(false);
        };

        const handleChangeSelectOnFocus = ({ value, id }) => {
            bDebug && console.log('handleChangeSelectOnFocus [ id: ' + id + ', value: ' + value + ' ]');
            set_isSelectOnFocus({
                ...isSelectOnFocus
                , [id]: value
            })
        };

        const handleRiSetParam = () => {
            //!!! TODo settare tutti gli state in base a oFilterParamTotal
            // Da Capire se Serve Ancora
            bDebug && console.log('handleRisetParam')
            if ( isLineItem ) {
                set_pLineItemName(            oFilterParams?.pLineItemName     || '' );
                set_pLineItemId(              oFilterParams?.pLineItemId       || '' );
                set_pOrderName(               oFilterParams?.pOrderName        || '' );
                set_pStatusList(              oFilterParams?.pStatusList       || '' );
                set_pLineItemTypeList(        oFilterParams?.pLineItemTypeList || '' );
                set_pPacing(                  oFilterParams?.pPacing           || '' );
                set_pPacingOper(              oFilterParams?.pPacingOper       || '' );
                set_pDeliveryEstim(           oFilterParams?.pDeliveryEstim    || '' );
                set_pDelivOper(               oFilterParams?.pDelivOper        || '' );
                set_pAlerts(                  oFilterParams?.pAlerts           || '' );
             // set_pContendingCount(         oFilterParams?.pContendingCount  || '' );
             // set_pContendingOper(          oFilterParams?.pContendingOper   || '' );
                set_pPriorityList(            oFilterParams?.pPriorityList     || '' );
                set_pDataFine(                oFilterParams?.pDataFine         || '' );
                set_pDataInizio(              oFilterParams?.pDataInizio       || '' );
                set_pDataFinePopUp(   moment( oFilterParams?.pDataFine         || '' , 'YYYYMMDD' ));
                set_pDataInizioPopUp( moment( oFilterParams?.pDataInizio       || '' , 'YYYYMMDD' ));
            }
            else if (isAdUnit) {
                set_pAdUnitName(              oFilterParams?.pAdUnitName    || '' );
                set_pAdUnitId(                oFilterParams?.pAdUnitId      || '' );
                set_pLineItemName(            oFilterParams?.pLineItemName  || '' );
                set_pPlacementName(           oFilterParams?.pPlacementName || '' );
                set_pSitoName(                oFilterParams?.pSitoName      || '' );
                set_pSizes(                   oFilterParams?.pSizes         || '' );
                set_pFlagForecast((           oFilterParams?.pFlagForecast === 'Y' ) );
                set_pFlagFoglia((             oFilterParams?.pFlagFoglia   === 'Y' ) );
            }
            else if (isPlacement) {
                set_pPlacementName(           oFilterParams?.pPlacementName || '' );
                set_pPlacementId(             oFilterParams?.pPlacementId   || '' );
                set_pLineItemName(            oFilterParams?.pLineItemName  || '' );
            }
            set_isDettaglioOnFocus(null);
        }

        const handleReset = () => {
            bDebug && console.log('handleReset')
            if ( isLineItem ) {
                set_pLineItemName('');
                set_pLineItemId('');
                set_pOrderName('');
                set_pStatusList('');
                set_pLineItemTypeList('');
                set_pPacing('');
                set_pPacingOper('=');
                set_pDeliveryEstim('');
                set_pDelivOper('=');
                set_pAlerts('');
                // set_pContendingCount('');
                // set_pContendingOper('=');
                set_pPriorityList('');
                set_pDataFine('');
                set_pDataInizio('');
                set_pDataFinePopUp(moment(''));   
                set_pDataInizioPopUp(moment(''));
            }
            else if (isAdUnit) {
                set_pLineItemName('');
                set_pAdUnitName('');
                set_pAdUnitId('');
                set_pPlacementName('');
                set_pSitoName('');
                set_pSizes('');
                set_pFlagForecast('');
                set_pFlagFoglia('');
            }
            else if (isPlacement) {
                set_pPlacementName('');
                set_pPlacementId('');
                set_pLineItemName('');
            }
            set_isDettaglioOnFocus(null);
            setKeyValue(nanoid());
            set_isResectAction(!isResectAction)
        };

        // const handleChange = (event, newSelectedValues, label ) => {
        //     newSelectedValues.includes('(TUTTI)') 
        //         ? set_pStatusList('')
        //         : set_pStatusList(newSelectedValues.join('§'));
        // };

        const handleConfirm = () => {
            bDebug && console.log("HandleConfirmPopup")
            setLoading(true);
            set_isDettaglioOnFocus(null);
            setShowConfirmDialog(false);
            set_oFilterParams(); // serve per "inviare" al componente padre l'insieme degli stati di questo componente
            /*!hasValue && */set_onReload(!onReload); // non settare onReload altrimenti chiama 2 volte la GetAnagDetailsFromDB di AnagraficheDettaglio
        };

        const myKeyPress = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation() 
                handleConfirm()
            }
        };
    
    /* #endregion Hadler */
 
    /* #region UseEffect */
        useEffect(() => {
            bDebug && console.log('UseEffect GetSitoNameFromDB');
            set_isCallToDB(false);
            isAdUnit && getSitoNameFromDB();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            bDebug && console.log('UseEffect GetDettaglioFromDB');
            set_isCallToDB(false);
            bDebug && console.log('showConfirmDialog: ', showConfirmDialog);
            bDebug && console.log('isDettaglioOnFocus: ', isDettaglioOnFocus);

            ( showConfirmDialog && ( isDettaglioOnFocus === 'getLineItem'  ) ) && utils.debounce( getLineItemsFromDB , 1000 )();
            ( showConfirmDialog && ( isDettaglioOnFocus === 'getAdUnit'    ) ) && utils.debounce( getADunitFromDB    , 1000 )();
            ( showConfirmDialog && ( isDettaglioOnFocus === 'getPlacement' ) ) && utils.debounce( getPlacementFromDB , 1000 )();
            ( showConfirmDialog && ( isDettaglioOnFocus === 'getOrder'     ) ) && utils.debounce( getOrderFromDB     , 1000 )();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ pLineItemName, pAdUnitName, pPlacementName, pOrderName, isDettaglioOnFocus ]);

        useEffect(() => {
            let newHasValue = !!( pLineItemName || pLineItemId || pOrderName || pStatusList || pLineItemTypeList || pAlerts || pPacing || pDeliveryEstim || pContendingCount || pPriorityList || pDataFine || pDataInizio || pAdUnitName || pAdUnitId || pSitoName || pSizes || pFlagForecast || pFlagFoglia || pPlacementName || pPlacementId );
            bDebug && console.log('newHasValue: ', newHasValue)
            // console.log('pDataInizio: ', pDataInizio)
            setHasValue(newHasValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ pLineItemName, pLineItemId, pOrderName, pStatusList, pLineItemTypeList, pPacing, pDeliveryEstim, pContendingCount, pPriorityList, pDataFine, pDataInizio, pAdUnitName, pAdUnitId, pSitoName, pSizes, pFlagForecast, pFlagFoglia, pPlacementName, pPlacementId ]);

        useEffect(() => {
            bDebug && console.log('UseEffect isResectAction');
            if ( isResectAction !== null ) {
                set_oFilterParams();
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ isResectAction ])
    /* #endregion UseEffect */

    /* #region PopupModParam */
        const confirmOptions = {
              oPopupOptions: {
                 DialogTitle: <span className="AnagrafichePopUp title">Filtri {utils.formatNamePage(dettaglio)}</span>
                ,Content: (
                    <div className="AnagrafichePopUp" tabIndex={0} onKeyDown={ (e) => myKeyPress(e) }>
                        <div className="PopUpContent">

                            {/* Gam Account - Disabilitato perchè l'account non si può modificare nel filtro */}
                            { false && <span className="AutocompletePopup mybordercolor">
                              <div className='AutocompletePopup'><FormControl>
                               <InputLabel id="GAM-Account-select-label-filter" className='' >GAM Account</InputLabel>
                                <Select
                                    disabled
                                    id             = "pGAMAccount"
                                    label          = "GAM Account"
                                    labelId        = "GAM-Account-select-label-filter"
                                    key            = { `pGAMAccount ${keyValue}` }
                                    value          = { pAccountDesc }
                                    onChange       = {(event) => set_pAccountDesc( event.target.value )}
                                    className      = 'mySelect mybordercolor'
                                >
                                    { GAMaccount.map((account) => (
                                        <MenuItem key={account} value={account}>
                                            { account }
                                        </MenuItem>
                                    ))}
                                </Select>
                              </FormControl></div>
                            </span> }

                            {/* isLineItem Line Item Id : isAdUnit Ad Unit iD */}
                            <span className="AutocompletePopup">
                              { isLineItem ?
                                    <CustomCssTextField
                                        label     = { "Line item ID" }
                                        value     = { pLineItemId }
                                        className = "AutocompletePopup"
                                        onChange  = { (event) => {
                                            const regex = /^[0-9]*$/;
                                            const newValue = event.target.value.slice(0, 20);
                                            if ( regex.test( newValue ) ) {
                                                set_pLineItemId( newValue );
                                            }
                                        }}
                                    />
                                    : isAdUnit ?
                                        <CustomCssTextField
                                            label     = { "Ad Unit ID" }
                                            value     = { pAdUnitId }
                                            className = "AutocompletePopup"
                                            onChange  = { (event) => {
                                                const regex = /^[0-9]*$/;
                                                const newValue = event.target.value.slice(0, 20);
                                                if ( regex.test( newValue ) ) {
                                                    set_pAdUnitId( newValue );
                                                }}}
                                        />
                                        : isPlacement ?
                                            <CustomCssTextField
                                                label     = { "Placement ID" }
                                                value     = { pPlacementId }
                                                className = "AutocompletePopup"
                                                onChange  = { (event) => {
                                                    const regex = /^[0-9]*$/;
                                                    const newValue = event.target.value.slice(0, 20);
                                                    if ( regex.test( newValue ) ) {
                                                        set_pPlacementId( newValue );
                                                    }}}
                                            />
                                            : null
                              }
                            </span>

                            {/* isAdUnit pAdUnitName withCallDB */}
                            { isAdUnit && <span className="AutocompletePopup">
                                <Autocomplete
                                    key           = { `pAdUnitName ${keyValue}` }
                                    clearOnBlur   = { false }
                                    id            = "pAdUnitName"
                                    noOptionsText = { isCallToDB ? 'Nessun elemento trovato' : "Loading..." }
                                    options       = { asADUnitForSelect }
                                    inputValue    = { pAdUnitName }
                                    onInputChange = { ( event, newInputValue ) => set_pAdUnitName( newInputValue ) }
                                    renderInput   = { ( params ) => (
                                        <CustomCssTextField
                                            {...params}
                                            label     = "Ad Unit Nome"
                                            value     = { pAdUnitName }
                                            onMouseDown = { () => set_isDettaglioOnFocus('getAdUnit') }
                                            className = "AutocompletePopup"
                                        />
                                    )}
                                />
                            </span> }
                            
                            {/* isAdUnit pPlacementName */}
                            {( isPlacement || isAdUnit ) && <span className="AutocompletePopup">
                                <Autocomplete
                                    disabled      = { idDettaglio }
                                    key           = { `pPlacementName ${keyValue}` }
                                    clearOnBlur   = { false }
                                    id            = "pPlacementName"
                                    noOptionsText = { isCallToDB ? 'Nessun elemento trovato' : "Loading..."}
                                    options       = { asPlacementForSelect }
                                    inputValue    = { idDettaglio ? sPlacementContainerName : pPlacementName }
                                    onInputChange = { ( event, newInputValue ) => set_pPlacementName(newInputValue) }
                                    renderInput   = { ( params ) => (
                                        <CustomCssTextField
                                            { ...params }
                                            label       = "Placement Nome"
                                            value       = { idDettaglio ? sPlacementContainerName : pPlacementName }
                                            // onClick   = { () => set_isDettaglioOnFocus('getPlacement') }
                                            onMouseDown = { () => set_isDettaglioOnFocus('getPlacement') }
                                            className   = "AutocompletePopup"
                                        />
                                    )}
                                />
                            </span> }

                            {/* Line item name generale per tutti */}
                            { true && <span className="AutocompletePopup">
                                <Autocomplete // Ever ( isLineItem || isAdUnit || isPlacement )
                                    key           = { `pLineItemName ${keyValue}`}
                                    clearOnBlur   = { false }
                                    id            = "pLineItemName"
                                    noOptionsText = { isCallToDB ? 'Nessun elemento trovato' : "Loading..."}
                                    options       = { asLineItemsForSelect }
                                    inputValue    = { pLineItemName }
                                    onInputChange = { ( event, newInputValue ) => set_pLineItemName( newInputValue ) }
                                    renderInput   = { ( params ) => (
                                        <CustomCssTextField
                                            {...params}
                                            label     = "Line Item Nome"
                                            value     = { pLineItemName }
                                            // onClick   = { () => set_isDettaglioOnFocus('getLineItem') }
                                            // onKeyDown = { () => set_isDettaglioOnFocus('getLineItem') }
                                            onMouseDown = { () => set_isDettaglioOnFocus('getLineItem') }
                                            className = "AutocompletePopup"
                                        />
                                    )}
                                /> 
                            </span>}

                            {/* isAdUnit pAdUnitName withCallDB */}
                            { isAdUnit && <span className="AutocompletePopup mybordercolor">
                                <FormControl variant='outlined' >
                                    <InputLabel id="demo-simple-select-helper-label">
                                        Sito
                                    </InputLabel>
                                    <Select
                                        id        = "pSitoName"
                                        key       = { `pSitoName ${keyValue}` }
                                        value     = { pSitoName }
                                        label     = 'Sito'
                                        onChange  = { (event) => set_pSitoName(event.target.value || '') }
                                        onFocus   = { (event) => handleChangeSelectOnFocus({ id: 'pSitoName', value: true }) }
                                        onBlur    = { (event) => handleChangeSelectOnFocus({ id: 'pSitoName', value: false }) }
                                        className = {  `AutocompletePopup mybordercolor ${isSelectOnFocus.pSitoName ? 'onFocus' : ''}` }
                                    >
                                        { 
                                             aoSitoForSelect?.length ? 
                                             aoSitoForSelect.map( item =>  (
                                                <MenuItem key={ item.SITO_NAME || '' } value={ item.SITO_NAME || '' }>
                                                    { item.SITO_NAME || '' }
                                                </MenuItem> ))
                                            :   <MenuItem key={  isCallToDB ? 'Nessun elemento trovato' : "Loading..." } value=''>
                                                     {  isCallToDB ? 'Nessun elemento trovato' : "Loading..." }
                                                </MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </span>}

                            {/* isAdUnit pSizes */}
                            { isAdUnit && <span className="AutocompletePopup">
                                <CustomCssTextField
                                    label     = { 'Formati' }
                                    value     = { pSizes }
                                    className = "AutocompletePopup"
                                    onChange  = { (event) => {
                                        const newValue = event.target.value.slice(0, 10);
                                        set_pSizes(newValue) 
                                    }}
                                />
                            </span> }
                            
                            {/* flag forecast &&  flag foglia */}
                            {isAdUnit && <span className="AutocompletePopup">
                                <>
                                    <FormControl className="input-field flag-admin CheckboxStile ">
                                        <Checkbox
                                            key      = "pFlagForecast"
                                            name     = "pFlagForecast"
                                            id       = "pFlagForecast"
                                            checked  = { pFlagForecast }
                                            onChange = { e => { set_pFlagForecast(!pFlagForecast); }}
                                        />
                                        <span className="AdUnit_Flag"> Solo con Forecast</span>
                                    </FormControl>
                                
                                    <FormControl className="input-field flag-admin CheckboxStile">
                                        <Checkbox
                                            key      = "pFlagFoglia"
                                            name     = "pFlagFoglia"
                                            id       = "pFlagFoglia"
                                            checked  = { pFlagFoglia }
                                            onChange = { e => { set_pFlagFoglia(!pFlagFoglia) }}
                                        />
                                        <span className="AdUnit_Flag"> Solo Foglia</span>
                                    </FormControl>
                                </>
                            </span> }

                            {/* isLineItem pOrderName*/}
                            { isLineItem && <span className="AutocompletePopup">
                                <Autocomplete
                                    key           = { `pOrderName ${keyValue}` }
                                    clearOnBlur   = { false }
                                    id            = "pOrderName"
                                    noOptionsText = { isCallToDB ? 'Nessun elemento trovato' : "Loading..."}
                                    options       = { asOrderForSelect }
                                    inputValue    = { pOrderName }
                                    onInputChange = { (event, newInputValue) =>
                                        set_pOrderName(newInputValue)
                                    }
                                    renderInput   = { (params) => (
                                        <CustomCssTextField
                                            { ...params }
                                            label       = "Ordini"
                                            value       = { pOrderName }
                                            // onClick   = { () => set_isDettaglioOnFocus('getOrder') }
                                            onMouseDown = { () => set_isDettaglioOnFocus('getOrder') }
                                            className   = "AutocompletePopup"
                                        />
                                    )}
                                />
                            </span> }

                            {/* isLineItem PriorityList */}
                            {/* <span className="AutocompletePopup">
                                { isLineItem && <CustomCssTextField
                                    autoComplete = "off"
                                    id           = "pPriorityList"
                                    key          = "pPriorityList"
                                    label        = "Priorità"
                                    className    = "PriorityList"
                                    value        = { pPriorityList }
                                    onChange     = { (event) => {
                                        const newValue = event.target.value.trim();
                                        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
                                          set_pPriorityList(newValue === "" ? "" : newValue > 100 ? 100 : newValue);
                                        }}}
                                        
                                /> }
                            </span> */}

                            {/* SELECT STATUS */}
                            { isLineItem && <span className="AutocompletePopup mybordercolor">
                              <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    id             = "pPriorityList"
                                    key            = { `pPriorityList ${keyValue}` }
                                    value          = { pPriorityList.split('§').filter(Boolean) }
                                    clearOnBlur    = { false }
                                    options        = { asPriorityNumbers }
                                    getOptionLabel = { (option) => option }
                                    onChange       = { ( event, values ) => values.includes('(TUTTI)') ? set_pPriorityList('') : set_pPriorityList(values.join('§')) }
                                    className      =   'PriorityList'
                                    renderOption   = { (props, option, { selected }) => (
                                        <li {...props}>
                                        <Checkbox checked={selected} />
                                        { option}
                                      </li>
                                    )}
                                    renderInput = { ( params ) => (
                                        <CustomCssTextField
                                            { ...params }
                                            label     = "Priorità"
                                            className = "PriorityList"
                                        />
                                    )}
                                />
                            </span> }

                            {/* SELECT STATUS */}
                            { isLineItem && <span className="AutocompletePopup mybordercolor">
                              <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    id             = "pStatusList"
                                    key            = { `pStatusList ${keyValue}` }
                                    value          = { pStatusList.split('§').filter(Boolean) }
                                    clearOnBlur    = { false }
                                    options        = { statusDel }
                                    getOptionLabel = { (option) => option }
                                    onChange       = { ( event, values ) => values.includes('(TUTTI)') ? set_pStatusList('') : set_pStatusList(values.join('§')) }
                                    renderOption   = { (props, option, { selected }) => (
                                        <li {...props}>
                                        <Checkbox checked={selected} />
                                        { option}
                                      </li>
                                    )}
                                    renderInput = { ( params ) => (
                                        <CustomCssTextField
                                            { ...params }
                                            label     = "Status"
                                            className = "AutocompletePopup"
                                        />
                                    )}
                                />
                            </span> }

                            {/* isLineItem pLineItemTypeList*/}
                            { isLineItem && <span className="AutocompletePopup ">
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    id             ="pLineItemTypeList"
                                    key            ={ `pLineItemTypeList ${keyValue}` }
                                    value          ={ pLineItemTypeList.split('§').filter(Boolean) }
                                    clearOnBlur    ={ false }
                                    options        ={ aoLineItemType }
                                    getOptionLabel ={ (option) => option }
                                    onChange       ={ ( event, values ) => 
                                            values.includes('(TUTTI)') 
                                            ? set_pLineItemTypeList('') 
                                            : set_pLineItemTypeList(values.join('§'))
                                    }
                                    renderOption   ={ (props, option, { selected }) => (
                                        <li {...props}>
                                          <Checkbox checked={selected} />
                                          { option}
                                        </li>
                                    )}
                                    renderInput    ={ ( params ) => (
                                        <CustomCssTextField
                                            { ...params }
                                            label     = "Tipologia"
                                            value     = { pLineItemTypeList }
                                            className = "AutocompletePopup"
                                        />
                                    )}
                                />
                            </span> }
                            
                            {/* Pacing  */}
                            { isLineItem && <span className="AutocompletePopup ValGapper mybordercolor">
                                <Select
                                    nullable="true"
                                    id="pPacingOper"
                                    value={pPacingOper}
                                    onChange={(event) => set_pPacingOper(event.target.value)}
                                    onFocus={(event) => handleChangeSelectOnFocus({ id: 'pPacingOper', value: true })}
                                    onBlur={(event) => handleChangeSelectOnFocus({ id: 'pPacingOper', value: false })}
                                    className={`selectVal mybordercolor ${isSelectOnFocus.pPacingOper ? 'onFocus' : ''}`}
                                >
                                    { aoValuesSign.map((oSign) => (
                                        <MenuItem key={oSign.value} value={oSign.value}>
                                            {oSign.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <CustomCssTextField
                                    id="pPacing"
                                    key="pPacing"
                                    label="Pacing "
                                    className="selectProgOb"
                                    placeholder="%"
                                    value={pPacing}
                                    onChange={(event) => {
                                        const newValue = event.target.value.trim();
                                        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
                                            set_pPacing(newValue === "" ? "" : newValue > 999 ? 999 : newValue);
                                        }}}
                                />
                            </span> }

                            {/* isLineItem --- SELECT SIGN + DELIVERY ESTIM */}
                            { isLineItem && <span className="AutocompletePopup ValGapper mybordercolor">
                                <Select
                                    id="selectSign"
                                    value={pDelivOper}
                                    onChange={(event) => set_pDelivOper(event.target.value)}
                                    onFocus={(event) => handleChangeSelectOnFocus({ id: 'selectSign', value: true })}
                                    onBlur={(event) => handleChangeSelectOnFocus({ id: 'selectSign', value: false })}
                                    className={`selectVal  mybordercolor ${isSelectOnFocus.selectSign ? 'onFocus' : ''}`}
                                >
                                    { aoValuesSign.map((oSign) => (
                                        <MenuItem key={oSign.value} value={oSign.value}>
                                            {oSign.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <CustomCssTextField
                                    autoComplete = "off"
                                    id           = "pDeliveryEstim"
                                    key          = "pDeliveryEstim"
                                    label        = "Check inventory"
                                    className    = "selectProgOb "
                                    placeholder  = "%"
                                    value        = { pDeliveryEstim }
                                    onChange     = { (event) => {
                                        const newValue = event.target.value.trim();
                                        if (newValue === "" || /^[0-9\b]+$/.test(newValue)) {
                                            set_pDeliveryEstim(newValue === "" ? "" : newValue > 100 ? 100 : newValue);
                                        }}}
                                />
                            </span> }
                            
                            {/* ALLARMI */}
                            { isLineItem && <span className="AutocompletePopup mybordercolor">
                              <div className='AutocompletePopup'><FormControl>
                               <InputLabel id="GAM-Account-select-label-filter" className='' >Allarmi</InputLabel>
                                <Select
                                    id             = "pAlerts"
                                    label          = "Allarmi"
                                    labelId        = "Allarmi-select-label-filter"
                                    key            = { `pAlerts ${keyValue}` }
                                    value          = { pAlerts }
                                    onChange       = {(event) => set_pAlerts( event.target.value )}
                                    className      = 'mySelect mybordercolor alerts'
                                >
                                    { aoAlertsConditions.map((oAlertCondition) => (
                                        <MenuItem key={oAlertCondition.key} value={oAlertCondition.value} className='alert-flex'>
                                                { oAlertCondition.label }
                                        </MenuItem>
                                    ))}
                                </Select>
                              </FormControl></div>
                            </span> }

                            {/* DATEPICKER */}
                            { isLineItem && <span className="selPlacementDateGroup mybordercolor ">
                                <LocalizationProvider dateAdapter={AdapterMoment} key="date">
                                    <DatePicker
                                        id        = "dateInizio"
                                        key       = "dataInizio"
                                        label     = "Data Inizio"
                                        format    = "DD/MM/YYYY"
                                        views     = {['year', 'month', 'day']}
                                        value     = { pDataInizioPopUp }
                                        minDate   ={  moment( '20200101', 'YYYYMMDD' ) }
                                        maxDate   ={  moment( '20301231', 'YYYYMMDD' ) }
                                        className = 'dateFiltro'
                                        onChange  = { (value) => {
                                            set_pDataInizio(value && value.format("YYYYMMDD"));
                                            set_pDataInizioPopUp(value);
                                        }}
                                    />
                                    <DatePicker
                                        id        = "dateFine"
                                        key       = "dataFine"
                                        label     = "Data Fine"
                                        format    = "DD/MM/YYYY"
                                        views     = {['year', 'month', 'day']}
                                        value     = { pDataFinePopUp }
                                        minDate   ={  moment( '20200101', 'YYYYMMDD' ) }
                                        maxDate   ={  moment( '20301231', 'YYYYMMDD' ) }
                                        className = 'dateFiltro'
                                        onChange  = { (value) => {
                                            set_pDataFine(value && value.format("YYYYMMDD"));
                                            set_pDataFinePopUp(value);
                                        }}
                                    />
                                </LocalizationProvider>
                            </span> }
                        </div>
                    </div>
                )
                , sDialogCssClass:  'anagraficaPopupWrapper'
                , firstButtonLabel: 'Applica'
                , isThirdButton:    true
                , classWrapperContent: 'myWrapperContent'
                , onClick:          handleConfirm
                , onCancel:         handleClose
                , onReset:          handleReset
            }

            , sPopupType:     showConfirmDialog ? "confirm" : ""
            , set_sPopupType: setShowConfirmDialog
   
        };
    /* #endregion PopupModParam */


    return <div>
        <button onClick={handleClick} className={'FiltersButton reloadButton myShadow'} >
            { hasValue && <span className="green-dot"/> }
            <FilterIcon />
            FILTRI
        </button>
        <PopupConfirm {...confirmOptions} />
    </div>;
};

export default AnagrafichePopup;