import React, { useState, useEffect, useContext } from 'react';
import { useNavigate }   from 'react-router-dom';
import Input             from '@mui/material/Input';
import InputAdornment    from '@mui/material/InputAdornment';
import FormControl       from '@mui/material/FormControl';
import IconButton        from '@mui/material/IconButton';
import Button            from '@mui/material/Button';

import { AuthContext }   from '../contexts/authContext';
import { AppContext }    from '../App.js';
import CircleProgress    from '../components/CircleProgress/CircleProgress.tsx';
import appinfo           from '../appinfo';
import config            from '../config.js';
import moment            from 'moment';

import { AccountCircleIcon 
       , KeyIcon
       , VisibilityIcon
       , VisibilityOffIcon }    from '../assets/icons/icons.js';

const bDebug = config.DEBUG && false;


const Signin = () => {

    const
         [ ver, , month, day ] = ( appinfo.VERSION || '' ).split('-')
        ,env = process.env.REACT_APP_ENV
        // ,jumpRoute = (env === 'localsole') || ( env === 'prodsole' ) ? 'allarmi' : 'anagrafiche' // !!! TODO in Sostituzione finche non abbiamo una dashboard
        ,authContext = useContext(AuthContext)
        ,{ set_oNotifyOptions ,selectedLogo ,selectedLogoText } = useContext(AppContext)
        ,navigate    = useNavigate()
        
        ,[ email           ,setEmail          ] = useState('')
        ,[ password        ,setPassword       ] = useState('')
        ,[ showPassword    ,setShowPassword   ] = useState(false)
        ,[ signinLoading   ,setSigninLoading  ] = useState(false)
        // ,[ bIsAuth         ,setbIsAuth        ] = useState(false)
        ,[ bBtnEnabled     ,set_bBtnEnabled   ] = useState(false)
        
    ;
    
    let DATABASE_SERVER = '';
    
    useEffect( ()=>{
        window.ver = () => {
            console.info(appinfo.NAME + ' version: ' + ( appinfo.VERSION || 'UNKNOWN' ) + ' | Environment: ' + ( env || 'UNKNOWN' ) + ' | Database: ' + ( DATABASE_SERVER || 'UNKNOWN' ));
        }
        const stringParameters = new URLSearchParams( '?' + window.location.href.split('?')[1]);
        if ( stringParameters.has('emailverified') ) {
            if ( ( stringParameters.get('emailverified') + '' ) === '1' ) {
                set_oNotifyOptions({ message: 'Email verificata', severity:'success' });
            } else if ( ( stringParameters.get('emailverified') + '' ) === '2' ) {
                set_oNotifyOptions({ message: 'Errore durante la verifica Email, link scaduto.', severity:'error' });
            } else {
                set_oNotifyOptions({ message: 'Errore durante la verifica Email, prego riprovare.', severity:'error' });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleClickForgotCredentials = (event) =>{
        event.preventDefault();
        navigate('/forgot', {replace: false});
    };

    const loginDian = async (event) => {
        
        let sErrorMessage = '';
        setSigninLoading(true);
        set_oNotifyOptions({ message: "" });
        try {
            await authContext.signInWithEmail(email, password); // login su COGNITO
            
            // la login su COGNITO è andata a buon fine e prosegue
            // CHIAMATA A DATABASE PER OTTENERE LE INFORMAZIONI L'AMBIENTE, IL CLIENTE
        
            localStorage.setItem('userEmail', email);

            const bAdminUser          = ( localStorage.getItem('flagAdmin') || '' ) === 'Y';
            const jumpRoute = (JSON.parse(localStorage.getItem('menuItemsToDisplay') || "[{}]") )
                                .map( o => Object.keys(o) ).flat(Infinity).includes('Allarmi') || bAdminUser  ? 'allarmi' : 'home';
            // il navigate non va messo in una useEffect perchè la Rout App renderizza immediatamente ad allarmi vedendo l'utente autenticato
            navigate(`/${ jumpRoute }`, { replace: false }); // Qua va indicata la pagina che viene immediatamente reindirizzata dopo aver effettuato la login
            setSigninLoading(false);
            
        } catch (err) {
            setSigninLoading(false);
            console.error('err', err);
            let nRetVal = err.stack.split('§')[1];
            
            /*
                Possibili casi di errore in login:
                COD - Descrizione                                           - RetVal Restituito - Messaggio
                L1  - Utente non registrato: email errata                   - -1                - Credenziali Errate
                L2  - Utente non verificato: email corretta non verificata  - -1                - Credenziali Errate
                L3  - Password Errata:       email corretta, psw errata     - 'no retval'       - Credenziali Errate

                ERRORE NotAuthorizedException: Password attempts exceeded :
                After five failed sign-in attempts with a password, Amazon Cognito locks out your user for one second. 
                The lockout duration then doubles after each additional one failed attempt, up to a maximum of approximately 15 minutes.
                Attempts made during a lockout period generate a Password attempts exceeded exception, and don't affect the duration of subsequent lockout periods.
                For a cumulative number of failed sign-in attempts n, not including Password attempts exceeded exceptions, Amazon Cognito locks out your user for 2^(n-5) seconds. 
                To reset the lockout to its initial state, your user must not initiate any sign-in attempts for 15 consecutive minutes at any time after a lockout.
                This behavior is subject to change.
                This behavior doesn't apply to custom challenges unless they also perform password-based authentication.
                
            */

            if ( nRetVal === "0" ) {
                sErrorMessage = `Errore di Sistema`;
            // } else if ( nRetVal === "-1" ) { // Eliminiamo questa distinzione per evitare di fornire informazioni compromettenti
            //     sErrorMessage = `Utente non verificato, controlla la mail per validare l'account`;
            } else {
                sErrorMessage = `Credenziali Errate`;
            }

            set_oNotifyOptions({ message: sErrorMessage, severity:'error' });
        }
    };

    const myKeyPress = (e) => {
        bDebug && console.log('MyKeyPress: ', e.key);
        bDebug && console.log(e);
        if (e.key === 'Enter') {
            e.preventDefault();
            if ( password && email ) {
                loginDian();
            }
        }
    };

    useEffect(() => {
        let control = password && email ;
        set_bBtnEnabled(control);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ email, password ])

    return (
        <div className="app-login-container" onKeyDown={ myKeyPress }>
    
            <div className="login">
        
                    <div className={ "login-outer-container " + env }>
                    
                            { signinLoading && <CircleProgress/> }
                            <div className="background-with-logo"></div>
                            <div className="background-with-logo-top-dx"></div>
                            <div className="background-with-logo-top-dx-bis"></div>
                            <div className="background-with-logo-bot-dx"></div>
                            <div className="background-with-logo-bot-dx-bis"></div>
                    
                            <div id="login-form" className="login-container">
                        
                                <div className="side left login-content">

                                    <div className="header-product-name top-bottom-section">
                                        <img
                                            draggable   ="false"
                                            src         ={ selectedLogoText }
                                            className   ={ "logotext " }
                                            alt         ="Digital Analyzer"
                                        />
                                    </div>
                            
                                    <div className="user-psw-container">
                                
                                        <div className="">
                                            <FormControl className="login-credentials-wrapper top">
                                                <Input
                                                    name            ="email"
                                                    value           ={ email }
                                                    placeholder     ="Username"
                                                    spellCheck      ={ false }
                                                    onChange        ={ e => { setEmail(e.target.value); } }
                                                    startAdornment  = {
                                                        <InputAdornment position="start">
                                                            <AccountCircleIcon />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                
                                        <div className="">
                                            <FormControl className="login-credentials-wrapper">
                                                <Input
                                                    name            ="password"
                                                    id              ="password"
                                                    placeholder     ="Password"
                                                    type            ={ showPassword ? 'text' : 'password' }
                                                    value           ={ password }
                                                    onChange        ={ e => { setPassword(e.target.value); } }
                                                    spellCheck      ={ false }
                                                    startAdornment  ={
                                                        <InputAdornment position="start">
                                                            <KeyIcon />
                                                        </InputAdornment>
                                                    }
                                                    endAdornment    ={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label ="toggle password visibility"
                                                                onClick    ={ e => { setShowPassword(!showPassword); } }
                                                            >
                                                                { showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/> }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                
                                        <div className="forgot-credentials-link"><span onClick={handleClickForgotCredentials}>Password dimenticata</span></div>
                                
                                        <div className="button-wrapper">
                                            <Button
                                                type        = "default"
                                                className   ={ "text-uppercase login-btn" }
                                                onClick     ={ loginDian }
                                                disabled    ={ !bBtnEnabled }
                                            >LOGIN</Button>
                                        </div>
                            
                                    </div>
                            
                                    <div className="footer-info top-bottom-section">
                                        <a href="https://www.systrategy.it" target="_blank" rel="noreferrer">
                                            <img
                                                src         ="/systrategy_logo.svg"
                                                alt         ="Systrategy"
                                                draggable   ="false"
                                                className   ="systrategy-logo"
                                            />
                                        </a>
                                    </div>
                        
                                </div>
                        
                                <div className='side right only-logo'>
                            
                                    <div className="info-top info-top-bottom">
                                        <h1 className={ 'environment ' + env }>{ ( env || 'unknown' ).toUpperCase() }</h1>
                                    </div>
                                    
                                    <img draggable="false" className='logo-lg' src={selectedLogo} alt="Digital Analyzer"/>
                            
                                    <div className="info-bottom info-top-bottom">
                                        <div className={ 'environment ' + env }>
                                            <span className="version-number">{
                                                ver + ' - ' + ( moment(day + '-' + month, 'DD-MM').format('D MMM')  )
                                            }</span>
                                            <span className="database-info">{ DATABASE_SERVER || 'NO DATABASE' }</span>
                                        </div>
                                    </div>
                        
                                </div>
                    
                            </div>
                        
                    </div>

            </div>
            
        </div>
    );
};

export default Signin;
