import { AuthenticationDetails, ClientMetadata, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoAccessToken } from 'amazon-cognito-identity-js'
import config from '../config';

const userPoolId = config.REACT_APP_USERPOOL_ID;
const clientId = config.REACT_APP_CLIENT_ID;
//const endpoint = config.REACT_APP_ENDPOINT;

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
  //endpoint: `${endpoint}`,
}

const userPool: CognitoUserPool = new CognitoUserPool(poolData)

let currentUser: any = userPool.getCurrentUser()

export function getCurrentUser() {
  return currentUser
}

function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)

  return cognitoUser
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser()
  }

  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err: any, session: any) {
      if (err) {
        reject(err)
      } else {
        resolve(session)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function signUpUserWithEmail(username: string, password: string, nickname:string, metaData:ClientMetadata) {
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'nickname',
        Value: nickname,
      }),
    ];

    userPool.signUp(username, password, attributeList, [], function (err, res) {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    }, metaData)
  }).catch((err) => {
    throw err
  })
}

export async function verifyCode(username: string, code: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function signInWithEmail(username: string, password: string) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    currentUser = getCognitoUser(username)

    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res: any) {
        resolve(res)
      },
      onFailure: function (err: any) {
        reject(err)
      },

      newPasswordRequired: function(res: any, userAttributes:any, requiredAttributes:any) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // the api doesn't accept this field back
        delete userAttributes.email_verified;

        currentUser.completeNewPasswordChallenge(config.NEW_USER_TEMP_PASSWORD, userAttributes, this);
        // store userAttributes on global variable
        //sessionUserAttributes = userAttributes;
      }

    })
  }).catch((err) => {
    throw err
  })
}

export function refreshToken(session: any) {
  return new Promise(async function (resolve, reject) {
    if (!currentUser) {
      currentUser = userPool.getCurrentUser()
    }

    currentUser.refreshSession(session.refreshToken, function (err: any, result: any) {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })

  }).catch((err) => {
    throw err
  })
}

export function signOut() {
  if (currentUser) {
    currentUser.signOut()
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err: any, attributes: any) {
      if (err) {
        reject(err)
      } else {
        resolve(attributes)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function setAttribute(attribute: any) {
  return new Promise(function (resolve, reject) {
    const attributeList:any = []
    const res = new CognitoUserAttribute(attribute)
    attributeList.push(res)

    currentUser.updateAttributes(attributeList, (err: any, res: any) => {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function sendCode(email: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(email)
    if (!cognitoUser) {
      reject(`could not find ${email}`)
      return
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res)
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  }).catch((err) => {
    throw err
  })
}

export async function forgotPassword(username: string, code: string, password: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    if (!cognitoUser) {
      reject(`could not find ${username}`)
      return
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve('password updated')
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  })
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(function (resolve, reject) {
    currentUser.changePassword(oldPassword, newPassword, function (err: any, res: any) {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  })
}

export async function isAccessTokenValid(accessToken: string){
  const token = new CognitoAccessToken({ AccessToken: accessToken });

  const exp = token.getExpiration();

  return ( Date.now() < ( exp * 1000 ) );
}
