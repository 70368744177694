import React from "react";
import { Route, Routes } from 'react-router-dom';
import Signin from './Signin';
import ForgotPassword from './ForgotPassword';
import './index.css'

const AuthIndex = () => {
    return (
        <div className="auth-background">
            <Routes>
                <Route path="/*"            element={<Signin/>}/>
                <Route path="/login"        element={<Signin/>}/>
                <Route path="/forgot"              element={<ForgotPassword/>}/>
                <Route path="/forgot/:email/:code" element={<ForgotPassword/>}/>
            </Routes>
        </div>
    )
};

export default AuthIndex;